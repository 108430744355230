import API from '../Common/api'

export const getUserDetails = async (emailId) => {
  if (!emailId) {
    return {
      success: false,
      data: null,
      error: { message: 'User email id is either incorrect or not found!' },
    }
  }

  try {
    const response = await API.post('signIn/getUserDetails', { email: emailId })

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: { message: 'Something went wrong while fetching user details!' },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
