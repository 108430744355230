import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getUserDetails } from '../../services/userService'

const initialState = {
  userDetails: {},
  isLoading: false,
  error: null,
  accessToken: null,
  dummyaccessToken: null,
  powerbiaccessToken: null,
}

export const fetchUserDetails = createAsyncThunk(
  'content/fetchUserDetails',
  async (emailId) => {
    const response = await getUserDetails(emailId)

    if (response.success) {
      return response.data
    }

    return {}
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserRole: (state, action) => {
      state.userDetails = { ...state.userDetails, role: action.payload }
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    updateDummyAccessToken: (state, action) => {
      state.dummyaccessToken = action.payload
    },
    updatePowerbiAccessToken: (state, action) => {
      state.powerbiaccessToken = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.userDetails = action.payload
    })
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })
  },
})

export const {
  updateUserRole,
  updateAccessToken,
  updateDummyAccessToken,
  updatePowerbiAccessToken,
} = userSlice.actions
export default userSlice.reducer
