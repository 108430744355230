import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import {
  updateFilterData,
  updateisApiCallMade,
} from '../../../redux/reducer/projectHealthFilterSlice'
import { TabPanel, a11yProps } from '../../Common/TabPanel'
import ConsolidatedHealthView from './ConsolidatedHealth/ConsolidatedHealthView'
import WeeklyHealthView from './WeeklyHealth/WeeklyHealthView'
import Loading from '../../../Common/Loading'


export default function ProjectHealthView() {
  const navigate = useNavigate()
  const { page } = useParams()
  const [value, setValue] = useState(0)
  const [editing, setEditing] = useState(false)
  const { enqueueSnackbar: notify } = useSnackbar()
  const { isApiCalHappened, count } = useSelector((state) => state.filter)
  const { userDetails } = useSelector((state) => state.user)
  const { isLoading } = useSelector((state) => state.prHealth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isApiCalHappened && count >= 1) {
      dispatch(updateisApiCallMade(true))
    } else {
      dispatch(updateisApiCallMade(false))
    }
    return () => {
      dispatch(updateisApiCallMade(false))
      dispatch(
        updateFilterData({
          vertical: [],
          account: [],
          project: [],
          exclude: [],
          health_status: [],
        })
      )
    }
  }, [dispatch])

  useEffect(() => {
    switch (page) {
      case 'weekly-health':
        setValue(0)
        break
      case 'consolidated-view':
        setValue(1)
        break
      default:
        setValue(0)
        break
    }
  }, [page])
  const handleChange = (event, newValue) => {
    event.preventDefault()
    !editing && setValue(newValue)
    switch (newValue) {
      case 0:
        navigate(`/project-health/weekly-health`)
        break
      case 1:
        !editing
          ? navigate(`/project-health/consolidated-view`)
          : notify(`Please save and proceed`, {
              variant: 'info',
            })
        break
      default:
        navigate(`/project-health/weekly-health`)
        break
    }
  }

  return (
    <Container maxWidth="xxl">
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs value={value} onChange={handleChange} indicatorColor="secondary">
          <Tab label="WEEKLY VIEW" {...a11yProps(0)} />
          <Tab label="CONSOLIDATED VIEW" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={value}>
        {page === 'weekly-health' && (
          <WeeklyHealthView editing={editing} setEditing={setEditing} />
        )}
        {isLoading ? (
          <div style={{ height: '80vh' }}>
            {' '}
            <Loading open={isLoading} />{' '}
          </div>
        ) : (
          <>{page === 'consolidated-view' && <ConsolidatedHealthView />}</>
        )}
      </TabPanel>
      <Outlet />
    </Container>
  )
}
