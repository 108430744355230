import API from '../Common/api'

export const getComplianceReviews = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'No data found!' },
    }
  }

  try {
    const response = await API.get(`/complianceReview/getComplianceReviews`, {
      params: data,
    })

    if (response.status === 200 && response.data) {
      let reviewObj = []
      response.data.result.map((obj) =>
        reviewObj.push({
          ...obj,
          projectTypeId: obj.projectTypeId || '',
          phases: obj.phases ? JSON.parse(obj.phases) : [],
        })
      )
      return {
        success: true,
        data: reviewObj,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching compliance details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getComplianceReviewById = async (reviewId) => {
  if (!reviewId) {
    return {
      success: false,
      data: null,
      error: { message: 'Review id is either incorrect or not found!' },
    }
  }

  try {
    const response = await API.get(
      `/complianceReview/getComplianceReviewById/${reviewId}`
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching review details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
export const getAllDefaultChecklists = async () => {
  try {
    const response = await API.get(`complianceReview/getAllChecklists`)
    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching checklists records!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
export const getUniqueAccounts = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'No data found!' },
    }
  }
  try {
    const response = await API.get(`/complianceReview/getUniqueAccounts`, {
      params: data,
    })

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching compliance details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getUniqueAccountsforPmo = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'No data found!' },
    }
  }
  try {
    const response = await API.get(
      `/complianceReview/getUniqueAccountsforPmo`,
      {
        params: data,
      }
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching compliance details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getUniqueAccountsforAdmin = async () => {
  try {
    const response = await API.get(
      `/complianceReview//getUniqueAccountsforAdmin`
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while fetching compliance  artifacts details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getComplianceArtifacts = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'No data found!' },
    }
  }
  try {
    const response = await API.get(`/complianceReview/getComplianceArtifacts`, {
      params: data,
    })

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while fetching compliance  artifacts details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getComplianceArtifactsPmo = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'No data found!' },
    }
  }
  try {
    const response = await API.get(
      `/complianceReview/getComplianceArtifactsPmo`,
      {
        params: data,
      }
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while fetching compliance  artifacts details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getComplianceArtifactsAdmin = async () => {
  try {
    const response = await API.get(
      `/complianceReview/getComplianceArtifactsAdmin`
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while fetching compliance  artifacts details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const saveComplianceArtifacts = async (data) => {
  if (data) {
    try {
      const response = await API.post(
        `/complianceReview/saveComplianceArtifacts`,
        data
      )

      if (response.status === 200 && response.data) {
        return {
          success: true,
          data: response.data,
          error: null,
        }
      } else {
        return {
          success: false,
          data: null,
          error: {
            message: 'Something went wrong while saving artifacts records!',
          },
        }
      }
    } catch (error) {
      return {
        success: false,
        data: null,
        error: error,
      }
    }
  }
}

export const updateComplianceArtifacts = async (data) => {
  if (data) {
    try {
      const response = await API.post(
        `/complianceReview/updateComplianceArtifacts`,
        data
      )

      if (response.status === 200 && response.data) {
        return {
          success: true,
          data: response.data,
          error: null,
        }
      } else {
        return {
          success: false,
          data: null,
          error: {
            message: 'Something went wrong while updating artifacts records!',
          },
        }
      }
    } catch (error) {
      return {
        success: false,
        data: null,
        error: error,
      }
    }
  }
}

export const updateComplianceReview = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'Invalid Parameters!' },
    }
  }

  try {
    const response = await API.post(
      `/complianceReview/updateComplianceReview`,
      data
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while updating review details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getComplianceChecklist = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'Checklist is not found!' },
    }
  }
  try {
    const response = await API.get(`/complianceReview/getComplianceChecklist`, {
      params: data,
    })
    if (response.status === 200 && response.data) {
      const parsedData = response.data.result.map((item) => ({
        ...item,
        description: JSON.parse(item.description),
      }))
      return {
        success: true,
        data: parsedData,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching compliance checklist!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const updateComplianceReviewStatus = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'Invalid Parameters!' },
    }
  }

  try {
    const response = await API.post(
      `/complianceReview/updateComplianceReviewStatus`,
      data
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while updating review details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getPhaseDetails = async () => {
  try {
    const response = await API.get(`/complianceReview/getPhaseDetails`)
    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data.result,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while phaseDetails details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const saveChecklistdataforCompliance = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'Invalid Parameters!' },
    }
  }

  try {
    const response = await API.post(`/phaseChecklist/savePhasechecklist`, data)

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while updating review-checklist details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getComplianceChecklistsSaved = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'Invalid Parameters!' },
    }
  }

  try {
    const response = await API.get(
      `/phaseChecklist/getComplianceChecklistDetails`,
      { params: data }
    )

    if (response.status === 200 && response.data) {
      let checklistObj = []
      response.data.result.map((obj) =>
        checklistObj.push({
          ...obj,
          checklist: obj.checkList ? JSON.parse(obj.checkList) : [],
        })
      )
      return {
        success: true,
        data: checklistObj,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while fetching review-checklist details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
let data = '32b4afb5-ad3e-4b35-82e0-50a93a1f4bd9'

export const getEltReviews = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'Invalid Parameters!' },
    }
  }

  try {
    const queryParams = encodeURIComponent(JSON.stringify(data))
    const response = await API.get(
      `/eltViewRoutes/getEltReviewLists?data=${queryParams}`
    )
    if (response.status === 200 && response.data) {
      let checklistObj = response.data
      return {
        success: true,
        data: checklistObj,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while fetching review-checklist details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
export const getEltViewEmployeeDetailsByClientIds = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { messag: 'Invalid Parameters' },
    }
  }
  try {
    const response = await API.get(
      `/eltViewRoutes/getEltViewEmployeeDetailsByClientIds?client_Ids=${data}`
    )
    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching elt view details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const saveCheckListLogs = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'Invalid Parameters!' },
    }
  }

  try {
    const response = await API.post(`/checklistLog/saveChecklistLogs`, data)

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while updating review-checklist details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
export const getCreatedByDetailsOfCompliance = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'Invalid Parameters!' },
    }
  }
  try {
    const response = await API.get(
      `/complianceReview/getComplianceCreatedDate?reviewId=${data}`
    )
    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while fetching compliance created detail!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
export const getCheckListLogs = async (data) => {
  if (!data) {
    return {
      success: false,
      data: null,
      error: { message: 'Invalid Parameters!' },
    }
  }

  try {
    const response = await API.get(`/checklistLog/getChecklistLogs`, {
      params: data,
    })

    if (response.status === 200 && response.data) {
      let checklistLogObj = []
      response.data.result.map((obj) =>
        checklistLogObj.push({
          ...obj,
          checkListLog: obj.checkListLog ? JSON.parse(obj.checkListLog) : [],
        })
      )
      return {
        success: true,
        data: checklistLogObj,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while fetching review-checklist details!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
