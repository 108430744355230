import API from '../Common/api'
import {
  url,
  subj,
  emailbody,
  ccemail,
  complianceemailbody,
  getEmail,
} from '../Common/AppConstants'
import storageFactory from '../Common/StorageFactory'

export const sendEmail = async (email, name, body, subj, ccmail) => {
  if (!email) {
    return {
      success: false,
      error: { message: 'User email id is either incorrect or not found!' },
    }
  }

  try {
    let data = {
      email: email,
      name: name,
      body: body,
      subj: subj,
      ccmail: ccmail,
    }
    const response = await API.post('/email/sendEmail', data)

    if (response.status === 200) {
      return {
        success: true,
        error: null,
      }
    } else {
      return {
        success: false,
        error: {
          message: 'Something went wrong while sending email to  user!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      error: error,
    }
  }
}

export const sendReviewerMail = async (data) => {
  const adminData = storageFactory().getAdminDetails()
  const userData = adminData.data.employeeDetails.find(
    (emp) => emp.employee_id == data.userId
  )
  const body = emailbody(data.review, data.role, data.client, data.project, url)
  const emailsubj = subj(data.project)

  await sendEmail(
    userData.email_id,
    userData.employee_name,
    body,
    emailsubj,
    ccemail
  )
}

export const sendRevieweeMail = async (data) => {
  const adminData = storageFactory().getAdminDetails()
  const userData = adminData.data.employeeDetails.find(
    (emp) => emp.employee_id == data.userId
  )
  const body = emailbody(data.review, data.role, data.client, data.project, url)
  const emailsubj = subj(data.project)
  await sendEmail(
    userData.email_id,
    userData.employee_name,
    body,
    emailsubj,
    ccemail
  )
}

export const sendRevieweeMailForCopliance = async (data) => {
  const adminData = storageFactory().getAdminDetails()
  const userData = adminData.data.employeeDetails.find(
    (emp) => emp.employee_name == data.username
  )
  const ccdata = adminData.data.employeeDetails.find(
    (emp) => emp.employee_name == data.accountHead
  )
  const ccdataadmin = adminData.data.employeeDetails.find(
    (emp) => emp.employee_id == data.admin
  )
  let cc = [...ccemail, ccdata.email_id, ccdataadmin.email_id]

  const body = complianceemailbody(data.client, data.project, url)
  const emailsubj = subj(data.project)
  await sendEmail(
    userData.email_id,
    userData.employee_name,
    body,
    emailsubj,
    cc
  )
}
const sendEltreviewEmailBody = () => {
  return `<pre>The checklist submitted by you has been reviewed by your leads.

  Please navigate to https://project-review.tredence.com/ to view the status.
  
  In case of rejection, please refer to the comments, & work on the feedback, and again re-submit the same for approval.

 Regards,
 Delivery Excellence Team
  </pre>`
}
export const sendEltReviewMail = async (data) => {
  const adminData = storageFactory().getAdminDetails()
  const RevieweeData = adminData.data.employeeDetails.find(
    (emp) => emp.employee_name == data.revieweename
  )
  const Admindetails = adminData.data.employeeDetails.find(
    (emp) => emp.employee_id == data.AdminEmpid
  )
  let nameTosend = RevieweeData.employee_name
  const eltApproverEmail = getEmail()
  await sendEmail(
    [RevieweeData.email_id, Admindetails.email_id],
    nameTosend,
    sendEltreviewEmailBody(),
    'Elt Approval Update',
    [eltApproverEmail, 'delivery-excellence@tredence.com']
  )
}
const reviewComplianceBody = (Account, project) => {
  return `<pre> ${localStorage.getItem(
    'project_review_employee_name'
  )} has submitted some artefacts for the following project:

  •Account:${Account}
  •Project:${project}

  Please login to PR app https://project-review.tredence.com/  and  approve/reject the list and update the comments if any. 

  Regards,
  Delivery Excellence Team
  </pre>`
}
export const sendRevieweeMailforComplianceData = async (data) => {
  let userData = data.eltPersonEmail
  userData.push(data.adminPersonEmail)
  await sendEmail(
    userData,
    data.eltName,
    reviewComplianceBody(data.Account, data.project),
    'Compliance Review Update',
    data.ccEmail
  )
}
