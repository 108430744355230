import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateFilterData } from '../../../../redux/reducer/projectHealthFilterSlice'
import ArtifactsTable from './ArtifactsTable'

function Artifacts() {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(
        updateFilterData({
          vertical: [],
          account: [],
          project: [],
          exclude: [],
          health_status: [],
        })
      )
    }
  })
  return <ArtifactsTable />
}

export default Artifacts
