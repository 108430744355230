import CopyRight from './CopyRight'
import TredenceLogo from '../Assets/Images/TredenceLogo.svg' 
 

const footerStyle = {display:'flex',alignItems:'center',justifyContent:'space-between',padding:'0.6rem',flexWrap:'wrap',bottom:0,width: '100%'}
 const Footer = () =>{
    return (<div style={footerStyle}>
    <CopyRight styleProps={{'textAlign':'left',padding:'0.5rem'}} />
    <img
        src={TredenceLogo}
        alt="Tredence"
        height={40}
      />
    </div>)
}

export default Footer
