import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControl,
  Grid,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilterData } from '../../../../redux/reducer/projectHealthFilterSlice'

const useStyles = makeStyles((theme) => ({
  ellipsisSelect: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

export default function FilterComponent(props) {
  const [rows, setRows] = useState([])
  const [vertical, setVertical] = useState([])
  const [account, setAccount] = useState([])
  const [selectAll, setSelectAll] = useState({
    vertical: false,
    account: false,
    project: false,
    status: false,
  })
  const [isReset, setIsReset] = useState(false)
  const [optionValues1, setOptionValues1] = useState([])
  const [optionValues2, setOptionValues2] = useState([])
  const [optionValues3, setOptionValues3] = useState([])
  //const [optionValues4, setOptionValues4] = useState([])
  const [optionValues5, setOptionValues5] = useState([])

  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    consolidatedViewInitialData,
    weeklyHealthInitialData,
    otherArtifactsInitialData,
    filterData,
    exclude,
  } = useSelector((state) => state.filter)
  useEffect(() => {
    if (props.keyName === 'weeklyHealth') {
      setRows(weeklyHealthInitialData)
    } else if (props.keyName === 'consolidatedView') {
      setRows(consolidatedViewInitialData)
    } else if (props.keyName === 'otherArtifacts') {
      setRows(otherArtifactsInitialData)
    }
  }, [
    weeklyHealthInitialData,
    consolidatedViewInitialData,
    otherArtifactsInitialData,
    props.keyName,
  ])

  useEffect(() => {
    if (!rows || rows.length === 0) {
      return
    }
    const array1 = rows.map((v) => v.vertical).sort()
    const selectAll = [...array1]
    setOptionValues1(_.uniq(selectAll))
    const filteredStatus = rows.map((item) => item.health_status).sort()
    setOptionValues5(_.uniq(filteredStatus))
  }, [rows, isReset])

  useEffect(() => {
    const filteredAccounts =
      props.keyName === 'weeklyHealth'
        ? rows
            .filter(
              (item) =>
                filterData.vertical?.includes(item.vertical) &&
                exclude == item?.exclude
            )
            .map((item) => item.account)
            .sort()
        : rows
            .filter((item) => filterData.vertical?.includes(item.vertical))
            .map((item) => item.account)
            .sort()
    setOptionValues2(_.uniq(filteredAccounts))

    const filteredProjects =
      props.keyName === 'weeklyHealth'
        ? rows
            .filter(
              (item) =>
                filterData.account?.includes(item.account) &&
                exclude == item?.exclude
            )
            .map((item) => item.project)
            .sort()
        : rows
            .filter((item) => filterData.account?.includes(item.account))
            .map((item) => item.project)
            .sort()
    setOptionValues3(_.uniq(filteredProjects))
    // if(filterData.exclude.length===0&& filterData.health_status.length>0){
    //   const filteredExcludes = rows.filter((item) =>filterData.account.includes(item.account)&&filterData.health_status.includes(item.health_status)).map((item) => item.exclude).sort()
    //   setOptionValues4(_.uniq(filteredExcludes))
    // }else if(filterData.exclude.length===0){
    //   const filteredExcludes = rows.filter((item) =>filterData.account.includes(item.account)).map((item) => item.exclude).sort()
    //   setOptionValues4(_.uniq(filteredExcludes))
    // }
    // if(filterData.exclude.length>0&& filterData.health_status.length===0){
    //   const filteredStatus = rows.filter((item) =>filterData.account.includes(item.account)&&filterData.exclude.includes(item.exclude)).map((item) => item.health_status).sort()
    // setOptionValues5(_.uniq(filteredStatus))
    // }else if(filterData.health_status.length===0){
    if (filterData?.project?.length > 0) {
      const filteredStatus = rows
        .filter(
          (item) =>
            filterData?.project.includes(item.project) &&
            exclude === item.exclude
        )
        .map((item) => item.health_status)
      setOptionValues5(_.uniq(filteredStatus))
    } else {
      const filteredStatus = rows
        .filter((item) => exclude === item.exclude)
        .map((item) => item.health_status)
        .sort()
      setOptionValues5(_.uniq(filteredStatus))
    }
    // const filteredStatus = rows.map((item) => item.health_status).sort()
    // setOptionValues5(_.uniq(filteredStatus))
    // }
  }, [filterData, optionValues1, exclude])

  useEffect(() => {
    setSelectAll({
      vertical: filterData?.vertical.length === optionValues1?.length,
      account: filterData?.account.length === optionValues2?.length,
      project: filterData?.project.length === optionValues3?.length,
      status: filterData?.health_status?.length === optionValues5?.length,
    })
  }, [optionValues1, optionValues2, optionValues3, optionValues5, filterData])

  const onClickHandler = () => {
    setIsReset(true)
    dispatch(
      updateFilterData({
        vertical: [],
        account: [],
        project: [],
        /* exclude:[],*/ health_status: [],
      })
    )
  }

  const handleSelectAllChange = (value, sourceData, fieldName) => {
    let updatedValues = []
    if (value.includes('Select All')) {
      updatedValues = selectAll[fieldName] ? [] : sourceData || []
      setSelectAll({ ...selectAll, [fieldName]: !selectAll[fieldName] })
    } else {
      updatedValues = value
      setSelectAll({ ...selectAll, [fieldName]: false })
    }
    if (sourceData.length === value.length) {
      updatedValues = value.length ? sourceData : []
      setSelectAll({ ...selectAll, [fieldName]: value.length })
    }
    return updatedValues
  }

  const onVerticalChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues1, 'vertical')
    dispatch(
      updateFilterData({
        vertical: value,
        account: [],
        project: [],
        /* exclude:[],*/ health_status: [],
      })
    )
    const filteredAccounts =
      props.keyName === 'weeklyHealth'
        ? rows
            .filter(
              (item) =>
                value.includes(item.vertical) && exclude === item?.exclude
            )
            .map((item) => item.account)
        : rows
            .filter((item) => value.includes(item.vertical))
            .map((item) => item.account)
    setOptionValues2(_.uniq(filteredAccounts))
  }

  const onAccountChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues2, 'account')

    dispatch(
      updateFilterData({
        account: value,
        project: [],
        /* exclude:[],*/ health_status: [],
      })
    )
    const filteredProjects =
      props.keyName === 'weeklyHealth'
        ? rows
            .filter(
              (item) =>
                value.includes(item.account) && exclude === item?.exclude
            )
            .map((item) => item.project)
        : rows
            .filter((item) => value.includes(item.account))
            .map((item) => item.project)
    setOptionValues3(_.uniq(filteredProjects))
    // const filteredExcludes = rows.filter((item) => value.includes(item.account)).map((item) => item.exclude)
    // const filteredStatus = rows.filter((item) => value.includes(item.account)).map((item) => item.health_status)
    // setOptionValues4(_.uniq(filteredExcludes))
    // setOptionValues5(_.uniq(filteredStatus))
    // } else {
    //   dispatch(
    //     updateFilterData({
    //       account: [],
    //       project: [],
    //       /* exclude:[],*/ health_status: [],
    //     })
    //   )
    // }
  }
  const onProjectChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues3, 'project')
    dispatch(updateFilterData({ project: value }))
    const filteredStatus =
      props.keyName === 'weeklyHealth'
        ? rows
            .filter(
              (item) =>
                value.includes(item.project) && exclude === item?.exclude
            )
            .map((item) => item.health_status)
        : rows
            .filter((item) => value.includes(item.project))
            .map((item) => item.health_status)
    setOptionValues5(_.uniq(filteredStatus))
  }

  const onStatusChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues5, 'status')
    dispatch(updateFilterData({ health_status: value }))
  }

  const clickAwayHandler = (event) => {
    if (event.target.localName === 'body') {
      return
    }
    props.setAnchorEl(false)
  }

  return (
    <>
      <ClickAwayListener onClickAway={(e) => clickAwayHandler(e)}>
        <Box sx={{ margin: '10px' }}>
          <Grid container alignItems="center">
            <Grid style={{ marginLeft: '5px' }} item>
              <Typography style={{ fontSize: '14px' }} variant="body1">
                Filter
              </Typography>
            </Grid>
            <Grid style={{ marginLeft: '35px' }} item>
              <Button
                onClick={onClickHandler}
                sx={{ textTransform: 'none', fontSize: '14px' }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>

          <Grid padding="15px">
            <FormControl
              variant="standard"
              sx={{ m: 1, width: 100 }}
              size="small"
            >
              <InputLabel>Vertical</InputLabel>
              <Select
                sx={{ height: '50%' }}
                value={filterData.vertical}
                label="vertical"
                className={classes.ellipsisSelect}
                onChange={(event) => onVerticalChange(event)}
                multiple
                renderValue={(selected) => selected.join(', ')}
              >
                {optionValues1.length > 0 ? (
                  <MenuItem key="SelectAll" value="Select All">
                    <Checkbox checked={selectAll['vertical']} />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                ) : (
                  ''
                )}
                {optionValues1?.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={filterData?.vertical?.includes(item)} />{' '}
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip
              title={
                filterData.vertical.length === 0
                  ? 'Please select the Vertical'
                  : ''
              }              
              leaveTouchDelay={3000} enterTouchDelay={20}
            >
              <FormControl
                variant="standard"
                sx={{ m: 1, width: 100 }}
                size="small"
              >
                <InputLabel>Account</InputLabel>
                <Select
                  sx={{ height: '50%' }}
                  disabled={filterData.vertical.length === 0}
                  value={filterData.account}
                  label="account"
                  className={classes.ellipsisSelect}
                  onChange={(event) => {
                    onAccountChange(event)
                  }}
                  multiple // Enable multiple selection
                  renderValue={(selected) => selected.join(', ')} // Display selected values
                >
                  {optionValues2.length > 0 ? (
                    <MenuItem key="SelectAll" value="Select All">
                      <Checkbox checked={selectAll['account']} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                  ) : (
                    ''
                  )}
                  {optionValues2?.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={filterData?.account?.includes(item)} />{' '}
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid padding="15px">
            <Tooltip
              title={
                filterData.account.length === 0 &&
                filterData.vertical.length === 0
                  ? 'Please select the Vertical & Account'
                  : filterData.account.length === 0 &&
                    filterData.vertical.length !== 0
                  ? 'Please select the Account'
                  : ''
              }
              leaveTouchDelay={3000} enterTouchDelay={20}
            >
              <FormControl
                variant="standard"
                sx={{ m: 1, width: 100 }}
                size="small"
              >
                <InputLabel>Project</InputLabel>
                <Tooltip leaveTouchDelay={3000} enterTouchDelay={20}>
                  <Select
                    sx={{ height: '50%' }}
                    disabled={filterData.account.length === 0}
                    value={filterData?.project}
                    label="project"
                    className={classes.ellipsisSelect}
                    onChange={(event) => onProjectChange(event)}
                    multiple
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {optionValues3.length > 0 ? (
                      <MenuItem key="SelectAll" value="Select All">
                        <Checkbox checked={selectAll['project']} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                    ) : (
                      ''
                    )}
                    {optionValues3?.map((item) => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          checked={filterData?.project?.includes(item)}
                        />{' '}
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              </FormControl>
            </Tooltip>
            {/* {props.keyName === 'weeklyHealth'&& <Tooltip title={filterData.vertical.length===0?'':filterData.account.length === 0?"Please select the Account":filterData.project.length===0?"Please select the Project":""}>
              <FormControl
                variant="standard"
                sx={{ m: 1, width: 100 }}
                size="small"
              >
                <InputLabel>Exclude</InputLabel>
                <Tooltip title={''}>
                <Select
                  sx={{ height: '50%' }}
                  disabled={filterData.vertical.length>0 && filterData.project.length===0}
                  value={filterData?.exclude}
                  onClick={filterData.vertical.length===0?()=>{dispatch(updateFilterData({health_status:[]}))}:''}
                  label="exclude"
                  className={classes.ellipsisSelect}
                  onChange={(event) => onExcludeChange(event)}
                  multiple
                  renderValue={(selected) => selected?.join(', ')} 
                >
                  {optionValues4?.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={filterData?.exclude?.includes(item)} />{' '}
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </Tooltip>
              </FormControl>
            </Tooltip>} */}
            {props.keyName === 'weeklyHealth' && (
              <Tooltip
                leaveTouchDelay={3000}
                enterTouchDelay={20}
                title={
                  filterData.vertical.length === 0
                    ? ''
                    : filterData.account.length === 0
                    ? 'Please select the Account'
                    : filterData.account.length === 0 &&
                      filterData.vertical.length !== 0
                    ? 'Please select the Account'
                    : filterData.project.length === 0
                    ? 'Please select the Project'
                    : ''
                }
              >
                {exclude === 'No' && (
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, width: 100 }}
                    size="small"
                  >
                    <InputLabel>Overall Health Status</InputLabel>
                    <Tooltip title={''}>
                      <Select
                        sx={{ height: '50%' }}
                        disabled={
                          filterData.vertical.length > 0 &&
                          filterData.project.length === 0
                        }
                        value={filterData?.health_status}
                        //onClick={filterData.vertical.length===0?()=>{dispatch(updateFilterData({exclude:[]}))}:()=>{}}
                        label="project"
                        className={classes.ellipsisSelect}
                        onChange={(event) => onStatusChange(event)}
                        multiple
                        renderValue={(selected) => selected?.join(', ')}
                      >
                        {optionValues5.length > 0 ? (
                          <MenuItem key="SelectAll" value="Select All">
                            <Checkbox checked={selectAll['status']} />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        ) : (
                          ''
                        )}
                        {optionValues5?.map((item) => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              checked={filterData?.health_status?.includes(
                                item
                              )}
                            />{' '}
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                )}
              </Tooltip>
            )}
          </Grid>
        </Box>
      </ClickAwayListener>
    </>
  )
}
