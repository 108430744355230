import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ClearIcon from '@mui/icons-material/Clear'
import FilterListIcon from '@mui/icons-material/FilterList'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Button, Dialog, DialogContent, DialogContentText } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import Popper from '@mui/material/Popper'
import Select from '@mui/material/Select'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import Loading from '../../../../Common/Loading'
import { fetchFilteredData } from '../../../../redux/reducer/prHealthSlice'
import {
  updateConsolidatedViewInitialData,
  updateConsolidatedYearData,
  updateFilterData,
} from '../../../../redux/reducer/projectHealthFilterSlice'
import FilterComponent from '../Common/multiSelectCustomFilter'
import styles from '../../../../Styles/prHealthStyles.module.scss'


const currentYear = new Date().getFullYear()
const years = []
for (let yearData = 2023; yearData <= currentYear; yearData++) {
  years.push(yearData)
}
const useStyles = makeStyles({
  option: {
    fontSize: '12px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    margin: '50px, 0px, 50px, 0px',
  },

  toolbarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '-38px',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
  },
  searchInput: {
    fontSize: '14.5px',
    marginLeft: '8px',
    marginRight: '8px',
    width: '200px',
    borderBottom: '2px solid #F58220',
  },
  searchIcon: {
    cursor: 'pointer',
    color: '#F58220',
  },
})


function ConsolidatedHealthView() {
  const [filter, setfilter] = useState({})
  const [rows, setRows] = useState([])
  const [rowsData, setRowsData] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openYtdDialog, setOpenYtdDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const { userDetails } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [weekActionDetails, setWeekActionDetails] = useState('')
  const [statusDetails, setStatusDetails] = useState('')
  const [search, setSearch] = useState(false)
  const {
    consolidatedViewInitialData,
    weeklyHealthInitialData,
    filterData,
    consolidatedViewYear,
  } = useSelector((state) => state.filter)
  const { allWeeksProjectHealthDta } = useSelector((state) => state.prHealth)

  const [isDataFetched, setIsDataFetched] = useState(false)
  const previousWeekNumbersDataRef = useRef([])

  const [searchText, setSearchText] = useState('')
  const classes = useStyles()

  const handleSearch = (value) => {
    setSearchText(value)
  }
  const handleDownload = () => {
    const columnNames = columns.map((col) => col.name);
    const worksheetData = [columnNames];
    rows.forEach((row) => {
      worksheetData.push(columnNames.map((col) => row[col]));
    });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, 'Consolidated-view.csv');
  };

  const handlePopoverClick = (rowData, event) => {
    getStatusCounts(rowData)
    setAnchor(anchor ? null : event.currentTarget)
  }

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      maxWidth: 270,
      borderRadius: 3,
    },
  }))
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const clientNames = userDetails?.client_ids?.length
    ? userDetails.client_ids.map((client) => client.client_name)
    : []
  const verticalData = userDetails?.vertical_ids?.verticalInfo
  const verticalNames = verticalData?.length
    ? verticalData.map((vertical) => vertical.verticalName)
    : []

  const currentWeekNumber = moment().isoWeek()
  //const currentYear = moment().consolidatedViewYear()

  const yearHas53Weeks = (consolidatedViewYear) => {
    const endOfYear = moment().year(consolidatedViewYear).endOf('year')
    return endOfYear.isoWeek() === 53
  }

  const previousWeekNumbers = [
    currentWeekNumber,
    currentWeekNumber - 1,
    currentWeekNumber - 2,
    currentWeekNumber - 3,
  ]

  const currentWeekNumbers = [52, 51, 50, 49]

  const prevOddWeekNumbers = [53, 52, 51, 50]
  const [previousWeekNumbersData, setPreviousWeekNumbersData] =
    useState(previousWeekNumbers)

  useEffect(() => {
    if (
      consolidatedViewYear != currentYear &&
      !yearHas53Weeks(consolidatedViewYear)
    ) {
      setPreviousWeekNumbersData(currentWeekNumbers)
    } else if (
      consolidatedViewYear != currentYear &&
      yearHas53Weeks(consolidatedViewYear)
    ) {
      setPreviousWeekNumbersData(prevOddWeekNumbers)
    } else {
      setPreviousWeekNumbersData(previousWeekNumbers)
    }
  }, [consolidatedViewYear])

  const weeksData = []
  previousWeekNumbersData.forEach((weekNumber) => {
    const weekStart = moment()
      .year(consolidatedViewYear)
      .isoWeek(weekNumber)
      .startOf('isoWeek')
    const weekEnd = weekStart.clone().endOf('isoWeek')

    for (
      let day = weekStart.clone();
      day.isBefore(weekEnd);
      day.add(1, 'days')
    ) {
      if (day.isoWeekday() === 5) {
        const weekEndDateFormatted = `WE${day.format('DDMMM')}`
        weeksData.push({
          weekNo: weekNumber,
          title: weekEndDateFormatted,
          year: consolidatedViewYear,
        })
      }
    }
  })

  const customSort = (itemA, itemB, currentWeekNumber) => {
    const weekKey = `week_${currentWeekNumber}`;
    const statusOrder = {
      RED: 1,
      AMBER: 2,
      GREEN: 3,
    };
    const aWeekStatus = itemA[weekKey];
    const bWeekStatus = itemB[weekKey];
    const aOrder = statusOrder[aWeekStatus] || 4;
    const bOrder = statusOrder[bWeekStatus] || 4;
    return aOrder - bOrder;
  };

  const filtersData = (data) => {
    let filteredData = data
    if (filterData.vertical && filterData.vertical.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterData.vertical.includes(item.vertical)
      )
    }
    if (filterData.account && filterData.account.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account)
      )
    }
    if (filterData.project && filterData.project.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account) &&
          filterData.project.includes(item.project)
      )
    }
    return filteredData
  }

  const handleYearChange = (e) => {
    let value = e.target.value
    dispatch(updateConsolidatedYearData(value))
  }

//   function filterRowsBasedOnWeekNumbers(dataObjects, weekArray) {
//     const weekNumbersFromWeekArray = weekArray.map(week => week.weekNo);
//     return dataObjects.filter(dataObject => {
//         const weekNumbersFromDataObject = Object.keys(dataObject)
//             .filter(key => key.startsWith('week_no_'))
//             .map(key => parseInt(key.split('_')[2], 10));
//         return weekNumbersFromDataObject.some(weekNo => weekNumbersFromWeekArray.includes(weekNo));
//     });
// }
// const filteredResults = filterRowsBasedOnWeekNumbers(allWeeksProjectHealthDta, weeksData);
  useEffect(() => {
    const fetchData = async () => {
      setRows([])
      try {
        const currentDataString = previousWeekNumbersData.join(',')
        const previousDataString = previousWeekNumbersDataRef.current.join(',')

        if (currentDataString !== previousDataString) {
          setLoading(true)
          const filtered = previousWeekNumbersData.join(',')
          let data
          if (userDetails.role === 'Reviewer') {
            data = {
              accounts: clientNames,
              email_id: userDetails.email_id,
              verticals: verticalNames,
              year: consolidatedViewYear,
              role: 'Reviewer',
              view: 'consolidated_view',
              weeksData: weeksData,
            }
          } else if (userDetails.role === 'Admin') {
            data = {
              view: 'consolidated_view',
              year: consolidatedViewYear,
              role: 'Admin',
              weeksData: weeksData,
            }
          } else {
            data = {
              role: userDetails.role,
              year: consolidatedViewYear,
              view: 'consolidated_view',
              emailId: userDetails.email_id,
              weeksData: weeksData,
            }
          }
          let response = await dispatch(fetchFilteredData(data)).unwrap()
          if (response?.length > 0) {
            setIsDataFetched(true)
            const percetageAddedData = response.map((i) => {
              const matchedItem = allWeeksProjectHealthDta.find(
                (item) => i.unique_id === item.unique_id
              );
            
              if (matchedItem) {
                return {
                  ...i,
                  redStatusCount: matchedItem.redStatusCount,
                  greenStatusCount: matchedItem.greenStatusCount,
                  amberStatusCount: matchedItem.amberStatusCount,
                  totalStatusCount: matchedItem.totalStatusCount,
                  prcentageRed: matchedItem.redStatusPercentage?matchedItem.redStatusPercentage.toFixed(1):'0.0'    
                  };
              }
              return i;
            });
            const sortedData = [...percetageAddedData]?.sort((a, b) =>
              customSort(a, b, weeksData[0].weekNo)
            )
            setRowsData(sortedData)

            dispatch(updateConsolidatedViewInitialData(response))
            if (filterData.vertical && filterData.vertical.length > 0) {
              let filteredData = filtersData(sortedData)
              setRows(filteredData)
              setLoading(false)
            } else {
              setRows(sortedData)
              setLoading(false)
            }
          } else if (response.length === 0) {
            setRows(response)
            setLoading(false)
          }
        }
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
    previousWeekNumbersDataRef.current = previousWeekNumbersData
  }, [previousWeekNumbersData])
  const handleSearchClick = () =>{
    setSearch(prev=>!prev)
  }
  useEffect(() => {
    if (filterData.vertical && filterData.vertical.length > 0) {
      let filteredData = filtersData(rowsData)
      setRows(filteredData)
    } else {
      setRows(rowsData)
    }
  }, [filterData])

  const onPreviousButtonClick = () => {
    const prevWeeksData = previousWeekNumbersData.map((item) => item - 4)
    setPreviousWeekNumbersData(prevWeeksData)
  }

  const onNextButtonClick = () => {
    const prevWeeksData = previousWeekNumbersData.map((item) => item + 4)
    setPreviousWeekNumbersData(prevWeeksData)
  }

  const getBackgroundColor = (value) => {
    if (value) {
      switch (value.toUpperCase()) {
        case 'GREEN':
          return '#33D27E42'
        case 'AMBER':
          return '#ffdd76'
        case 'RED':
          return '#f08080'
        default:
          return ''
      }
    } else {
      return ''
    }
  }

  const content = (week, rowData, messageContent) => {
    setOpenDialog(true)
    const details = (
      <div>
        <b>Account</b> : {rowData.account}
        <br />
        <b>Project</b> : {rowData.project}
        <br />
        <b>Workstream</b> : {rowData.workstream} <br />
        <b>Week</b> : {week} <br />
        <div style={{ display: 'flex', marginTop: '2px' }}>
          <div
            style={{
              border: '1px solid white',
              padding: '5px',
              backgroundColor: getBackgroundColor(
                messageContent?.customer_escalations
              ),
            }}
          >
            Customer Escalation
          </div>
          <div
            style={{
              border: '1px solid white',
              padding: '5px',
              backgroundColor: getBackgroundColor(
                messageContent?.project_overrun
              ),
            }}
          >
            Project Overrun
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '2px' }}>
          <div
            style={{
              border: '1px solid white',
              padding: '5px',
              backgroundColor: getBackgroundColor(
                messageContent?.cs_escalation
              ),
            }}
          >
            Cs Escalation
          </div>
          <div
            style={{
              border: '1px solid white',
              padding: '5px',
              backgroundColor: getBackgroundColor(
                messageContent?.delivery_escalation
              ),
            }}
          >
            Delivery Escalation
          </div>
        </div>
        <div>
          <strong>Issues:</strong> {messageContent.issues}
        </div>
        <div>
          <strong>Corrective Action:</strong>{' '}
          {messageContent.corrective_actions}
        </div>
        <div>
          <strong>Preventive Action:</strong>{' '}
          {messageContent.preventive_actions}
        </div>
      </div>
    )
    setWeekActionDetails(details)
  }

  const getStatusCounts = (rowData, event) => {
    setOpenYtdDialog(true)

    const details = (
      <table>
        <tbody>
          <tr>
            <td style={{ fontWeight: 'bold', width: '105px' }}>Account</td>
            <td>
              <span style={{ paddingRight: '2px' }}>:</span>
              {rowData.account}
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 'bold', width: '105px' }}>Project</td>
            <td>
              <span style={{ paddingRight: '2px' }}>:</span>
              <span>{rowData.project}</span>
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 'bold', width: '105px' }}>Workstream</td>
            <td>
              <span style={{ paddingRight: '2px' }}>:</span>
              {rowData.workstream}
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 'bold', width: '105px' }}>Weeks In Red</td>
            <td>
              <span style={{ paddingRight: '2px' }}>:</span>
              {rowData?.redStatusCount}
            </td>
          </tr>

          <tr>
            <td style={{ fontWeight: 'bold', width: '105px' }}>
              Weeks In Amber
            </td>
            <td>
              <span style={{ paddingRight: '2px' }}>:</span>
              {rowData?.amberStatusCount}
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold', width: '105px' }}>
              Weeks In Green
            </td>
            <td>
              <span style={{ paddingRight: '2px' }}>:</span>
              {rowData?.greenStatusCount}
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold', width: '105px' }}>
              Weeks Reported
            </td>
            <td>
              <span style={{ paddingRight: '2px' }}>:</span>
              {rowData?.totalStatusCount}
            </td>
          </tr>
        </tbody>
      </table>
    )
    setStatusDetails(details)
  }

  const weeksDataColumns = useMemo(
    () => [
      {
        label: `${weeksData[3].title}`,
        name: `week_${weeksData[3].weekNo}`,
        options: {
          sort: false,
          filter: false,
          display: previousWeekNumbersData[0] >= 4 ? true : false,
          setCellProps: (cellValue, rowIndex, columnIndex) => {
            const dynamicKey = [`week_${weeksData[3].weekNo}`]
            const value = rows[rowIndex][dynamicKey]
            let styleValue = {}
            if (value === 'AMBER') {
              styleValue = {
                // '#FFC000' }
                backgroundColor: '#ffdd76',
              }
            } else if (value === 'GREEN') {
              styleValue = { backgroundColor: '#33D27E42' }
            } else if (value === 'RED') {
              styleValue = { backgroundColor: '#f08080' }
            } else {
              styleValue = { backgroundColor: '' }
            }
            return {
              style: styleValue,
            }
          },
          customBodyRender: (value, tableMeta, updateValue) =>
            value ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {value}
                <LightTooltip title="Click Here">
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() =>
                      content(
                        weeksData[3].title,
                        rows[tableMeta.rowIndex],
                        rows[tableMeta.rowIndex][
                          `week_${weeksData[3].weekNo}_toolTipData`
                        ]
                      )
                    }
                  >
                    <InfoOutlinedIcon sx={{ height: '14px' }} />
                  </IconButton>
                </LightTooltip>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                -
              </div>
            ),
          customHeadLabelRender: (columnMeta) => {
            return (
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'start',
                }}
              >
                {previousWeekNumbersData[3] == 1 ? (
                  ''
                ) : (
                  <ArrowLeftIcon
                    sx={{ marginRight: '1px', cursor: 'pointer' }}
                    onClick={onPreviousButtonClick}
                  />
                )}
                {columnMeta.label}
              </div>
            )
          },
        },
      },
      {
        label: `${weeksData[2].title}`,
        name: `week_${weeksData[2].weekNo}`,
        options: {
          sort: false,
          filter: false,
          display:
            previousWeekNumbersData[1] >= 3 || previousWeekNumbersData[0] >= 3
              ? true
              : false,
          setCellProps: (cellValue, rowIndex, columnIndex) => {
            const dynamicKey = [`week_${weeksData[2].weekNo}`]
            const value = rows[rowIndex][dynamicKey]
            let styleValue = {}
            if (value === 'AMBER') {
              styleValue = {
                backgroundColor: '#ffdd76',
              }
            } else if (value === 'GREEN') {
              styleValue = { backgroundColor: '#33D27E42' }
            } else if (value === 'RED') {
              styleValue = { backgroundColor: '#f08080' }
            } else {
              styleValue = { backgroundColor: '' }
            }
            return {
              style: styleValue,
            }
          },
          customBodyRender: (value, tableMeta, updateValue) =>
            value ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {value}
                <LightTooltip title="Click Here">
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() =>
                      content(
                        weeksData[2].title,
                        rows[tableMeta.rowIndex],
                        rows[tableMeta.rowIndex][
                          `week_${weeksData[2].weekNo}_toolTipData`
                        ]
                      )
                    }
                  >
                    <InfoOutlinedIcon sx={{ height: '14px' }} />
                  </IconButton>
                </LightTooltip>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                -
              </div>
            ),
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: `${weeksData[1].title}`,
        name: `week_${weeksData[1].weekNo}`,
        options: {
          sort: false,
          filter: false,
          display: previousWeekNumbersData[1] >= 1 ? true : false,
          setCellProps: (cellValue, rowIndex, columnIndex) => {
            const dynamicKey = [`week_${weeksData[1].weekNo}`]
            const value = rows[rowIndex][dynamicKey]
            let styleValue = {}
            if (value === 'AMBER') {
              styleValue = {
                // '#FFC000' }
                backgroundColor: '#ffdd76',
              }
            } else if (value === 'GREEN') {
              styleValue = { backgroundColor: '#33D27E42' }
            } else if (value === 'RED') {
              styleValue = { backgroundColor: '#f08080' }
            } else {
              styleValue = { backgroundColor: '' }
            }
            return {
              style: styleValue,
            }
          },
          customBodyRender: (value, tableMeta, updateValue) =>
            value ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {value}
                <LightTooltip title="Click Here">
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() =>
                      content(
                        weeksData[1].title,
                        rows[tableMeta.rowIndex],
                        rows[tableMeta.rowIndex][
                          `week_${weeksData[1].weekNo}_toolTipData`
                        ]
                      )
                    }
                  >
                    <InfoOutlinedIcon sx={{ height: '14px' }} />
                  </IconButton>
                </LightTooltip>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                -
              </div>
            ),
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: `${weeksData[0].title}`,
        name: `week_${weeksData[0].weekNo}`,
        options: {
          sort: false,
          filter: false,
          setCellProps: (cellValue, rowIndex, columnIndex) => {
            const dynamicKey = [`week_${weeksData[0].weekNo}`]
            const value = rows[rowIndex][dynamicKey]
            let styleValue = {}
            if (value === 'AMBER') {
              styleValue = {
                // '#FFC000' }
                backgroundColor: '#ffdd76',
              }
            } else if (value === 'GREEN') {
              styleValue = { backgroundColor: '#33D27E42' }
            } else if (value === 'RED') {
              styleValue = { backgroundColor: '#f08080' }
            } else {
              styleValue = { backgroundColor: '' }
            }
            return {
              style: styleValue,
            }
          },
          customBodyRender: (value, tableMeta, updateValue) =>
            value ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {value}
                <LightTooltip title="Click Here">
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() =>
                      content(
                        weeksData[0].title,
                        rows[tableMeta.rowIndex],
                        rows[tableMeta.rowIndex][
                          `week_${weeksData[0].weekNo}_toolTipData`
                        ]
                      )
                    }
                  >
                    <InfoOutlinedIcon sx={{ height: '14px' }} />
                  </IconButton>
                </LightTooltip>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                -
              </div>
            ),
          customHeadLabelRender: (columnMeta) => {
            return (
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'start',
                }}
              >
                {columnMeta.label}
                {previousWeekNumbersData.includes(currentWeekNumber) &&
                consolidatedViewYear === currentYear ? (
                  ''
                ) : previousWeekNumbersData.includes(52) ||
                  (previousWeekNumbersData.includes(53) &&
                    consolidatedViewYear !== currentYear) ? (
                  ''
                ) : (
                  <ArrowRightIcon
                    sx={{ marginRight: '1px', cursor: 'pointer' }}
                    onClick={onNextButtonClick}
                  />
                )}
              </div>
            )
          },
        },
      },
    ],
    [rows, previousWeekNumbersData, filterData]
  )

  const onRemove = (index, removedFilter, filterList) => {
    setAnchorEl(null)
    if (index === 0) {
      const removedItems = rows
        .filter((item) => item.vertical === removedFilter)
        .map((item) => item.account)

      const removeProjects = rows.filter(
        (item) => item.vertical === removedFilter
      )
      const accounts = filterData.account.filter(
        (account) => !removedItems.includes(account)
      )
      const updatedAccounts = rows
        .filter((item) => accounts.includes(item.account))
        .map((item) => item.account)
      const projects = rows
        .filter((item) => updatedAccounts.includes(item.account))
        .map((item) => item)
      const updatedProjects = projects
        .filter((item) => filterData.project.includes(item.project))
        .map((item) => item.project)

      dispatch(
        updateFilterData({
          vertical: filterList[0],
          account: _.uniq(updatedAccounts),
          project: _.uniq(updatedProjects),
        })
      )
    } else if (index === 1) {
      const removedItems = rows
        .filter((item) => item.account === removedFilter)
        .map((item) => item.project)

      const projects = filterData.project.filter(
        (project) => !removedItems.includes(project)
      )
      const updatedProjects = rows
        .filter((item) => projects.includes(item.project))
        .map((item) => item.project)

      dispatch(
        updateFilterData({
          vertical: filterList[0],
          account: filterList[1],
          project: _.uniq(updatedProjects),
        })
      )
    } else if (index === 2) {
      dispatch(
        updateFilterData({
          vertical: filterList[0],
          account: filterList[1],
          project: filterList[2],
        })
      )
    }
  }
  const handleClear = () => {
    setSearchText('')
  }
  const options = {
    responsive: 'standard',
    selectableRows: 'none',
    search: false,
    searchText: searchText,
    filter: false,
    print: false,
    textLabels: {
      body: {
        noMatch:
          loading && rows.length === 0 ? (
            <Loading open={loading} />
          ) : (
            'No records found.'
          ),
      },
    },
    customToolbar: () => {
      return (
        <>
          <div className={styles.consolidatedtoolbarContainer}>
            <div className={styles.consolidatedSearchContainer}>
            {search && <InputBase
                    placeholder="Search..."
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    className={styles.consolidatedSearchInput}
                  />}
                  {searchText ? (
                    <IconButton type="button" onClick={handleClear}>
                      <ClearIcon onClick={handleClear} />
                    </IconButton>
                  ) : (
                    <SearchIcon className={styles.searchIcon} onClick={handleSearchClick}/>
                  )}{' '}
              {consolidatedViewInitialData.length > 0 && (
                <><Tooltip title={'Click here to filter the data'}>
                  <FilterListIcon
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    sx={{
                      margin: '-8px',
                      marginLeft: '8px',
                      cursor: 'pointer',
                    }} />
                </Tooltip><Tooltip title={'Download CSV'} arrow>
                    <Button    sx={{
                      margin: '-8px',
                      marginLeft: '8px',
                      cursor: 'pointer',
                    }} onClick={handleDownload}>
                      <CloudDownloadIcon />
                    </Button>
                  </Tooltip></>
              )}
  
              <Popper id={id} open={open} anchorEl={anchorEl}>
                <Box sx={{ p: 1.2, backgroundColor: '#f9f9f9' }}>
                  <FilterComponent
                    setAnchorEl={setAnchorEl}
                    setfilter={setfilter}
                    keyName={'consolidatedView'}
                  />
                </Box>
              </Popper>
            </div>
          </div>
        </>
      )
    },
    viewColumns: false,
    download:false,
    rowsPerPageOptions: [5, 10, 25, 50],
    fixedHeader: true,
    tableBodyHeight: '420px',
    elevation: 0,
    expandableRowsHeader: false,
    onFilterChipClose: (index, removedFilter, filterList) => {
      onRemove(index, removedFilter, filterList)
    },
  }

  const columns = useMemo(
    () => [
      {
        label: 'Vertical',
        name: 'vertical',
        options: {
          width: 400,
          filterList:
            consolidatedViewInitialData.length > 0 ? filterData?.vertical : [],
          display: 'excluded',
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Account',
        name: 'account',
        options: {
          width: 400,
          filterList:
            consolidatedViewInitialData.length > 0 ? filterData?.account : [],
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Project',
        name: 'project',
        options: {
          filterList:
            consolidatedViewInitialData.length > 0 ? filterData?.project : [],
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Workstream',
        name: 'workstream',
        options: {
          filter: false,
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      // {
      //     label: 'ADH',
      //     name: 'adh',
      //     options: {
      //         filter: false,
      //         customHeadLabelRender: (columnMeta) => {
      //             return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
      //         },
      //         customBodyRender: (value, tableMeta, updateValue) => (
      //             <div style={{ display: 'flex', alignItems: 'center' }}>
      //                 {value ? (
      //                     <ul>
      //                    { value.map((email, index) => (

      //                         <li key={index}>{email}</li>
      //                     ))
      //                    }
      //                     </ul>
      //                 ) : (
      //                     <div style={{ display: 'flex', alignItems: 'center' }}>
      //                         {'-'}
      //                     </div>
      //                 )}
      //             </div>
      //         )

      //     },
      // },

      ...weeksDataColumns,
      {
        label: '%Red YTD',
        name: 'prcentageRed',
        options: {
          filter: false,
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                style={{ textAlign: 'center' }}
                onClick={(event) =>
                  handlePopoverClick(rows[tableMeta.rowIndex], event)
                }
              >
                {value}
              </div>
            )
          },
        },
      },
      {
        label: 'Weeks In Red',
        name: 'redStatusCount',
        options: {
          display: 'exclude',
        },
      },
      {
        label: 'Weeks In Amber',
        name: 'amberStatusCount',
        options: {
          display: 'exclude',
        },
      },
      {
        label: 'Weeks In Green',
        name: 'greenStatusCount',
        options: {
          display: 'exclude',
        },
      },
      {
        label: 'Weeks Reported',
        name: 'totalStatusCount',
        options: {
          display: 'exclude',
        },
      },
    ],
    [rows, previousWeekNumbersData, consolidatedViewYear, filterData]
  )
  const handleClose = () => {
    setOpenDialog(false)
  }
  const handleYtdClose = () => {
    setOpenYtdDialog(false)
  }
  return (
    <>
     <div className={styles.yearDropdown}
        >
          <div className={styles.yearTitle}><h4>Year : </h4></div>
          <div className={styles.yearSelect}><Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="outlined"
            value={consolidatedViewYear}
            onChange={handleYearChange}
            sx={{ minWidth: '80px', height: '30px' }}
            size="small"
          >
            {years.map((year) => (
              <MenuItem value={year}>{year}</MenuItem>
            ))}
          </Select></div>
        </div>
      <MUIDataTable data={rows} columns={columns} options={options} />
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="xs"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: '1',
          fontSize: 12,
          borderRadius: 3,
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {weekActionDetails}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Popover
        open={openYtdDialog}
        onClose={handleYtdClose}
        aria-describedby="alert-dialog-slide-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: '1',
          fontSize: 12,
          borderRadius: 3,
        }}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '400px',
            maxHeight: '100%',
            margin: '15px',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          <Typography
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            {statusDetails}
          </Typography>
        </div>
      </Popover>
    </>
  )
}

export default ConsolidatedHealthView
