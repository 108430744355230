import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import _ from 'lodash'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import API from '../../../Common/api'
import Loading from '../../../Common/Loading'
import {
  getCsatByProject,
  getMetaDataonAccount,
  getMetaDataonProject,
  saveCsat,
  updateCsat,
} from '../../../services/csatService'

const FormDialog = (props) => {
  const { enqueueSnackbar: notify } = useSnackbar()
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [vertical, setVertical] = useState('')
  const [verticalId, setVerticalId] = useState(null)
  const [account, setAccount] = useState('')
  const [accountId, setAccountId] = useState(null)
  const [project, setProject] = useState('')
  const [projectLead, setProjectLead] = useState('')
  const [projectLeadList, setProjectLeadList] = useState([])
  const [projectLeadEmail, setProjectLeadEmail] = useState('')
  const [projectmetadata, setProjectMetadata] = useState([])
  const [projectId, setProjectId] = useState(null)
  const [uniqueId, setUniqueId] = useState(null)
  const [projectList, setProjectList] = useState([])
  const [workstream, setWorkStream] = useState(null)
  const [workstreamId, setWorkStreamId] = useState(null)
  const [existingworkstreams, setExistingWorkStreams] = useState([])
  const [csperson, setCsperson] = useState('')
  const [csEmail, setCsEmail] = useState('')
  const [InternalSurveyee, setInternalSurveyee] = useState('')
  const [InternalSurveyeeLevel, setInternalSurveyeeLevel] = useState()
  const [clientEmailId, setClientEmailId] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [emailErrorProjectLead, setEmailErrorProjectLead] = useState(false)
  const [clientPrefferredName, setClientPrefferredName] = useState('')
  const [clientTenure, setClientTenure] = useState('')
  const [surveyType, setSurveyType] = useState('')
  const [clientName, setClientName] = useState('')
  const [clientDesignation, setClientDesignation] = useState('')
  const [clientFirstName, setClientFirstName] = useState('')
  const [clientLastName, setClientLastName] = useState('')
  const [useClientFirstName, setUseClientFirstName] = useState(false)
  const [directClientcheck, setDirectClientCheck] = useState(true)
  const [directClient, setDirectClient] = useState(null)
  const [existingCsat, setExistingCsat] = useState([])
  const [existingCsatOpen, setExistingCsatOpen] = useState(false)
  const { csatRevieweeInitialData, csatFilterData } = useSelector(
    (state) => state.filter
  )

  const { userDetails } = useSelector((state) => state.user)
  const [alternateClientName, setAlternateClientName] = useState('')
  const [alternateemailError, setAlternateEmailError] = useState(false)
  const [alternateclientEmailId, setAlternateClientEmailId] = useState('')
  const [clientBaseLocation, setClientBaseLocation] = useState('')
  const [clientLinkedin, setClientLinkedin] = useState('')
  const [clientRevenue, setClientRevenue] = useState('')
  const [revenuesOptions, setRevenuesOptions] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { data: adminData } = useSelector((state) => state.admin)
  const [countriesOptions, setCountriesOptions] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [searchInputInternalServeyee, setSearchInputInternalServeyee] =
    useState('')
  const [searchInputProjectlead, setSearchInputProjectlead] = useState('')
  const [openLevelAlert, setOpenLevelAlert] = useState(false)
  const [acceptLevel, setAcceptLevel] = useState('')
  const lowerJobLevels = ['L1', 'L2', 'L3']

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const CreatedBy =
    props?.editRow?.created_by !== 'Admin'
      ? props?.employees?.find(
          (emp) => emp?.employee_id == props?.editRow?.created_by
        )?.employee_name || '-'
      : props?.editRow?.created_by
  const UpdatedBy =
    props?.editRow?.updated_by !== 'Admin'
      ? props?.employees?.find(
          (emp) => emp?.employee_id == props?.editRow?.updated_by
        )?.employee_name || '-'
      : props?.editRow?.updated_by

  const columns = useMemo(
    () => [
      {
        label: 'Vertical',
        name: 'vertical',
        options: {
          filterList: csatFilterData?.vertical,
          display: 'excluded',
        },
      },
      {
        label: 'Account',
        name: 'account',
        options: {
          searchable: true,
          filterList: csatFilterData?.account,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Project',
        name: 'project',
        options: {
          searchable: true,
          filterList: csatFilterData?.project,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Work Stream',
        name: 'workstream',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'CS Email',
        name: 'cs_emailId',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client Name',
        name: 'client_name',
        options: {
          searchable: true,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Client Email',
        name: 'client_email',
        options: {
          display: 'excluded',
        },
      },
      {
        label: 'Client Designation',
        name: 'client_designation',
        options: {
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Client First Name',
        name: 'client_first_name',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client Last Name',
        name: 'client_last_name',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client Preferred Name',
        name: 'client_prefferred_name',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Tenure',
        name: 'client_tenure',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Survey Type',
        name: 'survey_type',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Internal Surveyee',
        name: 'internal_surveyee',
        options: {
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Direct Client',
        name: 'direct_client',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Time Period',
        name: 'time_period',
        options: {
          searchable: true,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Created By',
        name: 'created_by',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Created At',
        name: 'createdAt',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Updated By',
        name: 'updated_by',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Updated At',
        name: 'updatedAt',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
    ],
    []
  )

  const options = {
    responsive: 'standard',
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: 'No nominations to display!',
      },
    },
    search: false,
    print: false,
    filter: false,
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 25, 50],
    rowsPerPage: 5,
    elevation: 2,
    download: false,
  }

  function splitFullName(fullName) {
    let firstSpaceIndex = fullName?.indexOf(' ')
    let firstName =
      firstSpaceIndex === -1 ? fullName : fullName?.slice(0, firstSpaceIndex)

    let lastName =
      firstSpaceIndex === -1 ? '' : fullName?.slice(firstSpaceIndex + 1)

    return {
      firstName: firstName,
      lastName: lastName,
    }
  }

  const SplitAndSetProjectLeads = (leads, existinglead) => {
    const lead = leads ? leads.toLowerCase() : leads
    if (lead?.length > 1 && lead?.split(',')?.length === 1) {
      if (existinglead) {
        setProjectLead(
          props?.employees?.find((v) => v?.email_id === existinglead)
        )
        setProjectLeadEmail(existinglead)
      } else {
        setProjectLead(props?.employees?.find((v) => v?.email_id === lead))
        setProjectLeadEmail(lead)
      }
      if (props?.employees?.filter((v) => v?.email_id === lead)?.length == 0) {
        setProjectLeadList(adminData?.employeeDetails, 'email_id')
      } else {
        setProjectLeadList(
          props?.employees?.filter((v) => v?.email_id === lead)
        )
      }
    } else if (lead?.split(',')?.length > 1 && lead) {
      if (existinglead) {
        setProjectLead(
          props?.employees?.find((v) => v?.email_id === existinglead)
        )
        setProjectLeadEmail(existinglead)
      }
      if (
        props?.employees?.filter((v) => lead?.split(',').includes(v?.email_id))
          ?.length == 0
      ) {
        setProjectLeadList(adminData?.employeeDetails, 'email_id')
      } else {
        if (
          props?.employees?.filter((v) =>
            lead?.split(',').includes(v?.email_id)
          )?.length == 1
        ) {
          setProjectLead(
            props?.employees?.find((v) =>
              lead?.split(',').includes(v?.email_id)
            )
          )
        }
        setProjectLeadList(
          props?.employees?.filter((v) =>
            lead?.split(',').includes(v?.email_id)
          )
        )
      }
    } else {
      if (existinglead) {
        setProjectLead(
          props?.employees?.find((v) => v?.email_id === existinglead)
        )
        setProjectLeadEmail(existinglead)
      }

      setProjectLeadList(adminData?.employeeDetails, 'email_id')
    }
  }

  useEffect(() => {
    if (Boolean(props.editForm)) {
      setVertical(props?.editRow?.vertical)
      setVerticalId(props?.editRow?.vertical_id)
      setAccount(props?.editRow?.account)
      if (props?.editRow?.account) {
        getProjectsforAccount(props?.editRow?.account)
      }
      setAccountId(props?.editRow?.account_id)
      setProject(props?.editRow?.project)
      SplitAndSetProjectLeads(
        props?.editRow?.projectLead_emailId,
        props?.editRow?.lead_email ? props?.editRow?.lead_email : null
      )
      setProjectId(props?.editRow?.salesforce_opportunity_id)
      setUniqueId(props?.editRow?.unique_id)
      setWorkStream(props?.editRow?.workstream)
      setWorkStreamId(props?.editRow?.workstream_id)
      setCsEmail(props.editRow?.cs_emailId)
      setCsperson(
        props?.employees?.find(
          (option) => option?.email_id === props.editRow?.cs_emailId
        )?.employee_name
      )
      setInternalSurveyee(props?.editRow?.internal_surveyee)
      setInternalSurveyeeLevel(props?.editRow?.internal_surveyee_level)
      setClientName(props?.editRow?.client_name)
      if (
        props?.editRow?.client_first_name &&
        props?.editRow?.client_last_name
      ) {
        setClientFirstName(props?.editRow?.client_first_name)
        setClientLastName(props?.editRow?.client_last_name)
      } else {
        let name = splitFullName(props?.editRow?.client_name)
        setClientFirstName(name?.firstName)
        setClientLastName(name?.lastName)
      }
      setClientDesignation(props?.editRow?.client_designation)
      setClientPrefferredName(props?.editRow?.client_prefferred_name)
      setClientEmailId(props?.editRow?.client_email)
      setSurveyType(props?.editRow?.survey_type)
      setClientTenure(props?.editRow?.client_tenure)
      setDirectClient(props?.editRow?.direct_client)
      setDirectClientCheck(props?.editRow?.direct_client ? false : true)
      if (props?.editRow?.client_location) {
        setClientBaseLocation(props?.editRow?.client_location)
      }
      setClientLinkedin(props?.editRow?.client_linkedin)
      setClientRevenue(props?.editRow?.client_revenue)
      setAlternateClientName(props?.editRow?.alternate_client_name)
      setAlternateClientEmailId(props?.editRow?.alternate_client_emailId)
      if (
        props?.editRow?.client_first_name?.trim() &&
        props?.editRow?.client_prefferred_name?.trim()
      ) {
        if (
          props?.editRow?.client_first_name?.trim() ===
          props?.editRow?.client_prefferred_name?.trim()
        ) {
          setUseClientFirstName(true)
        }
      }
    }
  }, [props.editForm])

  useEffect(() => {
    handleProjectChange(props?.editRow?.project)
  }, [props?.editRow?.project])

  useEffect(() => {
    const fetchRevenuesList = async () => {
      try {
        const response = await API.get(`/csat/getRevenuesList`)
        const data = response.data.result.map((item) => {
          return item.revenues
        })
        setRevenuesOptions(data)
      } catch (error) {
        //setError(error);
      }
    }
    const fetchCountriesList = async () => {
      try {
        const response = await API.get(`/csat/getCountriesList`)
        const data = response.data.result.map((item) => {
          return item.country_name
        })
        setCountriesOptions(response.data.result)
      } catch (error) {
        //setError(error);
      }
    }
    fetchRevenuesList()
    fetchCountriesList()
  }, [])

  const handleProjectLeadChange = (event, val) => {
    event.preventDefault()
    if (val) {
      setProjectLeadEmail(val?.email_id)
      setProjectLead(val)
    }
  }

  const handleClientFirstNameChange = (e) => {
    setUseClientFirstName(false)
    setClientFirstName(e.target.value)
  }

  const handleClickInternalSurveyee = (e, val) => {
    setInternalSurveyee(val?.email_id)
    setInternalSurveyeeLevel(val?.job_level)
    if (lowerJobLevels?.includes(val?.job_level)) {
      setOpenLevelAlert(true)
    }
  }

  const handleAcceptLevel = () => {
    setAcceptLevel('yes')
    handleCloseLevelAlert()
  }

  const handleCancelLevel = () => {
    setInternalSurveyee('')
    handleCloseLevelAlert()
  }

  const handleCloseLevelAlert = () => {
    setOpenLevelAlert(false)
  }

  const handleEmailChange = (e, alt) => {
    const email = e?.target?.value
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    // Check if the entered email matches the regex pattern
    if (emailRegex.test(email?.trim())) {
      if (alt == 'alternate') {
        setAlternateClientEmailId(email)
        setAlternateEmailError(false)
      } else {
        setClientEmailId(email)
        setEmailError(false)
      }
      if (props?.editRow?.client_email !== clientEmailId?.trim()) {
        //  code to check client email duplicate.
        const query = {
          account: account,
          project: project,
          client_email: clientEmailId?.trim(),
          time_period: '2024 H1',
        }
        let existingcsat = []
        setOpenBackdrop(true)
        getCsatByProject(query)
          .then((res) => {
            if (res.success) {
              setExistingCsat(res.data.result)
              existingcsat = res.data.result
            }
            setOpenBackdrop(false)
            if (existingcsat.length > 0) {
              setExistingCsatOpen(true)
              notify(`This client is already nominated!`, {
                variant: 'info',
              })
              setClientEmailId('')
            }
          })
          .catch((error) => {
            setOpenBackdrop(false)
            console.error(
              'Error fetchingcsat by project and clientmail data:',
              error
            )
          })
        setOpenBackdrop(false)
      }
    } else {
      if (alt == 'alternate') {
        setAlternateEmailError(!emailRegex.test(email))
        setAlternateClientEmailId('')
      } else {
        setEmailError(!emailRegex.test(email))
        setClientEmailId('')
      }
    }
  }

  const handleChangePreferredName = (e) => {
    setClientPrefferredName(e?.target?.value)
    if (e?.target?.value !== clientFirstName) {
      setUseClientFirstName(false)
    }
  }

  const handleCheckboxChangePreferredName = (e) => {
    setUseClientFirstName(e?.target?.checked)
    if (e?.target?.checked) {
      setClientPrefferredName(clientFirstName)
    }
  }
  const handleAnswerChange = (event) => {
    setDirectClient(event.target.value)
    setDirectClientCheck(false)
  }
  const handleAccountChange = (event, val) => {
    if (val) {
      setAccount(val)
      setProject('')
      setWorkStream('')
      setProjectLead('')
      setProjectLeadEmail('')
      setClientEmailId('')
      getProjectsforAccount(val)
    }
  }

  const getProjectsforAccount = (account) => {
    setOpenBackdrop(true)
    getMetaDataonAccount(account)
      .then((res) => {
        if (res.success) {
          setVertical(res.data.result[0]?.vertical)
          setVerticalId(res.data.result[0]?.verticalId)
          setAccountId(res.data.result[0]?.accountId)
          const UniqueProjects = _.uniq(
            _.map(res.data.result, 'project')
          )?.sort()
          setProjectList(UniqueProjects)
          setOpenBackdrop(false)
        }
      })
      .catch((error) => {
        console.error('Error fetching account data:', error)
        setOpenBackdrop(false)
      })
  }
  const handleProjectChange = (event, val) => {
    if (val) {
      setProject(val)
      setWorkStream('')
      setProjectLead('')
      setProjectLeadEmail('')
      setOpenBackdrop(true)
      if (clientEmailId) {
        setClientEmailId('')
      }
      getMetaDataonProject(val)
        .then((res) => {
          if (res.success) {
            setProjectMetadata(res.data.result)
            setProjectId(res.data.result[0]?.projectId)
            SplitAndSetProjectLeads(res.data.result[0]?.projectLeadEmail)
            setUniqueId(res.data.result[0]?.unique_id)
            const extractedworkstreams = res.data.result
              ?.filter(({ workstream }) => !workstream == false)
              ?.map(({ workstream, workstream_id, unique_id }) => ({
                workstream,
                workstream_id,
                unique_id,
              }))
            if (extractedworkstreams.length == 1) {
              setWorkStream(extractedworkstreams[0].workstream)
              setWorkStreamId(extractedworkstreams[0].workstream_id)
              setUniqueId(extractedworkstreams[0].unique_id)
            }
            setExistingWorkStreams(extractedworkstreams)
          }
          setOpenBackdrop(false)
        })
        .catch((error) => {
          console.error('Error fetching project data:', error)
          setOpenBackdrop(false)
        })
    }
  }

  const handleWorkstreamChange = (event, val) => {
    setProjectLead('')
    setProjectLeadEmail('')
    if (val) {
      setWorkStream(val?.workstream)
      setWorkStreamId(val?.workstream_id)
      setUniqueId(val?.unique_id)
      const selected = projectmetadata?.filter(
        (obj) => obj?.unique_id === val?.unique_id
      )
      SplitAndSetProjectLeads(selected[0].projectLeadEmail)
    }
  }

  const SaveOrUpdateCsat = (filledData, isUpdate) => {
    if (isUpdate) {
      setOpenBackdrop(true)
      updateCsat(filledData)
        .then((res) => {
          if (res.success) {
            notify(`Data Updated Successfully !`, {
              variant: 'success',
            })
            Promise.all([props?.getCsat(), props?.getCsatHistoricalData()])
              .then(() => {
                setOpenBackdrop(false)
                handleClose()
              })
              .catch((error) => {
                console.error('Error fetching data:', error)
                handleClose()
                setOpenBackdrop(false)
              })
          }
        })
        .catch((error) => {
          notify(`Couldn't Update CSAT!`, {
            variant: 'error',
          })
          console.error('Error while updating csat:', error)
          setOpenBackdrop(false)
        })
    } else {
      setOpenBackdrop(true)
      saveCsat(filledData)
        .then((res) => {
          if (res.success) {
            notify(`Data Saved Successfully !`, {
              variant: 'success',
            })
            Promise.all([props?.getCsat()])
              .then(() => {
                setOpenBackdrop(false)
                handleClose()
              })
              .catch((error) => {
                console.error('Error fetching data:', error)
                handleClose()
                setOpenBackdrop(false)
              })
          }
        })
        .catch((error) => {
          notify(`Couldn't Save CSAT!`, {
            variant: 'error',
          })
          console.error('Error while saving csat:', error)
          setOpenBackdrop(false)
        })
    }
  }

  const handleSave = (event) => {
    event.preventDefault()
    if (directClientcheck) {
      notify(
        ` Please mention if you are directly engaged in work with this client`,
        {
          variant: 'info',
        }
      )
    } else {
      if (!props?.editRow?.csat_id) {
        const filledData = {
          salesforce_opportunity_id: projectId,
          vertical: vertical,
          vertical_id: verticalId,
          account: account,
          account_id: accountId,
          project: project,
          unique_id: uniqueId,
          workstream: workstream,
          workstream_id: workstreamId,
          cs_emailId: csEmail,
          client_first_name: clientFirstName?.trim(),
          client_last_name: clientLastName?.trim(),
          client_name: clientFirstName?.trim() + ' ' + clientLastName?.trim(),
          client_designation: clientDesignation,
          client_email: clientEmailId?.trim(),
          client_prefferred_name: clientPrefferredName?.trim(),
          client_tenure: clientTenure,
          survey_type: surveyType,
          internal_surveyee: InternalSurveyee,
          internal_surveyee_level_acceptance: acceptLevel,
          direct_client: directClient,
          time_period: '2024 H1',
          created_by: props?.user?.employee_id,
          createdAt: new Date(),
          client_location: clientBaseLocation,
          client_linkedin: clientLinkedin,
          client_revenue: clientRevenue,
          alternate_client_name: alternateClientName,
          alternate_client_emailId: alternateclientEmailId,
          projectLead_emailId: projectLeadEmail,
        }
        SaveOrUpdateCsat(filledData, false)
      } else {
        const filledData = {
          rowId: props?.editRow?.csat_id,
          data: {
            workstream: workstream,
            workstream_id: workstreamId,
            cs_emailId: csEmail,
            client_first_name: clientFirstName?.trim(),
            client_last_name: clientLastName?.trim(),
            client_name: clientFirstName?.trim() + ' ' + clientLastName?.trim(),
            client_designation: clientDesignation,
            client_email: clientEmailId?.trim(),
            client_prefferred_name: clientPrefferredName?.trim(),
            client_tenure: clientTenure,
            survey_type: surveyType,
            internal_surveyee: InternalSurveyee,
            internal_surveyee_level_acceptance: acceptLevel,
            direct_client: directClient,
            time_period: '2024 H1',
            updated_by: props?.user?.employee_id,
            updatedAt: new Date(),
            client_location: clientBaseLocation,
            client_linkedin: clientLinkedin,
            client_revenue: clientRevenue,
            alternate_client_name: alternateClientName,
            alternate_client_emailId: alternateclientEmailId,
            projectLead_emailId: projectLeadEmail,
          },
        }
        if (props.view == 'historical') {
          if (emailErrorProjectLead) {
            notify(
              `The Project Lead Email entered is Invalid,Please enter a valid email or proceed without one!`,
              {
                variant: 'info',
              }
            )
            setEmailErrorProjectLead(false)
          } else {
            filledData.data.projectLead_emailId = projectLeadEmail
            if (!props?.editRow?.vertical || !props?.editRow?.vertical_id) {
              filledData.data.salesforce_opportunity_id = projectId
              filledData.data.vertical = vertical
              filledData.data.vertical_id = verticalId
              filledData.data.account = account
              filledData.data.account_id = accountId
              filledData.data.project = project
            } else if (
              !props?.editRow?.account ||
              !props?.editRow?.account_id
            ) {
              filledData.data.salesforce_opportunity_id = projectId
              filledData.data.account = account
              filledData.data.account_id = accountId
              filledData.data.project = project
            } else if (
              !props?.editRow?.project ||
              !props?.editRow?.salesforce_opportunity_id
            ) {
              filledData.data.salesforce_opportunity_id = projectId
              filledData.data.project = project
            }
            SaveOrUpdateCsat(filledData, true)
          }
        } else {
          SaveOrUpdateCsat(filledData, true)
        }
      }
    }
  }

  const handleClose = () => {
    props.onClose()
    setAccount('')
    setAccountId(null)
    setProject('')
    setProjectList([])
    setProjectId(null)
    setProjectLead('')
    setProjectLeadList([])
    setProjectLeadEmail('')
    setWorkStream(null)
    setWorkStreamId(null)
    setExistingWorkStreams([])
    setUniqueId(null)
    setCsEmail('')
    setCsperson('')
    setInternalSurveyee('')
    setInternalSurveyeeLevel('')
    setAcceptLevel()
    setClientFirstName('')
    setClientLastName('')
    setClientName('')
    setClientDesignation('')
    setClientPrefferredName('')
    setClientEmailId('')
    setSurveyType('')
    setClientTenure('')
    setUseClientFirstName(false)
    setDirectClientCheck(true)
    setDirectClient(null)
    setEmailError(false)
    setEmailErrorProjectLead(false)
    setExistingCsat([])
    setAlternateClientEmailId('')
    setAlternateClientName('')
    setAlternateEmailError(false)
    setClientBaseLocation('')
    setClientLinkedin('')
    setClientRevenue('')
  }

  const handleCloseExistingCsat = () => {
    setExistingCsatOpen(false)
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.employee_name,
  })

  const renderOption = useCallback(
    (props, option) => (
      <Tooltip title={option?.email_id || ''} arrow>
        <li {...props}>{option?.employee_name}</li>
      </Tooltip>
    ),
    []
  )

  return (
    <>
      <Dialog maxWidth="md" open={props.open} onClose={handleClose}>
        <DialogTitle
          DialogTitle
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div>Nominate client for 2024 H1</div>
          {props?.editRow?.csat_id && props.view !== 'historical' && (
            <>
              <div style={{ fontSize: '10px', marginRight: '20px' }}>
                <b>Created By</b> : {CreatedBy}
                <br />
                <b>Updated By</b> : {UpdatedBy}
              </div>
            </>
          )}
        </DialogTitle>
        <Loading open={openBackdrop} />
        <DialogContent>
          {/* <br></br> */}
          <form autocomplete="off" onSubmit={handleSave}>
            <Grid align="center" container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={props?.editRow?.account ? true : false}
                  options={props?.accounts}
                  value={props?.editForm ? props?.editRow?.account : account}
                  onChange={handleAccountChange}
                  autoComplete="off"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Account"
                      variant="outlined"
                      required
                      error={Boolean(!account)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Tooltip
                  title={
                    !account && projectList?.length == 0
                      ? 'Please Choose Account'
                      : ''
                  }
                >
                  <Autocomplete
                    disabled={
                      props?.view == 'current' && props?.editRow?.project
                        ? true
                        : false
                    }
                    options={projectList}
                    autoComplete="off"
                    value={project}
                    onChange={handleProjectChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Project"
                        variant="outlined"
                        required
                        error={Boolean(!project)}
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip
                  title={
                    !props?.editRow?.workstream &&
                    existingworkstreams?.length == 0
                      ? 'No workstreams'
                      : ''
                  }
                >
                  <Autocomplete
                    // freeSolo={true}
                    autoComplete="off"
                    disabled={existingworkstreams?.length > 0 ? false : true}
                    options={_.sortBy(existingworkstreams, 'workstream')}
                    getOptionLabel={(option) =>
                      option?.workstream ? option?.workstream : ''
                    }
                    value={
                      props?.workstreams?.find(
                        (option) => option?.workstream === workstream
                      ) || ''
                    }
                    defaultValue={props?.editRow?.workstream}
                    onChange={handleWorkstreamChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="medium"
                        label="Workstream"
                        variant="outlined"
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="project lead"
                  autoComplete="off"
                  options={projectLeadList}
                  value={
                    adminData?.employeeDetails?.find(
                      (option) => option?.email_id === projectLead?.email_id
                    ) || ''
                  }
                  getOptionLabel={(option) =>
                    option?.employee_name ? option?.employee_name : ''
                  }
                  filterOptions={filterOptions}
                  onChange={handleProjectLeadChange}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      inputProps={{
                        style: {
                          height: '8px',
                        },
                      }}
                      {...params}
                      label="Project Lead"
                      variant="outlined"
                      // required
                      // error={Boolean(!projectLeadEmailId)}
                    />
                  )}
                  renderOption={renderOption}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  id="csperson"
                  autoComplete="off"
                  options={props?.employees}
                  getOptionLabel={(option) =>
                    option.employee_name ? option.employee_name : ''
                  }
                  getOptionSelected={(option) => option?.employee_name}
                  filterOptions={filterOptions}
                  value={
                    props.employees?.find(
                      (option) => option?.employee_name === csperson
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setCsperson(newValue?.employee_name)
                    setCsEmail(newValue?.email_id)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={Boolean(!csperson)}
                      label="CS Person"
                      variant="outlined"
                    />
                  )}
                  renderOption={renderOption}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  autoComplete="off"
                  id="internal surveyee"
                  options={props?.employees}
                  isOptionEqualToValue={(option, value) =>
                    option?.employee_name === value?.employee_name
                  }
                  getOptionLabel={(option) =>
                    option.employee_name ? option.employee_name : ''
                  }
                  getOptionSelected={(option) => option?.employee_name}
                  defaultValue={
                    props.editRow?.internal_surveyee
                      ? props.editRow?.internal_surveyee
                      : null
                  }
                  filterOptions={filterOptions}
                  value={
                    props.employees?.find(
                      (option) =>
                        option?.employee_name?.toLowerCase() ===
                          InternalSurveyee?.toLowerCase() ||
                        option?.email_id?.toLowerCase() ===
                          InternalSurveyee?.toLowerCase()
                    ) || ''
                  }
                  onChange={handleClickInternalSurveyee}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={Boolean(!InternalSurveyee)}
                      label="Internal Surveyee"
                      variant="outlined"
                    />
                  )}
                  renderOption={renderOption}
                />
                <span
                  style={{
                    display: 'flex',
                    fontSize: '11px',
                    color: 'red',
                    justifyContent: 'flex-start',
                  }}
                >
                  *L4 & above
                </span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoComplete="new-password" //   margin="normal"
                  label="Client First Name"
                  value={clientFirstName}
                  required
                  error={Boolean(!clientFirstName)}
                  onChange={handleClientFirstNameChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  //   margin="normal"
                  autoComplete="new-password"
                  label="Client Last Name"
                  required
                  error={Boolean(!clientLastName)}
                  value={clientLastName}
                  onChange={(e) => setClientLastName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  //   margin="normal"
                  autoComplete="new-password"
                  label="Client Email"
                  required
                  error={Boolean(!clientEmailId)}
                  value={clientEmailId}
                  helperText={emailError ? 'Invalid email address' : ''}
                  onChange={(e) => setClientEmailId(e.target.value)}
                  onBlur={handleEmailChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  //   margin="normal"
                  autoComplete="new-password"
                  label="Client Designation"
                  required
                  error={Boolean(!clientDesignation)}
                  value={clientDesignation}
                  onChange={(e) => setClientDesignation(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  //   margin="normal"
                  autoComplete="new-password"
                  label="Client Preferred Name"
                  required
                  error={Boolean(
                    !clientPrefferredName &&
                      !props?.editRow?.client_prefferred_name
                  )}
                  value={clientPrefferredName}
                  onChange={handleChangePreferredName}
                  fullWidth
                />
                {clientFirstName && (
                  <FormControlLabel
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                    control={
                      <Checkbox
                        checked={useClientFirstName}
                        onChange={handleCheckboxChangePreferredName}
                        color="primary"
                      />
                    }
                    label="Same as Client First Name"
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  freeSolo={true} // Allow free text entry
                  autoComplete="off"
                  options={[
                    '<3 months',
                    '3-6 months',
                    '6-12 months',
                    '>12 months',
                  ]}
                  defaultValue={
                    props.editRow?.client_tenure
                      ? props.editRow?.client_tenure
                      : null
                  }
                  value={clientTenure}
                  onChange={(event, newValue) => setClientTenure(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={Boolean(!clientTenure)}
                      onChange={(e) => setClientTenure(e.target.value)}
                      label="Client Tenure"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  autoComplete="off"
                  options={countriesOptions}
                  value={clientBaseLocation}
                  onChange={(event, newValue) =>
                    setClientBaseLocation(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!clientBaseLocation}
                      label="Client Base Location (Country)"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  //   margin="normal"
                  autoComplete="new-password"
                  label="Client LinkedIn"
                  value={clientLinkedin}
                  onChange={(e) => setClientLinkedin(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  autoComplete="off"
                  options={revenuesOptions}
                  value={clientRevenue}
                  onChange={(event, newValue) => setClientRevenue(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!clientRevenue}
                      label="Revenue Bucket"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  options={['Generic', 'Solution Support']}
                  autoComplete="off"
                  value={surveyType}
                  defaultValue={
                    props?.editRow?.survey_type
                      ? props?.editRow?.survey_type
                      : null
                  }
                  onChange={(event, newValue) => setSurveyType(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={Boolean(!surveyType)}
                      label="Survey Type"
                    />
                  )}
                />
              </Grid>
              {/* Conditional only for Admin */}
              {userDetails?.role === 'Admin' && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      //   margin="normal"
                      autoComplete="new-password"
                      label="Alternate Client Name"
                      value={alternateClientName}
                      onChange={(e) => setAlternateClientName(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      //   margin="normal"
                      autoComplete="new-password"
                      label="Alternate Client Email"
                      value={alternateclientEmailId}
                      helperText={
                        alternateemailError ? 'Invalid email address' : ''
                      }
                      onChange={(e) =>
                        setAlternateClientEmailId(e.target.value)
                      }
                      onBlur={(e) => handleEmailChange(e, 'alternate')}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={isSmallScreen ? 12 : 6}>
                <FormLabel
                  component="legend"
                  style={{
                    display: 'flex',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    justifyContent: 'flex-start',
                  }}
                >
                  Are you directly engaged in work with this client?
                  <span style={!directClient ? { color: 'red' } : {}}>*</span>
                </FormLabel>
                <RadioGroup
                  aria-label="answer"
                  name="answer"
                  value={directClient}
                  onChange={handleAnswerChange}
                >
                  <Grid container direction="row" spacing={10}>
                    <Grid item>
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Grid>
              {/* <div > */}
              <Grid
                item
                xs={12}
                spacing={5}
                display={'flex'}
                alignContent={'flex-end'}
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <DialogActions>
                  <Button variant="contained" color="primary" type="submit">
                    {props.editForm ? 'Update' : 'Save'}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    color="secondary"
                  >
                    Close
                  </Button>
                </DialogActions>
                {/* </div> */}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="md"
        open={existingCsatOpen}
        onClose={handleCloseExistingCsat}
      >
        <DialogTitle>Existing Client Details</DialogTitle>
        <Loading open={openBackdrop} />
        <DialogContent>
          <MUIDataTable
            title=" "
            data={existingCsat}
            columns={columns}
            options={options}
          />
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleCloseExistingCsat}
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog open={openLevelAlert} onClose={handleCloseLevelAlert}>
        <DialogTitle>Internal Surveyee Nomination Alert</DialogTitle>
        <DialogContent sx={{ minWidth: '500px' }}>
          <Stack spacing={0.5}>
            <span
              style={{
                fontFamily: 'inherit',
                fontStyle: 'inherit',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              You are Nominating {InternalSurveyeeLevel} as InternalSurvyee.
            </span>
            <span
              style={{
                fontFamily: 'inherit',
                fontStyle: 'inherit',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              Please nominate L4 & above as per process. If you wish to continue
              with this nomination, click 'OK' else 'Cancel'
            </span>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAcceptLevel}
            variant="outlined"
            sx={{ width: '90px', fontSize: '12px' }}
          >
            OK
          </Button>
          <Button
            onClick={handleCancelLevel}
            variant="outlined"
            sx={{ width: '90px', fontSize: '12px' }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FormDialog
