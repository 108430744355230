import { Navigate } from 'react-router-dom'
import ComplianceReview from './Component/View/ComplianceReview'
import AdminEmployeeUpdate from './Component/View/Admin/AdminEmployeeUpdate'
import EltView from './Component/View/Elt/EltView'
import ProjectHealthView from './Component/View/ProjectHealth/ProjectHealthView'
import Csat from './Component/View/Csat'
import CsatAdminVdh from './Component/View/CsatAdminVdh'
import Reports from './Component/View/Reports/Reports'

const GuardedRoute = ({
  isRouteAccessible = false,
  redirectRoute = '/',
  navigateRoute,
  element,
}) => {
  return isRouteAccessible ? (
    element === 'compliance' ? (
      <ComplianceReview />
    ) : element === 'adminEmpUpdate' ? (
      <AdminEmployeeUpdate />
    ) : element === 'elt' ? (
      <EltView />
    ) : element === 'project-health' ? (
      <ProjectHealthView />
    ) : element === 'csat' ? (
      <Csat />
    ) : element === 'csatNominations' ? (
      <CsatAdminVdh />
    ) : element === 'report' ? (
      <Reports />
    ) : (
      <Navigate replace to={navigateRoute} />
    )
  ) : (
    <Navigate to={redirectRoute} replace />
  )
}

export default GuardedRoute
