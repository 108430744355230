import { getWeek } from 'date-fns'
import moment from 'moment'

export function getWeekNumber(yearData) {
  const currentDate = new Date()
  const startDate = new Date(yearData, 0, 1)
  const currentYear = new Date().getFullYear()
  if (yearData < currentYear) {
    const endDate = new Date(yearData, 11, 31)
    let days = Math.floor((endDate - startDate) / (24 * 60 * 60 * 1000))
    const weekNumber = Math.ceil(days / 7)
    return weekNumber
  } else {
    // let days = Math.floor((currentDate - startDate) /
    // (24 * 60 * 60 * 1000));
    const weekNumber = getWeek(currentDate) //Math.ceil(days / 7);
    return weekNumber ? weekNumber : 1
  }
}
const DAY = 86400000

export function getWeekDate(weekNumber, yearData) {
  // const currentDate = new Date();
  const year = new Date(yearData.toString()) // toString first so it parses correctly year numbers
  const daysToFriday = 5 - year.getDay() // Note that this can be also negative
  const fridayOfFirstWeek = new Date(year.getTime() + daysToFriday * DAY)
  const nthFriday = new Date(
    fridayOfFirstWeek.getTime() + 7 * (weekNumber - 1) * DAY
  )
  const weekDate = nthFriday.toString().substring(0, 10).substring(3)
  return weekDate
}
export function getFriday(weekNumber, year) {
  // const currentDate = new Date();
  // const startDate = new Date(currentDate.getFullYear(), 0, 1);
  // const startDate = new Date(year, 0, 1);
  // const days = Math.floor((currentDate - startDate) /
  //     (24 * 60 * 60 * 1000));
  // const weekNumber = Math.ceil(days / 7);
  const weekDate = getWeekDate(weekNumber, year)
  return `${weekDate} ${year}`
}

export function ensureSpaceBeforeMeridian(dateString) {
  if (dateString === null) {
    return '-'
  }
  return dateString?.replace(/(\d)(AM|PM)/i, '$1 $2')
}

export function getTotalDaysByVaule(date) {
  const correctedDateString = ensureSpaceBeforeMeridian(date?.replaceAll(',',''))
  const givenDate = new Date(correctedDateString)
  //return correctedDateString
  if (isNaN(givenDate.getTime())) {
    return '-'
  } else {
    const currentDate = new Date()
    givenDate.setUTCHours(0, 0, 0, 0);
    currentDate.setUTCHours(0, 0, 0, 0);
    const timeDifference = currentDate - givenDate
    const millisecondsInOneDay = 24 * 60 * 60 * 1000
    const dayDifference = Math.floor(timeDifference / millisecondsInOneDay)
    return dayDifference
  }
}
const today = moment()
const getWeeksForCurrentYear = () => {
  const weeks = []
  const currentWeek = today.isoWeek()

  for (let i = currentWeek; i >= 1; i--) {
    weeks.push({
      label: `week_no_${i}`,
      key: `week_no_${i}`,
    })
  }

  return weeks.reverse()
}

export const weeksForCurrentYear = getWeeksForCurrentYear()
