const isAllProjectTypes = 'ALL'
const isProjectTypeAll = (projectTypeId) => {
  return projectTypeId == isAllProjectTypes
}

const displaySegment = (segmentProjectType, selectedReviewProjectType) => {
  return (
    isProjectTypeAll(segmentProjectType) ||
    isSelectedProjectTypeMatches_In_SegmentProjectType(
      selectedReviewProjectType,
      segmentProjectType
    )
  )
}

function isSelectedProjectTypeMatches_In_SegmentProjectType(
  selctedprojectType,
  segmentProjectTypes
) {
  let allowedTypes = segmentProjectTypes.split(',')
  let matchType = allowedTypes.filter(
    (type) => type.trim() == selctedprojectType.trim()
  )
  return matchType && matchType.length > 0 ? true : false
}

function isModellingSpecific(segmentType) {
  let allowedType = 'Modeling Project - Checks for specific technique'
  return segmentType == allowedType
}

function isPerformanceMeasurement(segmentType) {
  let allowedType = 'Data Engineering - Performance Measurement'
  return segmentType == allowedType
}

const reviewerRole = 'Reviewer'
const adminRole = 'Admin'
const scheduled = 'SCHEDULED'
const underReview = 'UNDER REVIEW'

const URL = 'https://project-review.tredence.com/'

const subj = (project) => {
  return `Compliance Review Assigned - ${project}`
}

function getEmployeeID() {
  return localStorage.getItem('project_review_employee_id')
}

function getEmployeeRole() {
  return localStorage.getItem('project_review_employee_role')
}

function getReviewerIDContext(previous_review_id, status) {
  let context = 'review'
  if (localStorage.getItem('project_review_employee_role') === reviewerRole) {
    if (
      previous_review_id != null &&
      previous_review_id != 0 &&
      status == scheduled
    )
      context = 'reopen'
  }
  return context
}
function getCreatedAndUpdatedBy() {
  return {
    created_by: localStorage.getItem('project_review_employee_id'),
    updated_by: localStorage.getItem('project_review_employee_id'),
  }
}

const getDocumentFileName = (files, id) => {
  let findObject = files.filter((file) => file.document_id === id)
  return findObject ? findObject[0] : undefined
}

function processFileDownload(documents, files) {
  for (let x = 0; x < documents.length; x++) {
    let findFile = getDocumentFileName(files, documents[x].document_id)
    if (findFile) {
      documents[x].file = findFile.uploaded_file
      documents[x].review_id = findFile.review_id
    }
  }

  return documents
}

function getDefaultModelInputs(documents, files) {
  let data = {
    DATA_SET: [],
    CLIENT_NAME: [],
    PROJECT_NAME: [],
    PROJECT_TYPE: [],
    REVIEWERS_LIST: [],
    PROJECT_LEADS: [],
    EMP_LIST: [],
  }

  return data
}

function getClientIdByName(client_name, client_details) {
  let id = ''
  for (let x = 0; x < client_details.length; x++) {
    if (client_name == client_details[x].client_name) {
      id = client_details[x].client_id
      break
    }
  }
  return id
}

function getProjectLeadsByClient(clientId, employeeList) {
  let projectLeads = []
  for (let x = 0; x < employeeList.length; x++) {
    let clients = employeeList[x].client_ids
    let clientList = clients.split(',')
    // to remove the last empty String we should do pop
    clientList.pop()
    if (
      clientList.indexOf(clientId.toString()) >= 0 &&
      employeeList[x].role != adminRole &&
      employeeList[x].role != reviewerRole
    ) {
      projectLeads.push(employeeList[x])
    }
  }
  // sort by name
  projectLeads.sort(function (a, b) {
    if (a.employee_name < b.employee_name) {
      return -1
    }
    if (a.employee_name > b.employee_name) {
      return 1
    }

    // names must be equal
    return 0
  })

  return projectLeads
}

function creatFileObject(fileJson) {
  let formData = new FormData()
  const listitem = Object.keys(fileJson)
  console.log('::fileJson::', fileJson)
  for (let i = 0; i < listitem.length; i++) {
    if (fileJson[listitem[i]]) {
      console.log('::listitem::', fileJson[listitem[i]])
      formData.append('types', listitem[i])
      let newName = listitem[i] + '_' + fileJson[listitem[i]].name
      formData.append('filename', fileJson[listitem[i]], newName)
      console.log('fileName', formData)
    }
  }

  formData.append('updated_by', getEmployeeID())

  return formData
}

function isReviewer() {
  return localStorage.getItem('project_review_employee_role') === 'Reviewer'
}

function isAdmin() {
  return localStorage.getItem('project_review_employee_role') === 'Admin'
}

function isReviewee() {
  return localStorage.getItem('project_review_employee_role') === ''
}

const getDefaultFilesList = () => {
  return {
    user_persona_document: '',
    architectural_document: '',
    data_audit_document: '',
    qc_checklist_document: '',
    project_plan_document: '',
    code_review_checklist_document: '',
  }
}

const setSessionDetails = (response) => {
  localStorage.setItem('project_review_employee_login', true)
  localStorage.setItem('project_review_employee_id', response.employee_id)
  localStorage.setItem('project_review_employee_name', response.emp_name)
  localStorage.setItem('project_review_employee_email_id', response.email_id)
  localStorage.setItem(
    'project_review_employee_designation',
    response.designation
  )
  localStorage.setItem('project_review_employee_role', response.role)
  localStorage.setItem(
    'role_switch',
    response.role === 'Reviewer' || response.role === 'Reviewee'
  )
}

function getLeadArray(client_name, details, project_lead) {
  let clientDetails = details.clients
  let empDetails = details.employeeDetails
  let client_id = getClientIdByName(client_name, clientDetails)
  const projectLeads = getProjectLeadsByClient(client_id, empDetails)
  let leadArray = projectLeads.find(function (element) {
    return element.employee_id === project_lead
  })

  return leadArray
}

const emailbody = (review, role, client, project, url) => {
  return `<pre>You have been assigned as a ${review} ${role} for the below project: 

•Account: ${client}
•Project Name: ${project}
  
Please navigate to ${url} & complete the review.
   
Reach out to delivery-excellence@tredence.com for any queries/questions.
  
Regards,
Delivery Excellence Team

    </pre>`
}

const complianceemailbody = (client, project, url) => {
  return `<pre>
The delivery excellence(DX) team is starting project reviews to ensure PM and project-related best practices are followed for projects starting in 2023.

The “${client} - ${project}” project is among the ones we will track as part of this initiative.

Please ensure you have the right artefacts for your projects for each phase uploaded in KMS. You can find the required docs & templates listed under the checklist in the PR app.

Once you submit the relevant artefact, a sign-off email will be sent to your respective Account Head.

Please navigate to ${url} & complete the review.
   
Reach out to delivery-excellence@tredence.com for any queries/questions.

Regards,
Delivery Excellence Team
  
    </pre>`
}

const ccemaillist = ['delivery-excellence@tredence.com']

const getEmail = () => {
  return localStorage.getItem('project_review_employee_email_id')
}

const getProjectTypeById = (id) => {
  if (id == null || id === undefined || id === '') {
    return ''
  }

  let projectTypes = JSON.parse(localStorage.getItem('adminData')).data
    .projectTypes
  return projectTypes.find((p) => p.project_type_id === id)
}

module.exports = {
  adminRole: adminRole,
  reviewerRole: reviewerRole,
  completed: 'COMPLETED',
  pending: 'PENDING',
  expired: 'EXPIRED',
  scheduled: scheduled,
  underReview: underReview,
  cancelled: 'CANCELLED',
  url: URL,
  subj,
  ccemail: ccemaillist,
  isModellingSpecific,
  isPerformanceMeasurement,
  displaySegment,
  getEmployeeID,
  getEmployeeRole,
  getReviewerIDContext,
  getCreatedAndUpdatedBy,
  processFileDownload,
  creatFileObject,
  getDefaultModelInputs,
  isReviewer,
  isAdmin,
  getDefaultFilesList,
  getProjectLeadsByClient,
  setSessionDetails,
  getLeadArray,
  getEmail,
  isReviewee,
  getProjectTypeById,
  emailbody,
  complianceemailbody,
}
