import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getComplianceReviews,
  getComplianceReviewById,
  getComplianceChecklistsSaved,
  getPhaseDetails,
  getEltReviews,
} from '../../services/complianceService'

const initialState = {
  reviewStatuses: ['Scheduled', 'Expired'],
  upcomingComplianceReviews: [],
  isFetchUpcomingComplianceReviewsLoading: false,
  fetchUpcomingComplianceReviewsError: null,
  inProgressComplianceReviews: [],
  isFetchInProgressComplianceReviewsLoading: false,
  fetchInProgressComplianceReviewsError: null,
  completedComplianceReviews: [],
  isFetchCompletedComplianceReviewsLoading: false,
  fetchCompletedComplianceReviewsError: null,
  selectedComplianceReviewDetails: {},
  isFetchComplianceReviewByIdLoading: false,
  fetchComplianceReviewByIdError: null,
  checklists: [],
  complianceChecklistSaved: [],
  isFetchComplianceChecklistSavedLoading: false,
  fetchComplianceChecklistSavedError: null,
  PhaseDetails: [],
  PhaseDetailsLoading: false,
  fetchPhaseDetailsError: null,
  eltViewInprogressReviewsDetails: [],
  isFetchEltViewInprogressReviewsDetailsLoading: false,
  isFetchEltViewInprogressReviewsDetailsError: null,
  eltViewCompletedReviewsDetails: [],
  isFetchEltViewCompletedReviewsDetailsLoading: false,
  isFetchEltViewCompletedReviewsDetailsError: null,
  submitButtonhidden: true,
  submitButtonhiddenReviewid: -1,
  eltSubmitbuttonhide: true,
  eltSubmitbuttonhideReviewid: -1,
}

export const fetchUpcomingComplianceReviews = createAsyncThunk(
  'content/fetchUpcomingComplianceReviews',
  async (params) => {
    const response = await getComplianceReviews({
      status: JSON.stringify(['SCHEDULED', 'Expired']),
      ...params,
    })

    if (response.success) {
      return response.data
    }

    return []
  }
)

export const fetchPhaseDetails = createAsyncThunk(
  'content/fetchPhaseDetails',
  async () => {
    const response = await getPhaseDetails()

    if (response.success) {
      return response.data
    }

    return []
  }
)

export const fetchInProgressComplianceReviews = createAsyncThunk(
  'content/fetchInProgressComplianceReviews',
  async (params) => {
    const response = await getComplianceReviews({
      status: JSON.stringify(['In-Progress']),
      ...params,
    })

    if (response.success) {
      return response.data
    }

    return []
  }
)

export const fetchCompletedComplianceReviews = createAsyncThunk(
  'content/fetchCompletedComplianceReviews',
  async (params) => {
    const response = await getComplianceReviews({
      status: JSON.stringify(['Completed']),
      ...params,
    })

    if (response.success) {
      return response.data
    }

    return []
  }
)

export const fetchComplianceReviewById = createAsyncThunk(
  'content/fetchComplianceReviewById',
  async (data) => {
    const response = await getComplianceReviewById(data)

    if (response.success) {
      return response.data
    }

    return {}
  }
)
export const fetchComplianceChecklistSaved = createAsyncThunk(
  'content/fetchComplianceChecklistSaved',
  async (data) => {
    const response = await getComplianceChecklistsSaved(data)

    if (response.success) {
      return response.data
    } else {
      return {}
    }
  }
)

export const fetchEltViewInprogressReviewDetails = createAsyncThunk(
  'content/fetchEltViewInprogressReviewDetails',
  async (data) => {
    const response = await getEltReviews(data)

    if (response.success) {
      return response.data
    } else {
      return []
    }
  }
)

export const fetchEltViewCompletedReviewDetails = createAsyncThunk(
  'content/fetchEltViewCompletedReviewDetails',
  async (data) => {
    const response = await getEltReviews(data)

    if (response.success) {
      return response.data
    } else {
      return []
    }
  }
)

export const complianceSlice = createSlice({
  name: 'compliance',
  initialState,
  reducers: {
    resetSelectedComplianceReviewDetails: (state, action) => {
      state.selectedComplianceReviewDetails = {}
      state.isFetchComplianceReviewByIdLoading = false
      state.fetchComplianceReviewByIdError = null
    },
    setSubmitbuttonhide: (state, action) => {
      state.submitButtonhidden = action.payload
    },
    setsubmitButtonhiddenReviewid: (state, action) => {
      state.submitButtonhiddenReviewid = action.payload
    },
    setEltSubmitButtonHide: (state, action) => {
      state.eltSubmitbuttonhide = action.payload
    },
    seteltSubmitbuttonhideReviewid: (state, action) => {
      state.eltSubmitbuttonhideReviewid = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpcomingComplianceReviews.pending, (state) => {
      state.isFetchUpcomingComplianceReviewsLoading = true
    })
    builder.addCase(
      fetchUpcomingComplianceReviews.fulfilled,
      (state, action) => {
        state.isFetchUpcomingComplianceReviewsLoading = false
        state.upcomingComplianceReviews = action.payload
      }
    )
    builder.addCase(
      fetchUpcomingComplianceReviews.rejected,
      (state, action) => {
        state.isFetchUpcomingComplianceReviewsLoading = false
        state.fetchUpcomingComplianceReviewsError = action.error.message
      }
    )

    builder.addCase(fetchPhaseDetails.pending, (state) => {
      state.PhaseDetailsLoading = true
    })
    builder.addCase(fetchPhaseDetails.fulfilled, (state, action) => {
      state.PhaseDetailsLoading = false
      state.PhaseDetails = action.payload
    })
    builder.addCase(fetchPhaseDetails.rejected, (state, action) => {
      state.PhaseDetailsLoading = false
      state.fetchPhaseDetailsError = action.error.message
    })

    builder.addCase(fetchInProgressComplianceReviews.pending, (state) => {
      state.isFetchInProgressComplianceReviewsLoading = true
    })
    builder.addCase(
      fetchInProgressComplianceReviews.fulfilled,
      (state, action) => {
        state.isFetchInProgressComplianceReviewsLoading = false
        state.inProgressComplianceReviews = action.payload
      }
    )
    builder.addCase(
      fetchInProgressComplianceReviews.rejected,
      (state, action) => {
        state.isFetchInProgressComplianceReviewsLoading = false
        state.fetchInProgressComplianceReviewsError = action.error.message
      }
    )
    builder.addCase(fetchCompletedComplianceReviews.pending, (state) => {
      state.isFetchCompletedComplianceReviewsLoading = true
    })
    builder.addCase(
      fetchCompletedComplianceReviews.fulfilled,
      (state, action) => {
        state.isFetchCompletedComplianceReviewsLoading = false
        state.completedComplianceReviews = action.payload
      }
    )
    builder.addCase(
      fetchCompletedComplianceReviews.rejected,
      (state, action) => {
        state.isFetchCompletedComplianceReviewsLoading = false
        state.fetchCompletedComplianceReviewsError = action.error.message
      }
    )

    builder.addCase(fetchComplianceReviewById.pending, (state) => {
      state.isFetchComplianceReviewByIdLoading = true
    })
    builder.addCase(fetchComplianceReviewById.fulfilled, (state, action) => {
      state.isFetchComplianceReviewByIdLoading = false
      state.selectedComplianceReviewDetails = action.payload
    })
    builder.addCase(fetchComplianceReviewById.rejected, (state, action) => {
      state.isFetchComplianceReviewByIdLoading = false
      state.fetchComplianceReviewByIdError = action.error.message
    })
    builder.addCase(fetchComplianceChecklistSaved.pending, (state) => {
      state.isFetchComplianceChecklistSavedLoading = true
    })
    builder.addCase(
      fetchComplianceChecklistSaved.fulfilled,
      (state, action) => {
        state.isFetchComplianceChecklistSavedLoading = false
        state.complianceChecklistSaved = action.payload
      }
    )
    builder.addCase(fetchComplianceChecklistSaved.rejected, (state, action) => {
      state.isFetchComplianceChecklistSavedLoading = false
      state.fetchComplianceChecklistSavedError = action.error.message
    })
    builder.addCase(fetchEltViewInprogressReviewDetails.pending, (state) => {
      state.isFetchEltViewInprogressReviewsDetailsLoading = true
    })
    builder.addCase(
      fetchEltViewInprogressReviewDetails.fulfilled,
      (state, action) => {
        state.isFetchEltViewInprogressReviewsDetailsLoading = false
        state.eltViewInprogressReviewsDetails = action.payload
      }
    )
    builder.addCase(
      fetchEltViewInprogressReviewDetails.rejected,
      (state, action) => {
        state.isFetchEltViewInprogressReviewsDetailsLoading = false
        state.isFetchEltViewInprogressReviewsDetailsError = action.error.message
      }
    )
    builder.addCase(fetchEltViewCompletedReviewDetails.pending, (state) => {
      state.isFetchEltViewCompletedReviewsDetailsLoading = true
    })
    builder.addCase(
      fetchEltViewCompletedReviewDetails.fulfilled,
      (state, action) => {
        state.isFetchEltViewCompletedReviewsDetailsLoading = false
        state.eltViewCompletedReviewsDetails = action.payload
      }
    )
    builder.addCase(
      fetchEltViewCompletedReviewDetails.rejected,
      (state, action) => {
        state.isFetchEltViewCompletedReviewsDetailsLoading = false
        state.isFetchEltViewCompletedReviewsDetailsError = action.error.message
      }
    )
  },
})

export const {
  resetSelectedComplianceReviewDetails,
  setSubmitbuttonhide,
  setsubmitButtonhiddenReviewid,
  setEltSubmitButtonHide,
  seteltSubmitbuttonhideReviewid,
} = complianceSlice.actions
export default complianceSlice.reducer
