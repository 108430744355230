import React, { useEffect, useState } from 'react'
import CompletedReview from '../Elt/CompletedReviews/index'
import InProgressReviews from './InProgressReviews'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Container from '@mui/material/Container'
import { TabPanel, a11yProps } from '../../Common/TabPanel'
import { useNavigate, useParams, Outlet } from 'react-router-dom'
import Artifacts from '../ComplianceReview/Artifacts'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  marqueeContainer: {
    position: 'relative',
    top: '20px',
    overflow: 'hidden',
    backgroundColor: '#f0f0f0', // Background color for the ribbon
    paddingBottom: '5px', // Adjust the padding to control the space between the text and the ribbon
    width: '100%', // Ensure the container spans the full width
  },
  marquee: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '20px', // Adjust the font size as needed
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    width: '100%', // Ensure the marquee spans the full width
    animation: '$marquee 20s linear infinite',
    '&:hover': {
      animationPlayState: 'paused',
    }, // Increased the duration for a slower speed
  },
  '@keyframes marquee': {
    '0%': {
      transform: 'translateX(100%)',
    },
    '100%': {
      transform: 'translateX(-100%)',
    },
  },
}))
export default function EltView() {
  const classelt = useStyles()
  const navigate = useNavigate()
  const { page } = useParams()

  const [value, setValue] = useState(0)
  useEffect(() => {
    switch (page) {
      // case 'inprogress-reviews':
      //   setValue(0)
      //   break
      // case 'completed-reviews':
      //   setValue(1)
      //   break
      case 'upload-artifacts':
        setValue(0)
        break
      default:
        setValue(0)
        break
    }
  }, [page])

  const handleClick = () => {
    window.open('https://trek.theatom.ai/', '_blank')
  }

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue)
    switch (newValue) {
      // case 0:
      //   navigate(`/elt/inprogress-reviews`)
      //   break
      // case 1:
      //   navigate(`/elt/completed-reviews`)
      //   break
      case 0:
        navigate(`/elt/upload-artifacts`)
        break
      // default:
      //   navigate(`/elt/inprogress-reviews`)
      //   break
      default:
        navigate(`/elt/upload-artifacts`)
        break
    }
  }

  return (
    <>
      <div className={classelt.marqueeContainer}>
        <div className={classelt.marquee}>
          If you are looking to upload case studies please visit{' '}
          <span
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            TreK.
          </span>
        </div>
      </div>
      <Container maxWidth="xxl">
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
          >
            {/* <Tab label="IN-PROGRESS REVIEWS" {...a11yProps(0)} />
            <Tab label="COMPLETED REVIEWS" {...a11yProps(1)} /> */}
            <Tab label="UPLOAD ARTIFACTS" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={value}>
          {/* {page === 'inprogress-reviews' && <InProgressReviews />}
          {page === 'completed-reviews' && <CompletedReview />} */}
          {value === 0 && <Artifacts />}
        </TabPanel>
        <Outlet />
      </Container>
    </>
  )
}
