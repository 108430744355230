import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { CSVLink } from 'react-csv'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import API from './../../Common/api'
import CloseIcon from '@mui/icons-material/Close'
import Loading from './../../Common/Loading'
import { getAllMetaData } from '../../services/csatService'
import { useSelector } from 'react-redux'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'

const downloadButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '0.5rem',
  padding: '1rem',
  border: '1px solid #E7E7E7',
  fontSize: '0.7rem',
  width: '17rem',
  fontWeight: 600,
  color: '#000000',
}

const ReportsListModel = (props) => {
  const [prHealthData, setPrHealthData] = useState([])
  const [prHealthPreviousYearData, setprHealthPreviousYearData] = useState([])
  const [uploadTemplateData, setUploadTemplateData] = useState([])
  const [loading, setLoading] = useState(false)
  const today = moment()
  const currentYear = today.year()
  const { userDetails } = useSelector((state) => state.user)

  const iconStyle = {
    fontSize: 15,
    cursor: 'pointer',
    marginLeft: 'auto',
    fontWeight: 'bold',
  }

  const titleStyle = {
    fontSize: '15px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  }

  const getWeeksForPreviousYear = () => {
    const weeks = []
    const startDate = moment([currentYear, 0, 1])

    for (let i = 1; i < 53; i++) {
      const weekStartDate = startDate.clone().add(i, 'weeks')
      const weekEndDate = weekStartDate.clone().endOf('week')

      weeks.push({
        label: `week_no_${i}`,
        key: `week_no_${i}`,
      })
    }

    return weeks
  }

  //For current year
  const getWeeksForCurrentYear = () => {
    const weeks = []
    const currentWeek = today.isoWeek()

    for (let i = currentWeek; i >= 1; i--) {
      weeks.push({
        label: `week_no_${i}`,
        key: `week_no_${i}`,
      })
    }

    return weeks.reverse()
  }

  const weeksForCurrentYear = getWeeksForCurrentYear()
  const weeksForPreviousYear = getWeeksForPreviousYear()


  const getAllWeeksProjectHealthData = async () => {
    setLoading(true)
    const verticalData = userDetails?.vertical_ids?.verticalInfo
    const verticalNames = verticalData?.length
      ? verticalData.map((vertical) => vertical.verticalName)
      : []
    const data = {
      year: currentYear,
      email_id: userDetails?.email_id,
      role: userDetails?.role,
      verticalNames: verticalNames,
    }
    try {
      const response = await API.post('project-health/getAllWeeksProjectHealthData', data)
      const weekKeys = weeksForCurrentYear.map((week) => week.key)
      const processedData = response.data.map((row) => {
        const newRow = { ...row }
        weekKeys.forEach((weekKey) => {
          if (!(weekKey in newRow)) {
            newRow[weekKey] = 'No data'
          }
        })
        return newRow
      })
      setPrHealthData(processedData)
    } catch (error) {
      console.error('Error fetching current year project health data:', error)
    } finally {
      setLoading(false)
    }
  }

  const getPreviousYearAllWeeksProjectHealthData = async () => {
    setLoading(true)
    const verticalData = userDetails?.vertical_ids?.verticalInfo
    const verticalNames = verticalData?.length
      ? verticalData.map((vertical) => vertical.verticalName)
      : []
    const data = {
      year: currentYear - 1,
      email_id: userDetails?.email_id,
      role: userDetails?.role,
      verticalNames: verticalNames,
    }
    try {
      const response = await API.post('project-health/getAllWeeksProjectHealthData', data)
      const weekKeys = weeksForPreviousYear.map((week) => week.key)
      const processedData = response.data.map((row) => {
        const newRow = { ...row }
        weekKeys.forEach((weekKey) => {
          if (!(weekKey in newRow)) {
            newRow[weekKey] = 'No data'
          }
        })
        return newRow
      })
      setprHealthPreviousYearData(processedData)
    } catch (error) {
      console.error('Error fetching previous year project health data:', error)
    } finally {
      setLoading(false)
    }
  }

  const getMetaData = async () => {
    const data = { role: userDetails.role, emp_id: userDetails.employee_id }
    if (userDetails.role === 'Reviewee') {
      const plemail = userDetails.email_id
      data = {
        plemail,
        role: userDetails.role,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Reviewer') {
      const adhemail = userDetails.email_id
      const verticalData = userDetails?.vertical_ids?.verticalInfo
      const verticalNames = verticalData?.length
        ? verticalData.map((vertical) => vertical.verticalName)
        : []
      data = {
        adhemail,
        role: userDetails.role,
        vertical: verticalNames,
        emp_id: userDetails.employee_id,
      }
    }
    try {
      const res = await getAllMetaData(data)
      if (res.success) {
        const dataWithIds = res.data.result.map((item, index) => ({
          rowId: index + 1,
          ...item,
        }))
        setUploadTemplateData(dataWithIds)
      }
    } catch (error) {
      console.error('Error fetching metadata:', error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        await Promise.all([
          getAllWeeksProjectHealthData(),
          getPreviousYearAllWeeksProjectHealthData(),
          userDetails?.role === 'Admin' && getMetaData(),
        ])
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const commonColumns = [
    {
      label: 'Vertical',
      key: 'vertical',
    },
    {
      label: 'Account',
      key: 'account',
    },
    {
      label: 'Project',
      key: 'project',
    },
  ]

  const metadataColumns = [
    { label: 'Row ID', key: 'rowId' },
    { label: 'Salesforce ID', key: 'salesforce_opportunity_id' },
    ...commonColumns,
    { label: 'Workstream', key: 'workstream' },
    { label: 'VDH', key: 'vertical_head_emailId' },
    { label: 'ADH', key: 'adh_emailId' },
    { label: 'Project Lead', key: 'projectLead_emailId' },
    { label: 'CS', key: 'cs_emailId' },
    { label: 'Project type', key: 'project_type' },
    { label: 'Project Sub-type', key: 'project_sub_type' },
    { label: 'Contract type', key: 'contract_type' },
  ]

  const prHealthAllWeeksData = [
    ...commonColumns,
    {
      label: 'Work Stream',
      key: 'workstream',
    },
    ...weeksForCurrentYear,
    {
      label: 'Weeks In Red',
      key: 'redStatusCount',
    },
    {
      label: 'Weeks In Amber',
      key: 'amberStatusCount',
    },
    {
      label: 'Weeks In Green',
      key: 'greenStatusCount',
    },
    {
      label: 'Weeks Reported',
      key: 'totalStatusCount',
    },
    {
      label: '% Red YTD',
      key: 'redStatusPercentage',
    },
  ]

  const prHealthPreviousYearColumns = [
    ...commonColumns,
    {
      label: 'Work Stream',
      key: 'workstream',
    },
    ...weeksForPreviousYear,
    {
      label: 'Weeks In Red',
      key: 'redStatusCount',
    },
    {
      label: 'Weeks In Amber',
      key: 'amberStatusCount',
    },
    {
      label: 'Weeks In Green',
      key: 'greenStatusCount',
    },
    {
      label: 'Weeks Reported',
      key: 'totalStatusCount',
    },
    {
      label: '% Red YTD',
      key: 'redStatusPercentage',
    },
  ]

  const reports = [
    {
      label: 'Project metadata',
      filename: 'Project metadata',
      data: uploadTemplateData,
      head: metadataColumns,
      icon: <InsertDriveFileOutlinedIcon style={{ color: '#4DB4FF' }} />,
    },
    {
      label: `YTD Project Health Report - ${currentYear}`,
      filename: `YTD Project Health Report - ${currentYear}`,
      data: prHealthData,
      head: prHealthAllWeeksData,
      icon: <AddBoxOutlinedIcon style={{ color: '#FF604A' }} />,
    },
    {
      label: `Project Health Report - ${currentYear - 1}`,
      filename: `Project Health Report - ${currentYear - 1}`,
      data: prHealthPreviousYearData,
      head: prHealthPreviousYearColumns,
      icon: <CalendarTodayOutlinedIcon style={{ color: '#4CBE57' }} />,
    },
  ]

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle style={titleStyle} id="alert-dialog-title">
        <span style={{ marginRight: 'auto' }}>List Of Reports</span>
        <CloseIcon onClick={props.handleClose} style={iconStyle} />
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid>
            <Loading open={loading} />
          </Grid>
        ) : (
          <Grid>
            <ul>
              {reports.map((report, index) => {
                if (
                  report?.label === 'Project metadata' &&
                  userDetails?.role === 'Admin'
                ) {
                  return (
                    <CSVLink
                      key={index}
                      data={report.data}
                      filename={`${report.filename}.csv`}
                      headers={report.head}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={report.icon}
                        style={downloadButtonStyle}
                      >
                        <span>{report.label}</span>
                      </Button>
                      <br />
                    </CSVLink>
                  )
                } else if (report?.label !== 'Project metadata') {
                  return (
                    <CSVLink
                      key={index}
                      data={report.data}
                      filename={`${report.filename}.csv`}
                      headers={report.head}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={report.icon}
                        style={downloadButtonStyle}
                      >
                        <span>{report.label}</span>
                      </Button>
                      <br />
                    </CSVLink>
                  )
                }
                return null;
              })}
            </ul>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ReportsListModel
