import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getVerticalDetails,
  getSubverticalDetails,
  getAlldetailsbasedVerticalsubvertical,
  saveAllverticalDetails,
} from '../../services/AddCompilanceservice'
const initialState = {
  // addCompilancemodalenable: false,
  verticalIsloading: false,
  allverticalData: [],
  verticalLoaderror: null,
  subVerticalIsloading: false,
  allSubverticalData: [],
  allSubverticalerror: null,
  allInputdata: {},
  otherData: [],
  otherDataLoading: false,
  otherDataLoaderror: null,
  accountDropdownoption: [],
  projectDropdownoption: [],
  verticalHeadsdropdownoption: [],
  clientHeadsdropdownoption: [],
  projectLeadsdropdownoption: [],
  addCompliancebutton: true,
  saveCompilanceapiloader: 0,
  saveComilanceAPIdata: '',
  // alertMessages: '',
  // alertMessagesSeverity: '',
  // snackbarOpen: false,
  allInputvalidationObject: {},
}
export const fetchVerticalDetails = createAsyncThunk(
  'content/fetchVerticalDetails',
  async () => {
    const response = await getVerticalDetails()
    if (response.status === 200) {
      return response.data
    }
    return []
  }
)
export const fetchSubverticalDetails = createAsyncThunk(
  'content/fetchSubverticalDetails',
  async (req) => {
    const response = await getSubverticalDetails(req)
    if (response.status === 200) {
      return response.data
    }
    return []
  }
)
export const fetchotherDetailsbasedonVerticalandSubvertical = createAsyncThunk(
  'content/fetchOtherdetails',
  async (req) => {
    const response = await getAlldetailsbasedVerticalsubvertical(req)
    if (response.status === 200) {
      return response.data
    }
    return []
  }
)
export const saveAlltheverticalDetails = createAsyncThunk(
  'content/saveDetails',
  async (req) => {
    const response = await saveAllverticalDetails(req)

    if (response.status === 200) {
      return response.data
    }
    return {}
  }
)
export const AddcompilanceSlice = createSlice({
  name: 'AddcompilanceSlice',
  initialState,
  reducers: {
    // addCompilancemodalenable: (state, action) => {
    //   state.addCompilancemodalenable = action.payload
    // },
    allInputboxonchange: (state, action) => {
      state.allInputdata[action.payload.name] = action.payload.value
    },
    allInputboxValidation: (state, action) => {
      state.allInputvalidationObject[action.payload.name] = action.payload.value
    },
    setAccountsdropdownaction: (state, action) => {
      state.accountDropdownoption = action.payload
    },
    setProjectdropdownaction: (state, action) => {
      state.projectDropdownoption = action.payload
    },
    setverticalHeadsdropdownoption: (state, action) => {
      state.verticalHeadsdropdownoption = action.payload
    },
    setclientHeadsdropdownoption: (state, action) => {
      state.clientHeadsdropdownoption = action.payload
    },
    setprojectLeadsdropdownoption: (state, action) => {
      state.projectLeadsdropdownoption = action.payload
    },
    resetAllinputs: (state, action) => {
      state.allInputdata = {}
    },
    setaddCompliancebutton: (state, action) => {
      state.addCompliancebutton = action.payload
    },
    resetAlldropdownOptions: (state, action) => {
      state.allverticalData = []
      state.allSubverticalData = []
      state.otherData = []
      state.accountDropdownoption = []
      state.projectDropdownoption = []
      state.verticalHeadsdropdownoption = []
      state.clientHeadsdropdownoption = []
      state.projectLeadsdropdownoption = []
    },
    // setalertMessages: (state, action) => {
    //   state.alertMessages = action.payload
    // },
    // setalertMessagesSeverity: (state, action) => {
    //   state.alertMessagesSeverity = action.payload
    // },
    // setsnackbarOpen: (state, action) => {
    //   state.snackbarOpen = action.payload
    // },
    resetAllvalidation: (state, action) => {
      state.allInputvalidationObject = {}
    },
    resetsaveCompilanceapiloader: (state, action) => {
      state.saveCompilanceapiloader = 0
      state.saveComilanceAPIdata = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVerticalDetails.pending, (state) => {
      state.verticalIsloading = true
    })
    builder.addCase(fetchVerticalDetails.fulfilled, (state, action) => {
      state.verticalIsloading = false
      state.allverticalData = action.payload
    })
    builder.addCase(fetchVerticalDetails.rejected, (state, action) => {
      state.verticalIsloading = false
      state.verticalLoaderror = action.error.message
    })
    builder.addCase(fetchSubverticalDetails.pending, (state) => {
      state.subVerticalIsloading = true
    })
    builder.addCase(fetchSubverticalDetails.fulfilled, (state, action) => {
      state.subVerticalIsloading = false
      state.allSubverticalData = action.payload
    })
    builder.addCase(fetchSubverticalDetails.rejected, (state, action) => {
      state.subVerticalIsloading = false
      state.allSubverticalerror = action.payload
    })
    builder.addCase(
      fetchotherDetailsbasedonVerticalandSubvertical.pending,
      (state) => {
        state.otherDataLoading = true
      }
    )
    builder.addCase(
      fetchotherDetailsbasedonVerticalandSubvertical.fulfilled,
      (state, action) => {
        state.otherDataLoading = false
        state.otherData = action.payload
      }
    )
    builder.addCase(
      fetchotherDetailsbasedonVerticalandSubvertical.rejected,
      (state, action) => {
        state.otherDataLoading = false
        state.otherDataLoaderror = action.payload
      }
    )
    builder.addCase(saveAlltheverticalDetails.pending, (state, action) => {
      state.saveCompilanceapiloader = 0
    })
    builder.addCase(saveAlltheverticalDetails.fulfilled, (state, action) => {
      state.saveCompilanceapiloader = 1
      state.saveComilanceAPIdata = action.payload
    })
    builder.addCase(saveAlltheverticalDetails.rejected, (state, action) => {
      state.saveCompilanceapiloader = 2
      state.saveComilanceAPIdata = action.payload
    })
  },
})
export const {
  // addCompilancemodalenable,
  allInputboxonchange,
  setAccountsdropdownaction,
  setProjectdropdownaction,
  setverticalHeadsdropdownoption,
  setclientHeadsdropdownoption,
  setprojectLeadsdropdownoption,
  resetAllinputs,
  setaddCompliancebutton,
  resetAlldropdownOptions,
  // setalertMessages,
  // setalertMessagesSeverity,
  // setsnackbarOpen,
  allInputboxValidation,
  resetAllvalidation,
  resetsaveCompilanceapiloader,
} = AddcompilanceSlice.actions
export default AddcompilanceSlice.reducer
