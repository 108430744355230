import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getprHealthRecords, getFilterdata, getByConditions, saveDataFinal, putMoMData, getMOMdata, getCurrentWeekMetaData, getProjectListData, getAllWeeksProjectHealth } from '../../services/prHealthServices'
import {weeksForCurrentYear} from '../../Component/View/ProjectHealth/Common/utils/commonFunc'
const initialState = {
    healthData: {},
    filterData: {},
    isLoadingRecords: false,
    errorRecords: null,
    isLoadingFilter: false,
    errorFilter: null,
    filteredData: [],
    isLoadingFiltering: false,
    errorFiltering: null,
    isLoadingProjects: false,
    errorGettingProjects: null,
    momData: [],
    isLoadingMoM: false,
    errorFetchMoM: null,
    projectsList: [],
    allWeeksProjectHealthDta:[],
    isLoading:false
    
}

// fetch all records in pr health table
export const fetchHealthRecords = createAsyncThunk(
    'content/fetchHealthRecords',
    async () => {
        const response = await getprHealthRecords()
        if (response.success) {
            return response.data
        }
        return []
    }
)
export const getAllWeeksProjectHealthData = createAsyncThunk(
    'content/getAllWeeksProjectHealthData',
    async (data) => {
        const response = await getAllWeeksProjectHealth(data)
        if (response.success) {
            const weekKeys = weeksForCurrentYear.map((week) => week.key)
            const processedData = response.data.map((row) => {
              const newRow = { ...row }
              weekKeys.forEach((weekKey) => {
                if (!(weekKey in newRow)) {
                  newRow[weekKey] = 'No data'
                }
              })
              return newRow
            })
            return processedData
        }
        return []
    }
)
// to fetch all ditinct values for each column in pr health table
export const fetchFilterData = createAsyncThunk(
    'content/fetchFilterData',
    async () => {
        const response = await getFilterdata()
        if (response.success) {
            return response.data
        }
        return []
    }
)

export const fetchFilteredData = createAsyncThunk(
    'content/fetchFilteredData',
    async (filterData) => {
        const response = await getByConditions(filterData)
        if (response.success) {
            const modifiedResponse = response.data.map(data => {
                if (!data?.exclude) {
                return ({ ...data, exclude: 'No', health_status:!data.health_status?'Provide Inputs':data.health_status })
                } else if(data?.exclude == 'No' && !data?.health_status){
                    return ({ ...data, health_status: 'Provide Inputs' })
                }  else if (data.exclude === 'Yes' && !data.health_status) {
                    return ({ ...data, health_status: 'Excluded from tracking' })
                } else {
                    return data
                }
            })
            return modifiedResponse
        }
        return []
    }
)

// fetch data for current week for reviewee view
export const fetchCurrentWeekMetaData = createAsyncThunk(
    'content/fetchCurrentWeekMetaData',
    async (filterData) => {
        const response = await getCurrentWeekMetaData(filterData)
        if (response.success) {
            const modifiedResponse = response.data.map(data => {
                if (!data?.exclude) {
                    return ({ ...data, exclude: 'No', health_status:!data.health_status?'Provide Inputs':data.health_status })
                    } else if(data?.exclude == 'No' && !data?.health_status){
                        return ({ ...data, health_status: 'Provide Inputs' })
                    }  else if (data.exclude === 'Yes' && !data.health_status) {
                        return ({ ...data, health_status: 'Excluded from tracking' })
                    } else {
                        return data
                    }
            })
            return modifiedResponse
        }
        return []
    }
)

// fetch data for current week for reviewee view
export const saveData = createAsyncThunk(
    'content/saveData',
    async (params) => {
        const response = await saveDataFinal(params)
        if (response.success) {
            return response
        }
        return []
    }
)

export const fetchMoMData = createAsyncThunk(
    'content/fetchMoMData',
    async (data) => {
        const response = await getMOMdata(data)
        if (response.success) {
            return response.data
        }
        return []
    }
)

export const saveMoMData = createAsyncThunk(
    'content/saveMoMData',
    async (data) => {
        const response = await putMoMData(data)
        if (response.success) {
            return response.data
        }
        return []
    }
)

// fetch all list of projects for an account in weekly view exclude section 
export const getProjectList = createAsyncThunk(
    'content/getProjectList',
    async (data) => {
        const response = await getProjectListData(data)
        if (response.success) {
            return response.data
        }
        return []
    }
)

export const prHealthSlice = createSlice({
    name: 'prHealth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // to fall records in pr health table (not being used in weekly health)
        builder.addCase(fetchHealthRecords.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchHealthRecords.fulfilled, (state, action) => {
            state.isLoading = false
            state.healthData = action.payload
        })
        builder.addCase(fetchHealthRecords.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
        // to fetch data for filters (not being used in weekly health)
        builder.addCase(fetchFilterData.pending, (state) => {
            state.isLoadingFilter = true
        })
        builder.addCase(fetchFilterData.fulfilled, (state, action) => {
            state.isLoadingFilter = false
            state.filterData = action.payload
        })
        builder.addCase(fetchFilterData.rejected, (state, action) => {
            state.isLoadingFilter = false
            state.errorFilter = action.error.message
        })
        // filtered Data operations (used when week number changes)
        builder.addCase(fetchFilteredData.pending, (state) => {
            state.isLoadingFiltering = true
        })
        builder.addCase(fetchFilteredData.fulfilled, (state, action) => {
            state.isLoadingFiltering = false
            state.filteredData = action.payload
        })
        builder.addCase(fetchFilteredData.rejected, (state, action) => {
            state.isLoadingFiltering = false
            state.errorFiltering = action.error.message
        })
        // MoM operations
        builder.addCase(fetchMoMData.pending, (state) => {
            state.isLoadingMoM = true
        })
        builder.addCase(fetchMoMData.fulfilled, (state, action) => {
            state.isLoadingMoM = false
            state.momData = action.payload
        })
        builder.addCase(fetchMoMData.rejected, (state, action) => {
            state.isLoadingMoM = false
            state.errorFetchMoM = action.error.message
        })
        builder.addCase(saveMoMData.pending, (state) => {
            state.isLoadingMoM = true
        })
        builder.addCase(saveMoMData.fulfilled, (state, action) => {
            state.isLoadingMoM = false
            state.momData = action.payload
        })
        builder.addCase(saveMoMData.rejected, (state, action) => {
            state.isLoadingMoM = false
            state.errorFetchMoM = action.error.message
        })
        // current week meta data for reviewee Data operations
        builder.addCase(fetchCurrentWeekMetaData.pending, (state) => {
            state.isLoadingFiltering = true
        })
        builder.addCase(fetchCurrentWeekMetaData.fulfilled, (state, action) => {
            state.isLoadingFiltering = false
            state.filteredData = action.payload
        })
        builder.addCase(fetchCurrentWeekMetaData.rejected, (state, action) => {
            state.isLoadingFiltering = false
            state.errorFiltering = action.error.message
        })
        // current week meta data for reviewee Data operations
        builder.addCase(saveData.pending, (state) => {
            state.isLoadingFiltering = true
        })
        builder.addCase(saveData.fulfilled, (state, action) => {
            state.isLoadingFiltering = false
        })
        builder.addCase(saveData.rejected, (state, action) => {
            state.isLoadingFiltering = false
            state.errorFiltering = action.error.message
        })
        // get project list to show in exclude existing projects
        builder.addCase(getProjectList.pending, (state) => {
            state.isLoadingProjects = true
            state.projectsList = []
        })
        builder.addCase(getProjectList.fulfilled, (state, action) => {
            state.isLoadingProjects = false
            state.projectsList = action.payload
        })
        builder.addCase(getProjectList.rejected, (state, action) => {
            state.isLoadingProjects = false
            state.projectsList = []
            state.errorFiltering = action.error.message
        })
        builder.addCase(getAllWeeksProjectHealthData.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(getAllWeeksProjectHealthData.fulfilled, (state, action) => {
            state.isLoading = false
            state.allWeeksProjectHealthDta = action.payload
        })
        builder.addCase(getAllWeeksProjectHealthData.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
    },
})

export default prHealthSlice.reducer
