import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Loading from '../../../../Common/Loading'

import { Folder } from '@mui/icons-material'
import {
  FileInMantleSharePoint,
  deleteFilesFromChecklistFolder,
  linksToArtifactsInSharePoint,
  uploadFileToFinalSharePoint,
} from '../../../../GraphService'
import { updateComplianceArtifacts } from '../../../../services/complianceService'

const ArtifactDetailForm = (props) => {
  const { enqueueSnackbar: notify } = useSnackbar()
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [account, setAccount] = useState(props?.editRow?.account)
  const [project, setProject] = useState(props?.editRow?.project)
  const [uploader, setUploader] = useState(props?.editRow?.updated_by)
  const [docName, setDocName] = useState(props?.editRow?.Doc_name)
  const [docDescription, setDocDescription] = useState(
    props?.editRow?.Doc_description
  )
  const [selectedFile, setSelectedFile] = React.useState(
    props?.editRow?.file_name
  )
  const [uploadedFile, setUploadedFile] = React.useState([])
  const [accountGeneric, setAccountGeneric] = React.useState(
    props?.editRow?.account_generic == 'Yes' ? true : false
  )

  const [status, setStatus] = useState(props?.editRow?.status)
  const [linktodocs, setLinkToDocs] = useState()
  const accessToken = useSelector((state) => state.user.accessToken)
  const { userDetails } = useSelector((state) => state.user)

  const [workstream, setWorkStream] = useState(props?.editRow?.workstream)

  const [uniqueId, setUniqueId] = useState(props?.editRow?.unique_id)
  const [workstreamId, setWorkStreamId] = useState(
    props?.editRow?.workstream_id
  )
  const [commnets, setComments] = useState()
  const [approvalDate, setApprovalDate] = useState(
    Date(props?.editRow?.approved_on)
  )
  const dummyaccessToken = useSelector((state) => state.user.dummyaccessToken)

  useEffect(() => {
    setAccount(props?.editRow?.account)
    setProject(props?.editRow?.project)
    setUploader(props?.editRow?.updated_by)
    setDocName(props?.editRow?.Doc_name)
    setDocDescription(props?.editRow?.Doc_description)
    setSelectedFile(props?.editRow?.file_name)
    setStatus(props?.editRow?.status)
    setComments(props?.editRow?.approver_comments)
    setAccountGeneric(props?.editRow?.account_generic == 'Yes' ? true : false)
    formatDate(props?.editRow?.approved_on)
    if (props?.editRow?.project == 'Generic') {
      fetchAllFiles(
        props?.editRow?.vertical,
        props?.editRow?.account,
        'Common Artifacts',
        props?.editRow?.Doc_name
      )
    } else {
      fetchAllFiles(
        props?.editRow?.vertical,
        props?.editRow?.account,
        props?.editRow?.project,
        props?.editRow?.Doc_name
      )
    }
  }, [props?.editRow])

  function formatDate(dateString) {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    setApprovalDate(`${day}-${month}-${year}`)
  }

  const fetchAllFiles = async (vertical, account, project, checklist) => {
    let extension
    if (props?.editRow?.account_generic) {
      extension = checklist
    } else {
      if (props?.editRow?.workstream_id) {
        extension = checklist + '_' + props?.editRow?.workstream_id
      } else {
        extension = checklist
      }
    }
    setOpenBackdrop(true)
    try {
      const files = await FileInMantleSharePoint(
        vertical,
        account,
        project,
        extension,
        accessToken
      )
      setUploadedFile(files)
      setOpenBackdrop(false)
    } catch (error) {
      setOpenBackdrop(false)
      console.error(error)
    }
  }

  // Approve or Reject  API----------------->
  const ApproveorRejectArtifacts = (filledData) => {
    setOpenBackdrop(true)
    let filetodelete = [uploadedFile[0]?.name]
    let projectname
    updateComplianceArtifacts(filledData)
      .then((res) => {
        if (props?.editRow?.project == 'Generic') {
          projectname = 'Common Artifacts'
        } else {
          projectname = props?.editRow?.project
        }
        deleteFilesFromChecklistFolder(
          props?.editRow?.vertical,
          props?.editRow?.account,
          projectname,
          filetodelete,
          accessToken
        )
          .then((response) => {
            console.log('Files deleted successfully')
          })
          .catch((error) => {
            console.error('Error deleting files:', error)
          })
        if (res.success) {
          notify(`Data Updated Successfully !`, {
            variant: 'success',
          })
          setOpenBackdrop(true)
          Promise.all([props?.getArtifacts()])
            .then(() => {
              setOpenBackdrop(false)
              handleClose()
            })
            .catch((error) => {
              console.error('Error fetching data:', error)
              handleClose()
              setOpenBackdrop(false)
            })
        }
      })
      .catch((error) => {
        notify(
          `Error while updating the artifacts, reach out to the DX team.`,
          {
            variant: 'error',
          }
        )
        console.error('Error while updating Artifacts:', error)
        setOpenBackdrop(false)
      })
  }

  const handleApprove = (event) => {
    event.preventDefault()
    setOpenBackdrop(true)
    const filledData = {
      rowId: props?.editRow?.Doc_Id,
      data: {
        status: 'Approved',
        approver_comments: commnets,
        approved_by: userDetails?.email_id,
        approved_on: new Date(),
      },
    }
    if (uploadedFile.length > 0) {
      let uploadPromise
      if (props?.editRow?.project === 'Generic') {
        uploadPromise = uploadFileToFinalSharePoint(
          uploadedFile,
          props?.editRow?.vertical,
          props?.editRow?.account,
          'Common Artifacts',
          dummyaccessToken
        )
      } else {
        uploadPromise = uploadFileToFinalSharePoint(
          uploadedFile,
          props?.editRow?.vertical,
          props?.editRow?.account,
          props?.editRow?.project,
          dummyaccessToken
        )
      }
      // Check if the upload was successful or partially failed
      uploadPromise
        .then((result) => {
          ApproveorRejectArtifacts(filledData)
          if (result) {
            console.log(`Upload ${selectedFile} succeeded:`, result)
            notify(`${selectedFile} uploaded successfully!`, {
              variant: 'success',
            })
          } else {
            // Handle failed uploads
            console.error(`Upload ${selectedFile} failed`)
            notify(`Error in Approving ${selectedFile}, please try again`, {
              variant: 'error',
            })
          }
          setOpenBackdrop(false)
        })
        .catch((error) => {
          // Handle any errors that occurred during uploads
          console.error('Error during file uploads:', error)
          if (error == 'Access denied') {
            notify(
              `You don't have access to update the docs. Please reach out to DX team.`,
              {
                variant: 'error',
              }
            )
          } else {
            notify('Error during file approval,reach out to the DX team.', {
              variant: 'error',
            })
          }
          setOpenBackdrop(false)
        })
    }
  }
  const handleReject = (event) => {
    event.preventDefault()
    if (!commnets) {
      notify(`Please Add comments!`, {
        variant: 'info',
      })
    } else {
      const filledData = {
        rowId: props?.editRow?.Doc_Id,
        data: {
          status: 'Rejected',
          approver_comments: commnets,
          approved_by: userDetails?.email_id,
          approved_on: new Date(),
        },
      }
      ApproveorRejectArtifacts(filledData)
    }
  }

  const fetchlinkstosharepoint = () => {
    setOpenBackdrop(true)

    let uploadPromise
    if (accountGeneric) {
      uploadPromise = linksToArtifactsInSharePoint(
        userDetails.role,
        props?.editRow?.status,
        selectedFile,
        props?.editRow?.vertical,
        props?.editRow?.account,
        'Common Artifacts',
        props?.editRow?.Doc_name,
        accessToken,
        dummyaccessToken
      )
    } else {
      let extension
      if (props?.editRow?.workstream_id) {
        extension =
          props?.editRow?.Doc_name + '_' + props?.editRow?.workstream_id
      } else {
        extension = props?.editRow?.Doc_name
      }
      uploadPromise = linksToArtifactsInSharePoint(
        userDetails.role,
        props?.editRow?.status,
        selectedFile,
        props?.editRow?.vertical,
        props?.editRow?.account,
        props?.editRow?.project,
        extension,
        accessToken,
        dummyaccessToken
      )
    }

    uploadPromise
      .then((result) => {
        setLinkToDocs(result)
        const features =
          'width=600,height=400,resizable=yes,scrollbars=yes,status=yes'
        window.open(result[0]?.link, '_blank', features)
        setOpenBackdrop(false)
      })
      .catch((error) => {
        setOpenBackdrop(false)
        if (error === 'Access denied') {
          notify(
            `You don't have access to view the docs. Please reach out to DX team.`,
            {
              variant: 'error',
            }
          )
        }
        setLinkToDocs([])
        console.log(error, 'error:')
      })
  }

  const handleClickFile = () => {
    fetchlinkstosharepoint()
  }

  const handleClose = () => {
    props?.onClose()
    setAccount('')
    setProject('')
    setDocName('')
    setDocDescription(null)
    setSelectedFile([])
    setWorkStream(null)
    setUniqueId(null)
    setAccountGeneric(false)
    setStatus()
    setComments()
    setUploadedFile([])
  }

  return (
    <>
      <Dialog maxWidth="sm" open={props?.open} onClose={handleClose}>
        <Loading open={openBackdrop} />
        <DialogTitle>Artifact Details</DialogTitle>
        <DialogContent>
          <Grid align="center" container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                disabled
                control={<Checkbox checked={accountGeneric} color="primary" />}
                label="Account Generic"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'left',
                textAlign: 'left',
                fontSize: '16px',
                marginBottom: '10px',
              }}
            >
              <Grid xs={4}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  Doc Name:
                </div>
              </Grid>
              <Grid xs={8}>
                <span style={{ flex: '1' }}>{docName}</span>
                {(userDetails.role !== 'Reviewee' &&
                  status !== 'Rejected' &&
                  status !== 'Deleted') ||
                status === 'Approved' ? (
                  <>
                    <Tooltip title="Check the file">
                      <Button onClick={handleClickFile}>
                        <Folder color="primary" fontSize="medium" />
                      </Button>
                    </Tooltip>
                  </>
                ) : null}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                textAlign: 'left',
                fontSize: '16px',
                marginBottom: '10px',
              }}
            >
              <Grid xs={4}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  Doc Description:
                </div>
              </Grid>
              <Grid xs={8}>
                <span style={{ flex: '0.7' }}>{docDescription}</span>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                textAlign: 'left',
                fontSize: '16px',
                marginBottom: '10px',
              }}
            >
              <Grid xs={4}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  Account:
                </div>
              </Grid>
              <Grid xs={8}>
                <span style={{ flex: '0.71' }}>{account}</span>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                textAlign: 'left',
                fontSize: '16px',
                marginBottom: '10px',
              }}
            >
              <Grid xs={4}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  Project:
                </div>
              </Grid>
              <Grid xs={8}>
                <span style={{ flex: '0.85' }}>{project}</span>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                textAlign: 'left',
                fontSize: '16px',
                marginBottom: '10px',
              }}
            >
              <Grid xs={4}>
                <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  Status:
                </div>
              </Grid>
              <Grid xs={8}>
                <span style={{ flex: '0.8' }}>{status ? status : '-'}</span>
              </Grid>
            </Grid>
            {(userDetails.role === 'Reviewee' || 'Admin') && (
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  textAlign: 'left',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                <Grid xs={4}>
                  <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                    Approver:
                  </div>
                </Grid>
                <Grid xs={8}>
                  <span style={{ flex: '1' }}>
                    {props?.editRow?.approved_by}
                  </span>
                </Grid>
              </Grid>
            )}
            {(userDetails.role === 'Reviewer' 
              // || userDetails.role === 'PMO'
              ) && (
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  textAlign: 'left',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                <Grid xs={4}>
                  <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                    Uploader:
                  </div>
                </Grid>
                <Grid xs={8}>
                  <span style={{ flex: '0.9' }}>
                    {props?.editRow?.updated_by}
                  </span>
                </Grid>
              </Grid>
            )}
            {(userDetails.role === 'Reviewer' /*|| userDetails.role === 'PMO'*/) &&
              props?.editRow?.status?.toLowerCase() ===
                'pending'.toLowerCase() && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    textAlign: 'left',
                    fontSize: '16px',
                    marginBottom: '10px',
                  }}
                >
                  <Grid xs={4}>
                    <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                      Comments:
                    </div>
                  </Grid>
                  <Grid xs={8}>
                    <TextField
                      label="Comments"
                      value={commnets}
                      error={Boolean(!commnets)}
                      onChange={(e) => setComments(e.target.value)}
                      aria-label="minimum height"
                      minRows={2}
                      placeholder="Comments"
                      style={{
                        width: '100%',
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            {props?.editRow?.approver_comments && (
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  textAlign: 'left',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                <Grid xs={4}>
                  <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                    Comments:
                  </div>
                </Grid>
                <Grid xs={8}>
                  <span style={{ flex: '0.9' }}>{commnets}</span>
                </Grid>
              </Grid>
            )}
            {props?.editRow?.status?.toLowerCase() !==
              'pending'.toLowerCase() &&
              props?.editRow?.approved_on && (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      textAlign: 'left',
                      fontSize: '16px',
                      marginBottom: '10px',
                    }}
                  >
                    <Grid xs={4}>
                      <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
                        Approval Date:
                      </div>
                    </Grid>
                    <Grid xs={8}>
                      <span style={{ flex: '0.9' }}>{approvalDate}</span>
                    </Grid>
                  </Grid>
                </>
              )}

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <DialogActions>
                {(userDetails.role === 'Reviewer' /*||
                  userDetails.role === 'PMO'*/) &&
                  status?.toLowerCase() === 'pending'.toLowerCase() && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleApprove}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleReject}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                <Button
                  onClick={handleClose}
                  color="primary"
                  autoFocus
                  className="primarybtntext"
                >
                  Close
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ArtifactDetailForm
