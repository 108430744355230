import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAdminDetails } from '../../services/adminService'

const initialState = {
  data: {},
  isLoading: false,
  error: null,
}

export const fetchAdminDetails = createAsyncThunk(
  'content/fetchAdminDetails',
  async () => {
    const response = await getAdminDetails()

    if (response.success) {
      return response.data
    }

    return {}
  }
)

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdminDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchAdminDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchAdminDetails.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })
  },
})

export default adminSlice.reducer
