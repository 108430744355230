import { Backdrop, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1,
    color: '#ffffff',
  },
}))

const Loading = (props) => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default Loading
