import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControl,
  Grid,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateCsatFilterData,
  updateCsatHistoricalFilterData,
} from '../../redux/reducer/projectHealthFilterSlice'

const useStyles = makeStyles((theme) => ({
  ellipsisSelect: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

export default function FilterComponent(props) {
  const [rows, setRows] = useState([])
  const [optionValues1, setOptionValues1] = useState([])
  const [optionValues2, setOptionValues2] = useState([])
  const [optionValues3, setOptionValues3] = useState([])
  const [optionValues4, setOptionValues4] = useState([])
  const [selectAll, setSelectAll] = useState({
    vertical: false,
    account: false,
    project: false,
    status: false,
    time_period: false,
  })
  const [view, setView] = useState({
    vertical: [],
    account: [],
    project: [],
    time_period: [],
  })

  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    csatAdminInitialData,
    csatRevieweeInitialData,
    csatFilterData,
    csatHistoricalInitialData,
    csatHistoricalFilterData,
  } = useSelector((state) => state.filter)

  useEffect(() => {
    if (props.keyName === 'csatAdminVdh') {
      setRows(csatAdminInitialData)
    } else if (props.keyName === 'csatRevieweeAdh') {
      setRows(csatRevieweeInitialData)
      setView(csatFilterData)
    } else if (props.keyName === 'historicalNominations') {
      setRows(csatHistoricalInitialData)
      setView(csatHistoricalFilterData)
    }
  }, [
    csatAdminInitialData,
    csatRevieweeInitialData,
    csatHistoricalInitialData,
    csatHistoricalFilterData,
    csatFilterData,
    props.keyName,
  ])
  useEffect(() => {
    if (!rows || rows.length === 0) {
      return
    }
    const array1 =
      props.keyName === 'csatRevieweeAdh'
        ? rows
            .filter(
              (e) =>
                props?.excludeFilter?.toLowerCase() ===
                (e?.exclude?.toLowerCase() || 'no')
            )
            .map((v) => v.vertical)
            .sort()
        : rows.map((v) => v.vertical).sort()
    const selectAll = [...array1]
    setOptionValues1(_.uniq(selectAll))

    const filteredTimePeriod = rows.map((v) => v.time_period).sort()
    setOptionValues4(_.uniq(filteredTimePeriod))
  }, [rows])

  useEffect(() => {
    const filteredAccounts =
      props.keyName === 'csatRevieweeAdh'
        ? rows
            .filter(
              (item) =>
                view.vertical?.includes(item.vertical) &&
                props?.excludeFilter?.toLowerCase() ===
                  (item?.exclude?.toLowerCase() || 'no')
            )
            .map((item) => item.account)
            .sort()
        : rows
            .filter((item) => view.vertical?.includes(item.vertical))
            .map((item) => item.account)
            .sort()
    setOptionValues2(_.uniq(filteredAccounts))

    const filteredProjects =
      props.keyName === 'csatRevieweeAdh'
        ? rows
            .filter(
              (item) =>
                view.account?.includes(item.account) &&
                props?.excludeFilter?.toLowerCase() ===
                  (item?.exclude?.toLowerCase() || 'no')
            )
            .map((item) => item.project)
            .sort()
        : rows
            .filter((item) => view.account?.includes(item.account))
            .map((item) => item.project)
            .sort()
    setOptionValues3(_.uniq(filteredProjects))

    // const filteredTimePeriod = rows
    //   .filter((item) => view.project?.includes(item.project))
    //   .map((item) => item.time_period)
    //   .sort()
    // setOptionValues4(_.uniq(filteredTimePeriod))
  }, [view, optionValues1])

  useEffect(() => {
    setSelectAll({
      vertical: view.vertical.length === optionValues1?.length,
      account: view.account.length === optionValues2?.length,
      project: view.project.length === optionValues3?.length,
      time_period: view?.time_period?.length === optionValues4?.length,
    })
  }, [view, optionValues1, optionValues2, optionValues3, optionValues4])

  const onClickHandler = () => {
    if (props.keyName === 'csatRevieweeAdh') {
      dispatch(updateCsatFilterData({ vertical: [], account: [], project: [] }))
    } else {
      dispatch(
        updateCsatHistoricalFilterData({
          vertical: [],
          account: [],
          project: [],
          time_period: [],
        })
      )
    }
  }

  const handleSelectAllChange = (value, sourceData, fieldName) => {
    let updatedValues = []
    if (value.includes('Select All')) {
      updatedValues = selectAll[fieldName] ? [] : sourceData || []
      setSelectAll({ ...selectAll, [fieldName]: !selectAll[fieldName] })
    } else {
      updatedValues = value
      setSelectAll({ ...selectAll, [fieldName]: false })
    }
    if (sourceData.length === value.length) {
      updatedValues = value.length ? sourceData : []
      setSelectAll({ ...selectAll, [fieldName]: value.length > 0 })
    }
    return updatedValues
  }

  const onVerticalChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues1, 'vertical')
    if (value?.length > 0) {
      if (props.keyName === 'csatRevieweeAdh') {
        dispatch(
          updateCsatFilterData({ vertical: value, account: [], project: [] })
        )
      } else {
        dispatch(
          updateCsatHistoricalFilterData({
            vertical: value,
            account: [],
            project: [],
            time_period: [],
          })
        )
      }
      const filteredAccounts =
        props.keyName === 'csatRevieweeAdh'
          ? rows
              .filter(
                (item) =>
                  value.includes(item.vertical) &&
                  props?.excludeFilter?.toLowerCase() ===
                    (item?.exclude?.toLowerCase() || 'no')
              )
              .map((item) => item.account)
          : rows
              .filter((item) => value.includes(item.vertical))
              .map((item) => item.account)

      setOptionValues2(_.uniq(filteredAccounts))
    } else {
      if (props.keyName === 'csatRevieweeAdh') {
        dispatch(
          updateCsatFilterData({ vertical: [], account: [], project: [] })
        )
      } else {
        dispatch(
          updateCsatHistoricalFilterData({
            vertical: [],
            account: [],
            project: [],
            time_period: [],
          })
        )
      }
    }
  }

  const onAccountChange = async (event) => {
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues2, 'account')

    if (value?.length > 0) {
      if (props.keyName === 'csatRevieweeAdh') {
        dispatch(updateCsatFilterData({ account: value, project: [] }))
      } else {
        dispatch(
          updateCsatHistoricalFilterData({
            account: value,
            project: [],
            time_period: [],
          })
        )
      }
      const filteredProjects =
        props.keyName === 'csatRevieweeAdh'
          ? rows
              .filter(
                (item) =>
                  value.includes(item.account) &&
                  props?.excludeFilter?.toLowerCase() ===
                    (item?.exclude?.toLowerCase() || 'no')
              )
              .map((item) => item.project)
          : rows
              .filter((item) => value.includes(item.account))
              .map((item) => item.project)
      setOptionValues3(_.uniq(filteredProjects))
    } else {
      if (props.keyName === 'csatRevieweeAdh') {
        dispatch(updateCsatFilterData({ account: [], project: [] }))
      } else {
        dispatch(
          updateCsatHistoricalFilterData({
            account: [],
            project: [],
            time_period: [],
          })
        )
      }
    }
  }
  const onProjectChange = async (event) => {
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues3, 'project')
    if (value?.length > 0) {
      if (props.keyName === 'csatRevieweeAdh') {
        dispatch(updateCsatFilterData({ project: value }))
      } else {
        dispatch(
          updateCsatHistoricalFilterData({ project: value, time_period: [] })
        )
      }
      const filteredTimePeriod =
        props.keyName === 'csatRevieweeAdh'
          ? rows
              .filter(
                (item) =>
                  value.includes(item.project) &&
                  props?.excludeFilter?.toLowerCase() ===
                    (item?.exclude?.toLowerCase() || 'no')
              )
              .map((item) => item.time_period)
          : rows
              .filter((item) => value.includes(item.project))
              .map((item) => item.time_period)
      setOptionValues4(_.uniq(filteredTimePeriod))
    } else {
      if (props.keyName === 'csatRevieweeAdh') {
        dispatch(updateCsatFilterData({ project: [] }))
      } else {
        dispatch(
          updateCsatHistoricalFilterData({ project: [], time_period: [] })
        )
      }
    }
  }
  const onTimePeriodChange = async (event) => {
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues4, 'time_period')
    if (value?.length > 0) {
      if (props.keyName === 'historicalNominations') {
        dispatch(updateCsatHistoricalFilterData({ time_period: value }))
      }
    } else {
      if (props.keyName === 'historicalNominations') {
        dispatch(updateCsatHistoricalFilterData({ time_period: [] }))
      }
    }
  }
  const clickAwayHandler = (event) => {
    if (event.target.localName === 'body') {
      return
    }
    props.setAnchorEl(false)
  }
  return (
    <>
      <ClickAwayListener onClickAway={(e) => clickAwayHandler(e)}>
        <Box sx={{ margin: '10px' }}>
          <Grid padding="8px" container>
            <Grid item xs={3}>
              <Typography fontSize="14px">Filter</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                style={{ fontSize: '14px', margin: '-2px' }}
                onClick={onClickHandler}
              >
                Reset
              </Button>
            </Grid>
          </Grid>

          <Grid padding="15px">
            <FormControl
              variant="standard"
              sx={{ m: 1, width: 100 }}
              size="small"
            >
              <InputLabel>Vertical</InputLabel>
              <Select
                sx={{ height: '50%' }}
                value={view.vertical}
                label="vertical"
                className={classes.ellipsisSelect}
                onChange={(event) => {
                  onVerticalChange(event)
                }}
                multiple
                renderValue={(selected) => selected.join(', ')}
              >
                {optionValues1.length > 0 ? (
                  <MenuItem key="SelectAll" value="Select All">
                    <Checkbox checked={selectAll['vertical']} />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                ) : (
                  ''
                )}
                {optionValues1?.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={view?.vertical?.includes(item)} />{' '}
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip
              title={
                view.vertical.length === 0 ? 'Please select the Vertical' : ''
              }
            >
              <FormControl
                variant="standard"
                sx={{ m: 1, width: 100 }}
                size="small"
              >
                <InputLabel>Account</InputLabel>
                <Select
                  sx={{ height: '50%' }}
                  disabled={view.vertical.length === 0}
                  value={view.account}
                  label="account"
                  className={classes.ellipsisSelect}
                  onChange={(event) => {
                    onAccountChange(event)
                  }}
                  multiple
                  renderValue={(selected) => selected.join(', ')}
                >
                  {optionValues2.length > 0 ? (
                    <MenuItem key="SelectAll" value="Select All">
                      <Checkbox checked={selectAll['account']} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                  ) : (
                    ''
                  )}
                  {optionValues2?.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={view?.account?.includes(item)} />{' '}
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid padding="15px">
            <Tooltip
              title={
                view.account.length === 0 && view.vertical.length === 0
                  ? 'Please select the Vertical & Account'
                  : view.account.length === 0 && view.vertical.length !== 0
                  ? 'Please select the Account'
                  : ''
              }
            >
              <FormControl
                variant="standard"
                sx={{ m: 1, width: 100 }}
                size="small"
              >
                <InputLabel>Project</InputLabel>
                <Select
                  sx={{ height: '50%' }}
                  disabled={view.account.length === 0}
                  value={view?.project}
                  label="project"
                  className={classes.ellipsisSelect}
                  onChange={(event) => onProjectChange(event)}
                  multiple
                  renderValue={(selected) => selected.join(', ')}
                >
                  {optionValues3.length > 0 ? (
                    <MenuItem key="SelectAll" value="Select All">
                      <Checkbox checked={selectAll['project']} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                  ) : (
                    ''
                  )}

                  {optionValues3?.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={view?.project?.includes(item)} />{' '}
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
            {props.keyName === 'historicalNominations' && (
              <Tooltip
                title={
                  view.vertical.length === 0
                    ? ''
                    : view.account.length === 0
                    ? 'Please select the Account'
                    : view.account.length === 0 && view.vertical.length !== 0
                    ? 'Please select the Account'
                    : view.project.length === 0
                    ? 'Please select the Project'
                    : ''
                }
              >
                <FormControl
                  variant="standard"
                  sx={{ m: 1, width: 100 }}
                  size="small"
                >
                  <InputLabel>Time Period</InputLabel>
                  <Select
                    sx={{ height: '50%' }}
                    disabled={
                      view.vertical.length > 0 && view.project.length === 0
                    }
                    value={view?.time_period}
                    label="project"
                    className={classes.ellipsisSelect}
                    onChange={(event) => onTimePeriodChange(event)}
                    multiple
                    renderValue={(selected) => selected.join(', ')}
                  >
                    <MenuItem key="SelectAll" value="Select All">
                      <Checkbox checked={selectAll['time_period']} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {optionValues4?.map((item) => (
                      <MenuItem key={item} value={item}>
                        <Checkbox checked={view?.time_period?.includes(item)} />{' '}
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            )}
          </Grid>
        </Box>
      </ClickAwayListener>
    </>
  )
}
