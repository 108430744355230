import React, { useState, useEffect } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableContainer,
} from '@mui/material'
import CsatView from './csatAdminVdh'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import _ from 'lodash'

const CsatAdminVdh = () => {
  const [expanded, setExpanded] = useState(false)
  const [currentDate, setCurrentData] = useState([])

  return (
    <>
      <Paper elevation={4} style={{ padding: '20px', marginTop: '50px' }}>
        <div
          style={{
            marginBottom: '20px',
            fontSize: '20px',
            // fontWeight: 'bold',
            color: '#F58220',
          }}
        >
          Stakeholder Nominations
        </div>
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                    >
                      <Typography
                        style={{
                          fontSize: '14px',
                          color: 'primary',
                        }}
                      >
                        Current Nominations - 2023 H2
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CsatView rows={currentDate} view={'current'} />
                    </AccordionDetails>
                  </Accordion>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default CsatAdminVdh
