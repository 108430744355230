import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import GetAppIcon from '@mui/icons-material/GetApp'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}))

const DocumentUploadModel = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  const [changedFiles, setChangedFiles] = React.useState([])
  const setFileName = (artifact_name, file) => {
    if (file) {
      props.setSelectedFile({
        ...props.selectedFile,
        [artifact_name]: file,
      })
    }
  }

  return (
    <Dialog
      open={props.documentRepositoryModelOpen}
      onClose={props.handleDocumentModelClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      className="filesmodal"
    >
      <DialogTitle id="alert-dialog-title">Document Repository</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <List
              component="nav"
              className={classes.root}
              aria-label="mailbox folders"
            >
              {props.files.map((file, _id) => {
                return (
                  <>
                    <FileInput
                      setFileName={setFileName}
                      name={file.document_name}
                      artifact_name={file.artifact_name}
                      file={file.file}
                      review_id={file.review_id}
                    ></FileInput>
                  </>
                )
              })}
            </List>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          className="primarybtn"
          onClick={props.fileUploadHandler}
        >
          Upload
        </Button>
        <Button
          onClick={props.handleDocumentModelClose}
          color="primary"
          autoFocus
          className="primarybtntext"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const FileInput = (props) => {
  const acceptedFileTypes = [
    'application/pdf',
    'image/*',
    // text, word formats
    'text/plain',
    'application/rtf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    // excel formats
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
    // ppt formats
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.oasis.opendocument.presentation',
    // archive formats
    'application/x-freearc',
    'application/x-bzip',
    'application/x-bzip2',
    'application/gzip',
    'application/vnd.rar',
    'application/x-tar',
    'application/zip',
    'application/x-7z-compressed',
  ]

  let backendURL = `http://localhost:1337`

  if (process.env.NODE_ENV === 'production') {
    backendURL = `https://project-review.tredence.com`
  }

  const getFileName = (review_id, fileName) => {
    return (
      window.location.origin +
      '/static/reviews/REVIEW_' +
      review_id +
      '/' +
      fileName
    )
  }

  return (
    <>
      <ListItem button divider>
        <ListItemText primary={props.name}></ListItemText>
        {props.file ? (
          <Tooltip title="Preview Previously Uploaded Document">
            <a href={`${backendURL}/qualityReviewArtifacts/files/download?review_id=${props.review_id}&fileName=${props.file}`}
              download={props.file}
            >
              <IconButton color="secondary" size="small">
                <GetAppIcon className="downloadicon"></GetAppIcon>
              </IconButton>
            </a>
          </Tooltip>
        ) : (
          ''
        )}
        <input
          type="file"
          accept={acceptedFileTypes.join(', ')}
          onChange={(event) => {
            props.setFileName(props.artifact_name, event.target.files[0])
          }}
        />
      </ListItem>
    </>
  )
}
export default DocumentUploadModel
