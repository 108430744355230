import API from '../Common/api'
export const getVerticalDetails = async () => {
  try {
    const response = await API.get('/complianceReview/getVerticallist')
    return response
  } catch (err) {
    return err
  }
}
export const getSubverticalDetails = async (req) => {
  try {
    const response = await API.get(
      `/complianceReview/getSubverticallist?verticalId=${req}`
    )
    return response
  } catch (err) {
    return err
  }
}
export const getAlldetailsbasedVerticalsubvertical = async (req) => {
  try {
    const response = await API.get(
      `/complianceReview/getalldatabasedonVertical?verticalId=${req.verticalId}&subverticalId=${req.subverticalId}`
    )
    return response
  } catch (err) {
    return err
  }
}
export const saveAllverticalDetails = async (req) => {
  try {
    const response = await API.post(
      `/complianceReview/saveAllthecompliancedata`,
      { body: req }
    )
    return response
  } catch (err) {
    return err
  }
}
export default {
  getVerticalDetails,
  getSubverticalDetails,
  getAlldetailsbasedVerticalsubvertical,
  saveAllverticalDetails,
}
