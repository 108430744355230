import React, { useState, useEffect } from 'react'
import {
  DialogContent,
  DialogContentText,
  Divider,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material'
import Button from '@mui/material/Button'
import { blue } from '@mui/material/colors'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles } from '@mui/styles'
import GetAppIcon from '@mui/icons-material/GetApp'
import PropTypes from 'prop-types'
import ACKNOWLEDGE from './../../Common/AcknowledgeAlert'
import API from './../../Common/api'
import Loading from './../../Common/Loading'
import exportFromJSON from 'export-from-json'
import ReportsListModel from './reportsList'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
}))

function creatFileObject(fileJson) {
  let formData = new FormData()
  const listitem = Object.keys(fileJson)

  for (let i = 0; i < listitem.length; i++) {
    if (fileJson[listitem[i]]) {
      formData.append('types', listitem[i])
      let newName = listitem[i] + '_' + fileJson[listitem[i]].name
      formData.append('filename', fileJson[listitem[i]], newName)
    }
  }
  return formData
}

const curruntWeek = () => {
  var date = new Date()
  date.setHours(0, 0, 0, 0)
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
  var week1 = new Date(date.getFullYear(), 0, 4)
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  )
}

function SimpleDialog(props) {
  const classes = useStyles()
  const { onClose, selectedValue, open } = props
  let [fileList, setFileList] = React.useState({})
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const [messageDetails, setMessageDetails] = React.useState({
    message: '',
    severity: '',
  })

  const [isDisable, setIsDisable] = React.useState(true)

  const handleClose = () => {
    onClose(selectedValue)
    setIsDisable(true)
  }

  const getFileName = (fileName) => {
    //let fileDownLoad = "http://localhost:1337/static/sample/"+fileName+".csv";
    return window.location.origin + '/static/sample/' + fileName + '.csv'
    //return fileDownLoad;
  }

  const addFiles = (name, target) => {
    var filePath = target.files[0].name
    // Allowing file type
    var allowedExtensions = /(\.csv)$/i
    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload file having extensions .csv only.')
      target.value = ''
      return false
    } else {
      fileList[name] = target.files[0]
      setFileList(fileList)
      setIsDisable(false)
    }
  }

  const uploadFiles = () => {
    onClose(selectedValue)
    setOpenBackdrop(true)
    let formData = creatFileObject(fileList)
    let week_num = curruntWeek()

    API.post('/weeklyDataUpload/weeklyDataUpdate/' + week_num, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    }).then(
      (response) => {
        if (response.status == 200) {
          setOpenBackdrop(false)
          setMessageDetails({
            ...messageDetails,
            message: 'Files uploaded successfully',
            severity: 'success',
          })
          setFileList({})
        } else {
          setOpenBackdrop(false)
          setMessageDetails({
            ...messageDetails,
            message: 'Error in File Upload',
            severity: 'error',
          })
          setFileList({})
        }
      },
      (error) => {
        setOpenBackdrop(false)
        setMessageDetails({
          ...messageDetails,
          message: 'Error in Process File(s) Upload',
          severity: 'error',
        })
        setFileList({})
      }
    )
  }

  const handleListItemClick = (value) => {
    onClose(value)
  }

  const APICAllForGettingData = (type) => {
    setOpenBackdrop(true)
    API.post('/admin/getRequestedDataForDownload', { selectedType: type }).then(
      function (response) {
        const fileName = type + ' existing data'
        const exportType = 'csv'
        if (type === 'clients') {
          const data = response.data.clients
          exportFromJSON({ data, fileName, exportType })
        }
        if (type === 'projects') {
          const data = response.data.projects
          exportFromJSON({ data, fileName, exportType })
        }
        setOpenBackdrop(false)
      },
      (error) => {
        setMessageDetails({
          ...messageDetails,
          message: ' Error in Fetching  ' + type,
          severity: 'error',
        })
        setOpenBackdrop(false)
      }
    )
  }
  const getClientData = () => {
    APICAllForGettingData('clients')
  }

  const getProjectData = () => {
    APICAllForGettingData('projects')
  }

  return (
    <>
      <ACKNOWLEDGE
        message={messageDetails.message}
        severity={messageDetails.severity}
      ></ACKNOWLEDGE>
      <Loading open={openBackdrop} />
      <Dialog
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        className="filesmodal"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Upload Documents</DialogTitle>

        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ textAlign: 'right' }}>
              <Tooltip title="Download existing clients list">
                <Button
                  size="small"
                  color="primary"
                  onClick={getClientData}
                  variant="contained"
                  className={classes.button}
                  startIcon={<GetAppIcon />}
                  sx={{ mr: 1 }}
                >
                  Download Clients
                </Button>
              </Tooltip>
              <Tooltip title="Download existing projects list">
                <Button
                  size="small"
                  color="primary"
                  onClick={getProjectData}
                  variant="contained"
                  className={classes.button}
                  startIcon={<GetAppIcon />}
                >
                  Download Projects
                </Button>
              </Tooltip>
            </div>
            <Grid container spacing={2}>
              <List
                component="nav"
                className={classes.root}
                aria-label="mailbox folders"
              >
                <ListItem button divider>
                  <ListItemText
                    primary="Add/Update Employees  :"
                    secondary='*For Multi-Client / Project , Separator - "~~"'
                  />
                  <Tooltip title="">
                    <IconButton color="secondary">
                      <label htmlFor="upload-csv-label">
                        <input
                          id="upload-csv"
                          name="upload-csv"
                          type="file"
                          accept=".csv"
                          onChange={(event) => {
                            addFiles('sample-add-employees', event.target)
                          }}
                        />
                      </label>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download sample add/update-employees file">
                    <a
                      href={getFileName('sample-add-employees')}
                      download={'sample-add-employees.csv'}
                    >
                      <IconButton color="secondary">
                        <GetAppIcon
                          color="secondary"
                          className="downloadicon"
                        ></GetAppIcon>
                      </IconButton>
                    </a>
                  </Tooltip>
                </ListItem>

                <ListItem button divider>
                  <ListItemText primary="Add new Clients :" />
                  <Tooltip title="Add new Clients">
                    <IconButton color="secondary">
                      <label htmlFor="sample-add-clients">
                        <input
                          id="clients-file"
                          name="clients-file"
                          type="file"
                          accept=".csv"
                          onChange={(event) => {
                            addFiles('sample-add-clients', event.target)
                          }}
                        />
                      </label>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download sample add client file">
                    <a
                      href={getFileName('sample-add-clients')}
                      download={'sample-add-clients.csv'}
                    >
                      <IconButton color="secondary">
                        <GetAppIcon
                          color="secondary"
                          className="downloadicon"
                        ></GetAppIcon>
                      </IconButton>
                    </a>
                  </Tooltip>
                </ListItem>

                <ListItem button divider>
                  <ListItemText primary="Add new Engagments :" />
                  <Tooltip title="Weekly Clients & Engagment">
                    <IconButton color="secondary">
                      <label htmlFor="add-engagments">
                        <input
                          id="add-engagments"
                          name="add-engagments"
                          type="file"
                          accept=".csv"
                          onChange={(event) => {
                            addFiles('sample-add-engagments', event.target)
                          }}
                        />
                      </label>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download sample Engagment file">
                    <a
                      href={getFileName('sample-add-engagments')}
                      download={'sample-add-engagments.csv'}
                    >
                      <IconButton color="secondary">
                        <GetAppIcon
                          color="secondary"
                          className="downloadicon"
                        ></GetAppIcon>
                      </IconButton>
                    </a>
                  </Tooltip>
                </ListItem>
              </List>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={uploadFiles} color="primary" disabled={isDisable}>
            upload{' '}
          </Button>
          <Button onClick={handleClose} color="secondary">
            Close{' '}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

const Upload = (props) => {
  const [open, setOpen] = React.useState(false)

  const handleListOpen = () => {
    setOpen(true)
  }

  const handleListClose = (value) => {
    setOpen(false)
  }

  return (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {/* <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              position: 'relative',
              top: '1rem',
            }}
            onClick={handleListOpen}
          >
            Reports
          </Button>
        </Grid> */}
        <ReportsListModel open={open} setOpen={setOpen} />
      </Grid>
    </>
  )
}

export default Upload
