import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  FormControl,
  Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    borderRadius: '20px',
  },
  errorTextField: {
    borderRadius: '20px',
    borderColor: 'red',
  },
  errorHelperText: {
    color: 'red',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}))

const SaveRejectionModal = (props) => {
  const classes = useStyles()
  const { enqueueSnackbar: notify } = useSnackbar()
  const [textArea, setTextArea] = useState('')
  const [isValid, setIsValid] = useState(true)

  const onChangeTextAreaHandler = (e) => {
    setTextArea(e.target.value)
    if (e.target.value.trim()) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  const onClose = () => {
    props.setOpenDialog(false)
    setTextArea('')
    setIsValid(true)
  }

  const onSubmitRejectionReason = () => {
    if (textArea) {
      props.rejectionReasonHandler('Rejected', textArea)
    }
    setIsValid(!!textArea)
    if (!!textArea) {
      setTextArea('')
      onClose()
    }
    notify(`Click on Save`, {
      variant: 'info',
    })
  }

  return (
    <Dialog
      open={props.openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
      className="filesmodal"
    >
      <DialogTitle id="alert-dialog-title">
        Save Rejection Reason
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          style={{
            position: 'absolute',
            right: '20px',
            top: '12px',
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.54)',
          }}
        >
          <CancelOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid item xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>{props.project}</div>
              <KeyboardArrowRightIcon />
              <div>{props.phaseName}</div>
              <KeyboardArrowRightIcon />
              <div>{props.checkListNames}</div>
              {/* <span style={{ marginLeft: '4px', marginBottom: "-5px" }}>
                                <Tooltip title={props.checkListNames} placement="top">
                                    <div>
                                        <InfoOutlinedIcon style={{ fontSize: 18 }} />
                                    </div>
                                </Tooltip>
                            </span> */}
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                variant="outlined"
                id="standard-multiline-static"
                fullWidth
                error={!isValid}
                value={textArea}
                onChange={onChangeTextAreaHandler}
                multiline
                rows={10}
                className="w100"
                placeholder="Please mention the reason for rejection"
                helperText={!isValid && 'This field is required'}
              />
            </FormControl>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          className="primarybtn"
          onClick={onSubmitRejectionReason}
          disabled={!isValid}
        >
          Save
        </Button>
        <Button
          onClick={onClose}
          color="primary"
          autoFocus
          className="primarybtntext"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SaveRejectionModal
