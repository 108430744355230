import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUpcomingComplianceReviews } from '../../../../redux/reducer/complianceSlice'
import ReviewTable from './ReviewTable'
// import FilterComponent from '../../../Common/FilterComponent'
// import { Button } from '@mui/material'

function UpcomingSchedules() {
  const dispatch = useDispatch()
  const { upcomingComplianceReviews, isFetchUpcomingComplianceReviewsLoading } =
    useSelector((state) => state.compliance)
  const { userDetails } = useSelector((state) => state.user)
  useEffect(() => {
    let params = {}
    if (userDetails.role === 'Reviewee') {
      params = {
        projectLead: userDetails.emp_name,
      }
    }
    dispatch(fetchUpcomingComplianceReviews(params))
  }, [dispatch, userDetails])

  return (
    <ReviewTable
      data={upcomingComplianceReviews}
      isLoading={isFetchUpcomingComplianceReviewsLoading}
    />
  )
}

export default UpcomingSchedules
