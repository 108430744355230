import { createSlice } from '@reduxjs/toolkit'
import { getWeekNumber } from '../../Component/View/ProjectHealth/Common/utils/commonFunc'

const currentYear = new Date().getFullYear()
const initialState = {
  weeklyHealthInitialData: [],
  consolidatedViewInitialData: [],
  otherArtifactsInitialData: [],
  csatAdminInitialData: [],
  csatRevieweeInitialData: [],
  csatHistoricalInitialData: [],
  filterData: {
    vertical: [],
    account: [],
    project: [],
    exclude: [],
    health_status: [],
  },
  selectAll: {
    vertical: false,
    account: false,
    project: false,
    status: false,
  },
  csatFilterData: { vertical: [], account: [], project: [] },
  csatHistoricalFilterData: {
    vertical: [],
    account: [],
    project: [],
    time_period: [],
  },
  page: getWeekNumber(currentYear),
  year: currentYear,
  consolidatedViewYear: currentYear,
  isApiCalHappened: false,
  exclude: 'No',
  count: 0,
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    updateWeekData: (state, action) => {
      return {
        ...state,
        page: action.payload,
      }
    },
    updateisApiCallMade: (state, action) => {
      return {
        ...state,
        isApiCalHappened: action.payload,
      }
    },
    updateConsolidatedYearData: (state, action) => {
      return {
        ...state,
        consolidatedViewYear: action.payload,
      }
    },
    updateYearData: (state, action) => {
      return {
        ...state,
        year: action.payload,
      }
    },
    updateCount: (state, action) => {
      return {
        ...state,
        count: action.payload,
      }
    },
    updateExcludeData: (state, action) => {
      return {
        ...state,
        exclude: action.payload,
      }
    },
    updateWeeklyHealthInitialData: (state, action) => {
      return {
        ...state,
        weeklyHealthInitialData: action.payload,
      }
    },
    updateConsolidatedViewInitialData: (state, action) => {
      return {
        ...state,
        consolidatedViewInitialData: action.payload,
      }
    },
    updateOtherArtifactsInitialData: (state, action) => {
      return {
        ...state,
        otherArtifactsInitialData: action.payload,
      }
    },
    updateCsatAdminInitialData: (state, action) => {
      return {
        ...state,
        csatAdminInitialData: action.payload,
      }
    },
    updateCsatRevieweeInitialData: (state, action) => {
      return {
        ...state,
        csatRevieweeInitialData: action.payload,
      }
    },
    updateCsatHistoricalInitialData: (state, action) => {
      return {
        ...state,
        csatHistoricalInitialData: action.payload,
      }
    },
    updateFilterData: (state, action) => {
      const { vertical, account, project, exclude, health_status } =
        action.payload
      state.filterData = {
        vertical: vertical !== undefined ? vertical : state.filterData.vertical,
        account: account !== undefined ? account : state.filterData.account,
        project: project !== undefined ? project : state.filterData.project,
        exclude: exclude !== undefined ? exclude : state.filterData.exclude,
        health_status:
          health_status !== undefined
            ? health_status
            : state.filterData.health_status,
      }
    },
    selectAllData: (state, action) => {
      const { vertical, account, project, status } = action.payload
      state.selectAll = {
        vertical: vertical !== undefined ? vertical : false,
        account: account !== undefined ? account : false,
        project: project !== undefined ? project : false,
        // exclude: exclude !== undefined ? exclude : state.filterData.exclude,
        status: status !== undefined ? status : false,
      }
    },
    updateCsatFilterData: (state, action) => {
      const { vertical, account, project } = action.payload
      state.csatFilterData = {
        vertical:
          vertical !== undefined ? vertical : state.csatFilterData.vertical,
        account: account !== undefined ? account : state.csatFilterData.account,
        project: project !== undefined ? project : state.csatFilterData.project,
      }
    },
    updateCsatHistoricalFilterData: (state, action) => {
      const { vertical, account, project, time_period } = action.payload
      state.csatHistoricalFilterData = {
        vertical:
          vertical !== undefined
            ? vertical
            : state.csatHistoricalFilterData.vertical,
        account:
          account !== undefined
            ? account
            : state.csatHistoricalFilterData.account,
        project:
          project !== undefined
            ? project
            : state.csatHistoricalFilterData.project,
        time_period:
          time_period !== undefined
            ? time_period
            : state.csatHistoricalFilterData.time_period,
      }
    },
  },
})

export const {
  updateWeeklyHealthInitialData,
  updateConsolidatedViewInitialData,
  updateOtherArtifactsInitialData,
  updateFilterData,
  updateCsatHistoricalFilterData,
  selectAllData,
  updateWeekData,
  updateCsatAdminInitialData,
  updateCsatFilterData,
  updateCsatRevieweeInitialData,
  updateCsatHistoricalInitialData,
  updateYearData,
  updateExcludeData,
  updateisApiCallMade,
  updateConsolidatedYearData,
  updateCount,
} = filterSlice.actions
export default filterSlice.reducer
