import InputBase from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import { Delete } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Button, Grid, IconButton, Stack, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import _ from 'lodash'
import MUIDataTable from 'mui-datatables'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../../Common/Loading'
import { updateOtherArtifactsInitialData } from '../../../../redux/reducer/projectHealthFilterSlice'
import {
  getAllDefaultChecklists,
  getComplianceArtifacts,
  getComplianceArtifactsAdmin,
  //getComplianceArtifactsPmo,
  getUniqueAccounts,
  getUniqueAccountsforAdmin,
  //getUniqueAccountsforPmo,
} from '../../../../services/complianceService'
import FilterComponent from '../../../View/ProjectHealth/Common/multiSelectCustomFilter'
import ArtifactDetailForm from './ArtifactDetailForm'
import ArtifactsForm from './ArtifactsForm'
import DeleteArtifactsForm from './DeleteArtifactForm'

const useStyles = makeStyles({
  toolbarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '-50px',
    marginRight: '30px',
  },

  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
  },
  searchInput: {
    marginLeft: '8px',
    marginRight: '8px',
    width: '200px',
    borderBottom: '2px solid #F58220',
    fontSize: '12px',
  },
  searchIcon: {
    cursor: 'pointer',
    color: '#F58220',
  },
  options: {
    marginTop: '10px',
  },
})

function ArtifactsTable(props) {
  const [rows, setRows] = useState()
  const [rowsData, setRowsData] = useState([])
  const [filter, setfilter] = useState({})
  const [allChecklistsData, setAllChecklistsData] = useState([])
  const [defaultChecklistsCsv, setDefaultChecklistsCsv] = useState([])
  const [openForm, setOpenForm] = useState(false)
  const [openDeleteForm, setOpenDeleteForm] = useState(false)
  const [openDetailsForm, setOpenDetailsForm] = useState(false)
  const [editRowIndex, setEditRowIndex] = useState(null)
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const [uniqueVerticalId, setUniqueVerticalId] = useState([])
  const [uniqueAccount, setUniqueAccount] = useState([])
  const [uniqueProjects, setUniqueProjects] = useState([])
  const [checklists, setChecklists] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const { data: adminData } = useSelector((state) => state.admin)
  const { userDetails } = useSelector((state) => state.user)
  const { filterData } = useSelector((state) => state.filter)
  const [searchText, setSearchText] = useState('')
  const [displayactions, setDispalyActions] = useState(true)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSearch = (value) => {
    setSearchText(value)
  }
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleFormOpen = () => {
    setOpenForm(true)
  }
  const handleDeleteFormOpen = () => {
    setOpenDeleteForm(true)
  }
  const handleDetailsFormOpen = () => {
    setOpenDetailsForm(true)
  }

  const handleFormClose = () => {
    setOpenForm(false)
    setEditRowIndex(null)
  }
  const handleDeleteFormClose = () => {
    setOpenDeleteForm(false)
    setEditRowIndex(null)
  }
  const handleDetailsFormClose = () => {
    setOpenDetailsForm(false)
    setEditRowIndex(null)
  }
  function onClose() {
    handleFormClose()
  }
  function onCloseDeleteForm() {
    handleDeleteFormClose()
  }
  function onCloseDetailsForm() {
    handleDetailsFormClose()
  }

  const onEdit = (index) => () => {
    setEditRowIndex(index)
    handleDeleteFormOpen()
  }
  const onDetailCheck = (index) => () => {
    setEditRowIndex(index)
    handleDetailsFormOpen()
  }
  const getAllAccountsforAdmin = () => {
    setOpenBackdrop(true)

    getUniqueAccountsforAdmin()
      .then((res) => {
        if (res.success) {
          setUniqueAccount(res.data.result)
        }
      })
      .catch((error) => {
        console.error('Error while fetching csat:', error)
      })
      .finally(() => {
        setOpenBackdrop(false)
      })
  }
  const getAllAccountsforVdh = (verticals) => {
    setOpenBackdrop(true)
    const data = { role: 'VDH', vertical_id: verticals }

    getUniqueAccounts(data)
      .then((res) => {
        if (res.success) {
          setUniqueAccount(res?.data?.result)
        }
      })
      .catch((error) => {
        console.error('Error while fetching unique accounts:', error)
      })
      .finally(() => {
        setOpenBackdrop(false)
      })
  }
  // const getAllAccountsforPmo = (verticals) => {
  //   setOpenBackdrop(true)
  //   const data = {
  //     role: 'PMO',
  //     vertical_id: verticals,
  //     email_id: userDetails.email_id,
  //   }
  //   getUniqueAccountsforPmo(data)
  //     .then((res) => {
  //       if (res.success) {
  //         console.log('res.success', res?.data?.result)
  //         setUniqueAccount(res?.data?.result)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error while fetching unique accounts:', error)
  //     })
  //     .finally(() => {
  //       setOpenBackdrop(false)
  //     })
  // }
  const getUniqueAccountsfunction = () => {
    setOpenBackdrop(true)
    const email = userDetails.email_id
    const data = { email: email, role: userDetails.role }

    getUniqueAccounts(data)
      .then((res) => {
        if (res.success) {
          setUniqueAccount(res?.data?.result)
        }
      })
      .catch((error) => {
        console.error('Error while fetching unique accounts:', error)
      })
      .finally(() => {
        setOpenBackdrop(false)
      })
  }

  const getArtifacts = () => {
    setOpenBackdrop(true)

    if (userDetails.role === 'Reviewee') {
      const data = {
        updated_by: userDetails.email_id,
        // project: uniqueProjects
      }

      getComplianceArtifacts(data)
        .then((res) => {
          if (res.success) {
            setRows(res.data.result)
            setRowsData(res.data.result)
            dispatch(updateOtherArtifactsInitialData(res.data.result))
          }
        })
        .catch((error) => {
          console.error('Error while fetching artifacts:', error)
        })
        .finally(() => {
          setOpenBackdrop(false)
        })
    } else if (userDetails.role === 'Reviewer') {
      const data = { account: uniqueAccount }

      getComplianceArtifacts(data)
        .then((res) => {
          if (res.success) {
            setRows(res.data.result)
            setRowsData(res.data.result)
            dispatch(updateOtherArtifactsInitialData(res.data.result))
          }
        })
        .catch((error) => {
          console.error('Error while fetching artifacts:', error)
        })
        .finally(() => {
          setOpenBackdrop(false)
        })
    } 
   /* else if (userDetails.role === 'PMO') {
      const data = { account: uniqueAccount, email_id: userDetails.email_id }

      getComplianceArtifactsPmo(data)
        .then((res) => {
          if (res.success) {
            setRows(res.data.result)
            setRowsData(res.data.result)
            dispatch(updateOtherArtifactsInitialData(res.data.result))
          }
        })
        .catch((error) => {
          console.error('Error while fetching artifacts:', error)
        })
        .finally(() => {
          setOpenBackdrop(false)
        })
    } */
    else if (userDetails.role === 'Admin') {
      getComplianceArtifactsAdmin()
        .then((res) => {
          if (res.success) {
            setRows(res.data.result)
            setRowsData(res.data.result)
            dispatch(updateOtherArtifactsInitialData(res.data.result))
          }
        })
        .catch((error) => {
          console.error('Error while fetching artifacts:', error)
        })
        .finally(() => {
          setOpenBackdrop(false)
        })
    }
  }

  const filtersData = (data) => {
    let filteredData = data
    if (filterData.vertical && filterData.vertical.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterData.vertical.includes(item.vertical)
      )
    }
    if (filterData.account && filterData.account.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account)
      )
    }
    if (filterData.project && filterData.project.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account) &&
          filterData.project.includes(item.project)
      )
    }
    return filteredData
  }

  useEffect(() => {
    if (filterData.vertical && filterData.vertical.length > 0) {
      let filteredData = filtersData(rowsData)
      setRows(filteredData)
    } else {
      setRows(rowsData)
    }
  }, [filterData, rowsData])

  const getAllChecklists = async () => {
    getAllDefaultChecklists()
      .then((res) => {
        if (res.success) {
          const uniquechecklists = _.uniq(
            _.map(res.data.complianceChecklistsCsv, 'checklist')
          )
          setChecklists(uniquechecklists)
        }
      })
      .catch((error) => {
        console.error('Error while fetching csat:', error)
      })
      .finally(() => {
        setOpenBackdrop(false)
      })
  }

  useEffect(() => {
    if (userDetails.role === 'Admin') {
      getAllAccountsforAdmin()
    } 
    // else if (
    //   //userDetails?.role === 'PMO' &&
    //   userDetails?.vertical_ids?.verticalIds
    // ) {
    //   const verticalids = userDetails?.vertical_ids?.verticalInfo?.map(
    //     (item) => item?.verticalId
    //   )
    //   setUniqueVerticalId(verticalids)
    //   //getAllAccountsforPmo(verticalids)
    // } 
    else {
      if (
        userDetails?.role === 'Reviewer' &&
        userDetails?.vertical_ids?.verticalIds
      ) {
        const verticalids = userDetails?.vertical_ids?.verticalInfo?.map(
          (item) => item?.verticalId
        )
        setUniqueVerticalId(verticalids)
        getAllAccountsforVdh(verticalids)
      } else {
        getUniqueAccountsfunction()
      }
    }
    getAllChecklists()
    if (userDetails?.role === 'Reviewer') {
      setDispalyActions('excluded')
    }
  }, [])

  useEffect(() => {
    getArtifacts()
  }, [uniqueAccount, uniqueProjects])

  const columns = useMemo(
    () => [
      {
        label: 'Doc Name',
        name: 'Doc_name',
        options: {
          searchable: true,

          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Doc Description',
        name: 'Doc_description',
        options: {
          searchable: true,

          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Account ',
        name: 'account',
        options: {
          searchable: true,

          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Project ',
        name: 'project',
        options: {
          searchable: true,

          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Workstream',
        name: 'workstream',
        options: {
          searchable: false,

          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          searchable: false,

          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
          customBodyRender: (value, tableMeta, updateValue) => (
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={onDetailCheck(
                tableMeta?.currentTableData[tableMeta?.rowIndex]?.index
              )}
            >
              {userDetails.role == 'Reviewer' && value == 'Pending'
                ? 'Take Action'
                : value}
            </span>
          ),
        },
      },

      {
        label: 'Actions',
        name: 'Actions',
        options: {
          sort: false,
          empty: true,
          download: false,
          display: displayactions,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  marginRight: '12px',
                }}
              >
                {columnMeta.label}
              </div>
            )
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Stack direction="row" spacing={1} justifyContent="left">
                <Tooltip title="Delete Artifact">
                  <IconButton
                    disabled={
                      rows[
                        tableMeta?.currentTableData[tableMeta?.rowIndex]?.index
                      ]?.status?.toLowerCase() === 'pending'
                        ? false
                        : true
                    }
                    aria-label="Delete Artifact"
                    color="primary"
                    onClick={onEdit(
                      tableMeta?.currentTableData[tableMeta?.rowIndex]?.index
                    )}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Stack>
            )
          },
        },
      },
    ],
    [rows, filterData]
  )
  const handleClear = () => {
    setSearchText('')
  }
  const options = {
    responsive: 'standard',
    selectableRows: 'none',
    search: false,
    filter: false,
    searchText: searchText,
    print: false,
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 25, 50],
    // rowsPerPage: 5,
    elevation: 2,
    download: true,
    downloadOptions: {
      filename: 'other-artifacts.csv',
    },
    // onDownload: (buildHead, buildBody, columns, Data) => {
    //   console.log('data', Data, buildHead, buildBody, columns)
    //   const formattedData = Data?.flatMap((item) => {
    //     const projectTypeIdCsv = item.data[20]
    //     const phases = item.data[9] || []
    //     const phasesChecklists = allChecklistsData?.filter(
    //       (x) => x.review_id === item.data[0]
    //     )
    //     console.log('phasesChecklists', phasesChecklists)
    //     if (phases.length === 0) {
    //       return [
    //         {
    //           index: item.index,
    //           data: [
    //             item.data[0],
    //             item.data[1],
    //             item.data[2],
    //             item.data[3],
    //             item.data[4],
    //             item.data[5],
    //             item.data[6],
    //             item.data[7],
    //             item.data[8],
    //             'TBD',
    //             'TBD',
    //             'TBD',
    //             'TBD',
    //             'TBD',
    //             'TBD',
    //             'TBD',
    //             'TBD',
    //             'TBD',
    //             'TBD',
    //           ],
    //         },
    //       ]
    //     }

    //     return phases.flatMap((v) => {
    //       let defaultChecklistsData = defaultChecklistsCsv?.filter(
    //         (dc) =>
    //           dc.project_type_id === projectTypeIdCsv &&
    //           dc.phase_id === v.phase_id
    //       )
    //       const phaseData = [
    //         item.data[0],
    //         item.data[1],
    //         item.data[2],
    //         item.data[3],
    //         item.data[4],
    //         item.data[5],
    //         item.data[6],
    //         item.data[7],
    //         item.data[8],
    //         v.phase ? v.phase : 'TBD',
    //         v.phaseStartDate ? v.phaseStartDate : 'TBD',
    //         v.phaseEndDate ? v.phaseEndDate : 'TBD',
    //       ]

    //       const checklistsForPhase = phasesChecklists?.filter(
    //         (checklist) => checklist.phase_id === v.phase_id
    //       )

    //       return defaultChecklistsData.flatMap((checklistItem) => {
    //         const checklistData = [
    //           checklistItem.checklist ? checklistItem.checklist : 'TBD',
    //           checklistItem.evidenceOfCompletion
    //             ? checklistItem.evidenceOfCompletion
    //             : 'TBD',
    //           checklistItem.links ? checklistItem.links : 'TBD',
    //         ]
    //         if (checklistsForPhase.length === 0) {
    //           return {
    //             index: item.index,
    //             data: [
    //               ...phaseData,
    //               ...checklistData,
    //               'TBD',
    //               'TBD',
    //               'TBD',
    //               'TBD',
    //             ],
    //           }
    //         }
    //         checklistsForPhase.map((savedChecklist) => {
    //           const parsedChecklist = JSON.parse(
    //             savedChecklist.checklist || '[]'
    //           )
    //           parsedChecklist.map((savedChecklistItem) => {
    //             if (
    //               savedChecklistItem &&
    //               savedChecklistItem.checklist_id === checklistItem.checklist_id
    //             ) {
    //               checklistData.push(
    //                 savedChecklistItem.applicationflag
    //                   ? savedChecklistItem.applicationflag
    //                   : 'TBD'
    //               )
    //               checklistData.push(
    //                 savedChecklistItem.remark
    //                   ? savedChecklistItem.remark
    //                   : 'TBD'
    //               )
    //               // checklistData.push(
    //               //   savedChecklistItem.kmslinks
    //               //     ? savedChecklistItem.kmslinks
    //               //     : 'TBD'
    //               // )
    //               checklistData.push(
    //                 savedChecklistItem.checklist_status
    //                   ? savedChecklistItem.checklist_status
    //                   : 'TBD'
    //               )
    //               checklistData.push(
    //                 savedChecklistItem.rejectionReason
    //                   ? savedChecklistItem.rejectionReason
    //                   : 'TBD'
    //               )
    //             }
    //           })
    //         })
    //         return {
    //           index: item.index,
    //           data: [...phaseData, ...checklistData],
    //         }
    //       })
    //     })
    //   })
    //   console.log('columns', columns)
    //   const csvColumns = columns.slice(0, columns?.length - 1)
    //   csvColumns.splice(17, 1)
    //   return buildHead(csvColumns) + buildBody(formattedData)
    // },

    customToolbar: () => {
      return (
        <>
          <div className={classes.toolbarContainer}>
            <div className={classes.searchContainer}>
              <InputBase
                placeholder="Search..."
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                className={classes.searchInput}
              />
              {searchText ? (
                <IconButton type="button" onClick={handleClear}>
                  <ClearIcon onClick={handleClear} />
                </IconButton>
              ) : (
                <SearchIcon className={classes.searchIcon} />
              )}
              <FilterListIcon
                variant="contained"
                color="primary"
                onClick={handleClick}
                sx={{
                  margin: '-8px',
                  marginLeft: '7px',
                  marginRight: '7px',
                  cursor: 'pointer',
                }}
              ></FilterListIcon>
              <Popper id={id} open={open} anchorEl={anchorEl}>
                <Box sx={{ p: 1.2, backgroundColor: '#f9f9f9' }}>
                  <FilterComponent
                    keyName={'otherArtifacts'}
                    setAnchorEl={setAnchorEl}
                    setfilter={setfilter}
                  />
                </Box>
              </Popper>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFormOpen}
                style={{
                  top: 2,
                  margin: '10px 0px 10px 10px',
                }}
              >
                Add Artifacts
              </Button>
            </div>
          </div>
        </>
      )
    },
  }
  const editRow = editRowIndex !== null ? rows[editRowIndex] : {}

  return (
    <>
      <Loading open={openBackdrop} />
      <MUIDataTable data={rows} columns={columns} options={options} />

      <Grid item xs={12}>
        <ArtifactsForm
          open={openForm}
          onClose={onClose}
          accounts={uniqueAccount}
          projects={uniqueProjects}
          checklists={checklists}
          getArtifacts={getArtifacts}
          workstreams={adminData.workstreams}
          employees={adminData.employeeDetails}
          editRow={editRow}
          user={userDetails}
        />
      </Grid>

      <Grid item xs={12}>
        <DeleteArtifactsForm
          open={openDeleteForm}
          onClose={onCloseDeleteForm}
          accounts={uniqueAccount}
          projects={uniqueProjects}
          checklists={checklists}
          getArtifacts={getArtifacts}
          workstreams={adminData.workstreams}
          employees={adminData.employeeDetails}
          // editForm={editForm}
          editRow={editRow}
          user={userDetails}
        />
      </Grid>

      <Grid item xs={12}>
        <ArtifactDetailForm
          open={openDetailsForm}
          onClose={onCloseDetailsForm}
          accounts={uniqueAccount}
          projects={uniqueProjects}
          checklists={checklists}
          getArtifacts={getArtifacts}
          workstreams={adminData.workstreams}
          employees={adminData.employeeDetails}
          editRow={editRow}
          user={userDetails}
        />
      </Grid>
    </>
  )
}

export default ArtifactsTable
