import React from 'react';

const CopyRight = ({styleProps}) => {
    const currentYear = new Date().getFullYear();

    return (
        <div style={{ fontFamily: 'Poppins', fontSize: '0.7rem',opacity:'0.8',...styleProps }}>
            &copy; Copyright {currentYear}. Tredence.Inc. All
            rights reserved.
        </div>
    );
};

export default CopyRight;
