import FilterListIcon from '@mui/icons-material/FilterList'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import _ from 'lodash'
import MUIDataTable from 'mui-datatables'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../Common/Loading'
import FilterComponent from '../../../Component/View/ProjectHealth/Common/multiSelectCustomFilter'
import {
  updateCsatAdminInitialData,
  updateCsatFilterData,
} from '../../../redux/reducer/projectHealthFilterSlice'
import {
  getCsatAdminData,
  getCsatReviwerData,
} from '../../../services/csatService'
import './csatstyles.css'

export default function CsatView() {
  const [rows, setRows] = useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const { userDetails } = useSelector((state) => state.user)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const { csatAdminInitialData, csatFilterData } = useSelector(
    (state) => state.filter
  )
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const filtersData = (data) => {
    let filteredData = data
    if (csatFilterData.vertical && csatFilterData.vertical.length > 0) {
      filteredData = filteredData.filter((item) =>
        csatFilterData.vertical.includes(item.vertical)
      )
    }
    if (csatFilterData.account && csatFilterData.account.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          csatFilterData.vertical.includes(item.vertical) &&
          csatFilterData.account.includes(item.account)
      )
    }
    if (csatFilterData.project && csatFilterData.project.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          csatFilterData.vertical.includes(item.vertical) &&
          csatFilterData.account.includes(item.account) &&
          csatFilterData.project.includes(item.project)
      )
    }
    return filteredData
  }

  let verticalId = userDetails?.vertical_ids?.verticalIds
  const getAdminData = (data) => {
    setOpenBackdrop(true)
    getCsatAdminData(data)
      .then((res) => {
        if (res.success) {
          setRows(res.data.result)
          setOpenBackdrop(false)
          dispatch(updateCsatAdminInitialData(res.data.result))
        }
      })
      .catch((error) => {
        console.error('Error while fetching csat records:', error)
        setOpenBackdrop(false)
      })
  }
  const getReviewerData = (data) => {
    setOpenBackdrop(true)
    getCsatReviwerData(data)
      .then((res) => {
        setRows(res.data.result)
        setOpenBackdrop(false)
        console.log('RESULT',res.data.result)
        dispatch(updateCsatAdminInitialData(res.data.result))
      })
      .catch((error) => {
        console.error('Error while fetching csat records:', error)
        setOpenBackdrop(false)
      })
  }
  useEffect(() => {
    if (userDetails.role === 'Admin') {
      let data = {
        role: userDetails.role,
        timePeriod: '2023 H2',
      }
      getAdminData(data)
    } else if (userDetails.role === 'Reviewer') {
      let data = {
        role: userDetails.role,
        timePeriod: '2023 H2',
        verticalId: verticalId,
      }
      getReviewerData(data)
    }
    console.log('KKKKKK')
    /* else if (userDetails.role === 'PMO') {
      let data = {
        role: userDetails.role,
        timePeriod: '2023 H2',
        email_id: userDetails.email_id,
      }
      getReviewerData(data)
    }*/
  }, [])

  useEffect(() => {
    if (
      csatAdminInitialData.length > 0 &&
      csatFilterData.vertical &&
      csatFilterData.vertical.length > 0
    ) {
      let filteredData = filtersData(csatAdminInitialData)
      setRows(filteredData)
    } else if (
      csatAdminInitialData.length > 0 &&
      csatFilterData.vertical.length === 0
    ) {
      setRows(csatAdminInitialData)
    }
  }, [csatFilterData])

  const columns = useMemo(
    () => [
      {
        label: 'Vertical',
        name: 'vertical',
        options: {
          filterList: csatFilterData?.vertical,
          display: 'excluded',
        },
      },
      {
        label: 'Account',
        name: 'account',
        options: {
          searchable: true,
          filterList: csatFilterData?.account,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Project',
        name: 'project',
        options: {
          searchable: true,
          filterList: csatFilterData?.project,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Work Stream',
        name: 'workstream',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'CS Email',
        name: 'cs_emailId',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client Name',
        name: 'client_name',
        options: {
          searchable: true,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Client Email',
        name: 'client_email',
        options: {
          display: 'excluded',
        },
      },
      {
        label: 'Client Designation',
        name: 'client_designation',
        options: {
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Client First Name',
        name: 'client_first_name',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client Last Name',
        name: 'client_last_name',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client Preferred Name',
        name: 'client_prefferred_name',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Tenure',
        name: 'client_tenure',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Survey Type',
        name: 'survey_type',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Internal Surveyee',
        name: 'internal_surveyee',
        options: {
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Direct Client',
        name: 'direct_client',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Time Period',
        name: 'time_period',
        options: {
          searchable: true,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Created By',
        name: 'created_by',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Created At',
        name: 'createdAt',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Updated By',
        name: 'updated_by',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Updated At',
        name: 'updatedAt',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
    ],
    [csatFilterData]
  )
  const onRemove = (index, removedFilter, filterList) => {
    if (index === 0) {
      const removedItems = rows
        .filter((item) => item.vertical === removedFilter)
        .map((item) => item.account)
      const accounts = csatFilterData.account.filter(
        (account) => !removedItems.includes(account)
      )
      const updatedAccounts = rows
        .filter((item) => accounts.includes(item.account))
        .map((item) => item.account)
      const projects = rows
        .filter((item) => updatedAccounts.includes(item.account))
        .map((item) => item)
      const updatedProjects = projects
        .filter((item) => csatFilterData.project.includes(item.project))
        .map((item) => item.project)

      dispatch(
        updateCsatFilterData({
          vertical: filterList[0],
          account: _.uniq(updatedAccounts),
          project: _.uniq(updatedProjects),
        })
      )
    } else if (index === 1) {
      const removedItems = rows
        .filter((item) => item.account === removedFilter)
        .map((item) => item.project)

      const projects = csatFilterData.project.filter(
        (project) => !removedItems.includes(project)
      )
      const updatedProjects = rows
        .filter((item) => projects.includes(item.project))
        .map((item) => item.project)

      dispatch(
        updateCsatFilterData({
          vertical: filterList[0],
          account: filterList[1],
          project: _.uniq(updatedProjects),
        })
      )
    } else if (index === 2) {
      dispatch(
        updateCsatFilterData({
          vertical: filterList[0],
          account: filterList[1],
          project: filterList[2],
        })
      )
    }
  }
  const options = {
    responsive: 'standard',
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: 'No nominations to display!',
      },
    },
    search: false,
    print: false,
    filter: false,
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 25, 50],
    rowsPerPage: 5,
    elevation: 2,
    download: true,
    downloadOptions: {
      filename: 'csat.csv',
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      // const modifiedArray = data.map((obj) => ({
      //   ...obj,
      //   data: obj.data.map((value) => (value === null || value === undefined || value === "" ? '-' : value)),
      // }));
      return buildHead(columns) + buildBody(data)
    },
    customToolbar: () => {
      return (
        <>
          <FilterListIcon
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
              margin: '-8px',
              marginLeft: '7px',
              cursor: 'pointer',
            }}
          ></FilterListIcon>
          <Popper id={id} open={open} anchorEl={anchorEl}>
            <Box sx={{ p: 1.2, backgroundColor: '#f9f9f9' }}>
              <FilterComponent
                keyName={'csatAdminVdh'}
                setAnchorEl={setAnchorEl}
              />
            </Box>
          </Popper>
        </>
      )
    },
    onFilterChipClose: (index, removedFilter, filterList) => {
      onRemove(index, removedFilter, filterList)
    },
  }

  return (
    <>
      <Loading open={openBackdrop} />
      <div style={{ marginBottom: '20px', position: 'relative' }}>
        <MUIDataTable data={rows} columns={columns} options={options} />
      </div>
    </>
  )
}
