import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import GetAppIcon from '@mui/icons-material/GetApp'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}))

const SimpleFileUploadModel = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  const [changedFiles, setChangedFiles] = React.useState([])
  const [isDisabled, setisDisabled] = React.useState(true)
  const acceptedFileTypes = [
    'application/pdf',
    'image/*',
    // text, word formats
    'text/plain',
    'application/rtf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    // excel formats
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
    // ppt formats
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.oasis.opendocument.presentation',
    // --->archive formats
    // 'application/x-freearc',
    // 'application/x-bzip',
    // 'application/x-bzip2',
    // 'application/gzip',
    // 'application/vnd.rar',
    // 'application/x-tar',
    // 'application/zip',
    // 'application/x-7z-compressed',
  ]
  const setFileName = (event) => {
    if (event.target.files[0]) {
      props.setSelectedFile(event.target.files[0])
      setisDisabled(false)
    } else {
      setisDisabled(true)
    }
  }

  return (
    <Dialog
      open={props.documentRepositoryModelOpen}
      onClose={props.handleDocumentModelClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="xs"
      className="filesmodal"
      sx={{ height: '800px' }} // Adjust the height as needed
    >
      <DialogTitle style={{ fontSize: '1rem' }} id="alert-dialog-title">
        Upload A File
      </DialogTitle>
      <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{
            border: '1px solid black',
            padding: '5px',
            margin: '2px',
            width: 'auto',
          }}
        >
          <Grid
            item
            justifyContent="center"
            style={{ padding: '5px 5px 5px 0px' }}
          >
            <input
              type="file"
              accept={acceptedFileTypes.join(', ')}
              onChange={(event) => {
                setFileName(event)
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              className="primarybtn"
              onClick={props.fileUploadHandler}
              disabled={isDisabled}
            >
              Upload
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={props.handleDocumentModelClose}
              color="primary"
              autoFocus
              className="primarybtntext"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const FileInput = (props) => {
  const acceptedFileTypes = [
    'application/pdf',
    'image/*',
    // text, word formats
    'text/plain',
    'application/rtf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    // excel formats
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
    // ppt formats
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.oasis.opendocument.presentation',
    // archive formats
    'application/x-freearc',
    'application/x-bzip',
    'application/x-bzip2',
    'application/gzip',
    'application/vnd.rar',
    'application/x-tar',
    'application/zip',
    'application/x-7z-compressed',
  ]
  const getFileName = (review_id, fileName) => {
    return (
      window.location.origin +
      '/static/reviews/REVIEW_' +
      review_id +
      '/' +
      fileName
    )
  }

  return (
    <>
      <input
        type="file"
        accept={acceptedFileTypes.join(', ')}
        onChange={(event) => {
          props.setFileName(props.artifact_name, event.target.files[0])
        }}
      />
    </>
  )
}
export default SimpleFileUploadModel
