import { makeStyles } from '@mui/styles'
import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  marqueeContainer: {
    position: 'relative',
    top: '20px',
    overflow: 'hidden',
    backgroundColor: '#f0f0f0', // Background color for the ribbon
    paddingBottom: '5px', // Adjust the padding to control the space between the text and the ribbon
    width: '100%', // Ensure the container spans the full width
  },
  marquee: {
    whiteSpace: 'nowrap',
    fontSize: '1.25rem', // Adjust the font size as needed
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    width: '100%', // Ensure the marquee spans the full width conditional--->
    animation: '$marquee 20s linear infinite', // Increased the duration for a slower speed
  },
  marqueesmall: {
    whiteSpace: 'nowrap',
    fontSize: '1.25rem', // Adjust the font size as needed
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    display: 'inline-block',
    width: 'auto', // Ensure the marquee spans the full width conditional--->
    animation: '$marquee 20s linear infinite', // Increased the duration for a slower speed
  },
  '@keyframes marquee': {
    '0%': {
      transform: 'translateX(100%)',
    },
    '100%': {
      transform: 'translateX(-100%)',
    },
  },
}))

const Marquee = ({ text }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isBetweenSmAndMd = window.matchMedia('(max-width: 784px)').matches

  return (
    <div className={classes.marqueeContainer}>
      <div
        className={
          isSmallScreen || isBetweenSmAndMd
            ? classes.marqueesmall
            : classes.marquee
        }
      >
        <span>{text}</span>
      </div>
      <div className={classes.ribbon} />
    </div>
  )
}

export default Marquee
