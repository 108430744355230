import API from '../Common/api'

export const getCsatData = async (data) => {
  try {
    const response = await API.get(
      `/csat/getCsatData?role=${data?.role}&plemail=${data?.plemail}&adhemail=${data?.adhemail}&verticals=${data.vertical}&emp_id=${data.emp_id} `
    )
    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching csat records!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
export const saveCsat = async (data) => {
  if (data) {
    try {
      const response = await API.post(`/csat/saveCsatStakeholder`, data)

      if (response.status === 200 && response.data) {
        return {
          success: true,
          data: response.data,
          error: null,
        }
      } else {
        return {
          success: false,
          data: null,
          error: {
            message: 'Something went wrong while fetching csat records!',
          },
        }
      }
    } catch (error) {
      return {
        success: false,
        data: null,
        error: error,
      }
    }
  }
}

export const updateCsat = async (data) => {
  if (data) {
    try {
      const response = await API.post(`/csat/updateCsatStakeholder`, data)

      if (response.status === 200 && response.data) {
        return {
          success: true,
          data: response.data,
          error: null,
        }
      } else {
        return {
          success: false,
          data: null,
          error: {
            message: 'Something went wrong while fetching csat records!',
          },
        }
      }
    } catch (error) {
      return {
        success: false,
        data: null,
        error: error,
      }
    }
  }
}

export const removeExcludedRow = async (data) => {
  if (data) {
    try {
      const response = await API.post(`/csat/removeExcludedRowformCsat`, data)

      if (response.status === 200 && response.data) {
        return {
          success: true,
          data: response.data,
          error: null,
        }
      } else {
        return {
          success: false,
          data: null,
          error: {
            message: 'Something went wrong while fetching csat records!',
          },
        }
      }
    } catch (error) {
      return {
        success: false,
        data: null,
        error: error,
      }
    }
  }
}

export const getMetaDataonAccount = async (account) => {
  try {
    const response = await API.get(
      `/csat/getMetaDataonAccount?account=${encodeURIComponent(account)}`
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching csat records!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
export const getMetaDataonProject = async (project) => {
  try {
    const response = await API.get(
      `/csat/getMetaDataonProject?project=${encodeURIComponent(project)}`
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching csat records!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getCsatHistoricalData = async (data) => {
  try {
    const response = await API.post(`/csat/getCsatHistoricalData`, data)

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching csat records!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getCsatAdminData = async (data) => {
  try {
    const response = await API.get(
      `csat/getCsatAdminData?role=${data.role}&timePeriod=${data.timePeriod}`
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching csat records!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getCsatReviwerData = async (data) => {
  try {
    const response = await API.get(
      `csat/getCsatReviewerData?role=${data.role}&timePeriod=${data.timePeriod}&vertical_ids=${data.verticalId}`
    )

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching csat records!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getCsatByProject = async (data) => {
  const queryString = Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
  try {
    const response = await API.get(`/csat/getCsatByProject?${queryString}`)

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching csat records!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
export const getAllMetaData = async (data) => {
  try {
    const response = await API.get(
      `/csat/getAllMetaData?role=${data?.role}&plemail=${data?.plemail}&adhemail=${data?.adhemail}&verticals=${data.vertical}&emp_id=${data.emp_id}`
    )
    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching csat records!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}