import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Config from '../../../../Config'
import { fetchEltViewInprogressReviewDetails } from '../../../../redux/reducer/complianceSlice'
import ReviewTable from './ReviewTable'
import _ from 'lodash'
import { Grid } from '@mui/material'
import FilterComponent from '../../../Common/FilterComponent'
function InProgressReviews() {
  const dispatch = useDispatch()
  const {
    eltViewInprogressReviewsDetails,
    isFetchEltViewInprogressReviewsDetailsLoading,
  } = useSelector((state) => state.compliance)
  const [filter, setfilter] = useState({})
  const [responseBackup, setresponseBackup] = useState([])
  const { userDetails } = useSelector((state) => state.user)
  let clientId =
    userDetails?.client_ids &&
    userDetails?.client_ids !== '' &&
    userDetails?.client_ids !== null &&
    userDetails?.client_ids.length > 0
      ? userDetails.client_ids.map((client) => client.client_id).join(',')
      : ''
  let verticalId = userDetails?.vertical_ids?.verticalIds
  const data = {
    verticalId: verticalId,
    clientId: clientId,
    status: 'In-Progress',
  }

  useEffect(() => {
    dispatch(fetchEltViewInprogressReviewDetails(data))
  }, [dispatch])

  let eltReviews = _.uniqBy(eltViewInprogressReviewsDetails, 'review_id')

  return (
    <ReviewTable
      appliedfilters={filter}
      data={eltReviews}
      isLoading={isFetchEltViewInprogressReviewsDetailsLoading}
    />
  )
}

export default InProgressReviews
