import React, { useState, useEffect } from 'react'
import PowerBIReport from './PowerBIReport'
import { Container, AppBar, Tabs, Tab } from '@mui/material'
import { TabPanel, a11yProps } from '../../Common/TabPanel'
import API from '../../../Common/api'

const Reports = () => {
  const [value, setValue] = useState(0)
  const [accessToken, setAccessToken] = useState()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  useEffect(() => {
    API.post('signIn/getPowerBiAccessToken').then((result) => {
      // dispatch(updatePowerbiAccessToken(result.data.access_token)) --> can update the react state.
      setAccessToken(result.data.access_token)
    })
  }, [])

  const reportId = '20c50140-2327-4ee1-9483-62cfdcfac75d' // Replace with your report ID
  const embedUrl =
    'https://app.powerbi.com/reportEmbed?reportId=20c50140-2327-4ee1-9483-62cfdcfac75d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUVBU1QtQVNJQS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d'

  return (
    <>
      <Container maxWidth="xxl">
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
          >
            <Tab label="DASHBOARD" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <PowerBIReport
            embedUrl={embedUrl}
            accessToken={accessToken}
            reportId={reportId}
          />
        </TabPanel>
      </Container>
    </>
  )
}

export default Reports
