import API from '../Common/api'
import moment from 'moment'
const today = moment()
const currentYear = today.year()

export const getprHealthRecords = async () => {
    try {
        const response = await API.get('/project-health/getAllPrHealthRecords')

        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
                error: null,
            }
        } else {
            return {
                success: false,
                data: null,
                error: { message: 'Something went wrong while fetching project health records!' },
            }
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error,
        }
    }
}

export const getFilterdata = async () => {
    try {
        const response = await API.get('/project-health/filterData')

        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
                error: null,
            }
        } else {
            return {
                success: false,
                data: null,
                error: { message: 'Something went wrong while fetching project health filter data!' },
            }
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error,
        }
    }
}

export const getByConditions = async (filter) => {
    try {
        const response = await API.post('/project-health/getByConditions', filter)

        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
                error: null,
            }
        } else {
            return {
                success: false,
                data: null,
                error: { message: 'Something went wrong while fetching project health filter data!' },
            }
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error,
        }
    }
}

export const getMOMdata = async (data) => {
    try {
        const response = await API.post('/project-health/fetchMoMdata', data)

        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
                error: null,
            }
        } else {
            return {
                success: false,
                data: null,
                error: { message: 'Something went wrong while getting project health MOM data!' },
            }
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error,
        }
    }
}

export const putMoMData = async (data) => {
    try {
        const response = await API.put('/project-health/putMoMdata', data)

        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
                error: null,
            }
        } else {
            return {
                success: false,
                data: null,
                error: { message: 'Something went wrong while saving project health MOM data!' },
            }
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error,
        }
    }
}

export const getCurrentWeekMetaData = async (filter) => {
    try {
        const response = await API.post('/project-health/currentWeekDataFetch', filter)

        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
                error: null,
            }
        } else {
            return {
                success: false,
                data: null,
                error: { message: 'Something went wrong while fetching project health filter data!' },
            }
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error,
        }
    }
}

export const saveDataFinal = async (params) => {
    try {
        const response = await API.post('/project-health/saveAllData', params)
        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
                error: null,
            }
        } else {
            return {
                success: false,
                data: null,
                error: { message: 'Something went wrong while fetching project health filter data!' },
            }
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error,
        }
    }
}

export const getProjectListData = async (data) => {
    try {
        const response = await API.post('/project-health/getProjectList', data)
        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
                error: null,
            }
        } else {
            return {
                success: false,
                data: null,
                error: { message: 'Something went wrong while fetching project health filter data!' },
            }
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error,
        }
    }
}


export const getAllWeeksProjectHealth = async (data) => {
    try {
        const response = await API.post(`project-health/getAllWeeksProjectHealthData`,data)

        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
                error: null,
            }
        } else {
            return {
                success: false,
                data: null,
                error: { message: 'Something went wrong while fetching project health records!' },
            }
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error,
        }
    }
}

export const getUpdatedBy = async (data) => {
    try {
        const response = await API.post('/project-health/getUpdatedBy',data)

        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
                error: null,
            }
        } else {
            return {
                success: false,
                data: null,
                error: { message: 'Something went wrong while fetching updated by data!' },
            }
        }
    } catch (error) {
        return {
            success: false,
            data: null,
            error: error,
        }
    }
}