import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import React from 'react'
import './reports.css'

const PowerBIReport = ({ embedUrl, accessToken, reportId }) => {
  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report',
        id: reportId,
        embedUrl: embedUrl,
        accessToken: accessToken,
        tokenType: models.TokenType.Aad,
        settings: {
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: true,
              position: models.PageNavigationPosition.Top, // Position the navigation pane at the top
            },
          },
          background: models.BackgroundType.Transparent,
          layoutType: models.LayoutType.Custom, // Ensure custom layout for responsiveness
          customLayout: {
            displayOption: models.DisplayOption.FitToWidth, // Adjust to fit width
          },
        },
      }}
      eventHandlers={
        new Map([
          ['loaded', () => console.log('Report loaded')],
          ['rendered', () => console.log('Report rendered')],
          ['error', (event) => console.log(event.detail.message)],
        ])
      }
      getEmbeddedComponent={(embeddedReport) => {
        window.Report = embeddedReport
      }}
      cssClassName={'Embed-container'}
    />
  )
}

export default PowerBIReport
