import { Button } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { makeStyles } from '@mui/styles'
import React from 'react'
import background from '../../Assets/Images/login_background.png'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}))

function DevUserLogin(props) {
  const classes = useStyles()
  const users = [
    // {
    //   name: 'Neha Singh',
    //   email: 'neha.singh@tredence.com',
    // },
    {
      name: 'Payal Jain',
      email: 'payal.jain@tredence.com',
    },
    {
      name: 'Indu Prasad',
      email: 'indu.prasad@tredence.com',
    },
    // {
    //   name: 'Syed Haffiz',
    //   email: 'syed.haffiz@tredence.com',
    // },
    {
      name: 'Pavan Brahmaji',
      email: 'pavan.brahmaji@tredence.com',
    },
    {
      name: 'Ranga Y',
      email: 'ranga.y@tredence.com',
    },
    {
      name: 'Soumya',
      email: 'soumya.huded@tredence.com',
    },
    {
      name: 'Sunanda',
      email: 'sunanda.konduru@tredence.com',
    },
  ]

  return (
    <div className={classes.root}>
      <List component="nav">
        {users.map((user, index) => (
          <div key={user.email}>
            <ListItem button onClick={() => props.handleLogin(user.email)}>
              <ListItemText primary={user.name} secondary={user.email} />
            </ListItem>
            {index !== users.length - 1 && <Divider component="li" />}
          </div>
        ))}
      </List>
    </div>
  )
}

function Login(props) {
  const [signUpError] = React.useState('')

  return (
    <div className="bgarea" style={{ backgroundImage: `url(${background})` }}>
      <div className="loginarea">
        {signUpError ? (
          <span style={{ color: 'white' }}>{signUpError}</span>
        ) : null}
        {process.env.NODE_ENV === 'development' ? (
          <DevUserLogin handleLogin={props.handleLogin} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            onClick={props.handleLogin}
            disabled={props.isLoading}
          >
            {props.isLoading ? 'Logging in...' : 'Login Here!'}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Login
