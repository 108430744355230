import React from 'react'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

const ACKNOWLEDGE = (props) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const [alertDetails, setAlertDetails] = React.useState({
    message: '',
    severity: 'info',
  })

  const handleAlertMessage = (message, severity) => {
    setAlertDetails({
      ...alertDetails,
      message: message,
      severity: severity,
    })
    handleSnackbarOpen()
  }

  React.useEffect(() => {
    if (props.message && props.severity)
      handleAlertMessage(props.message, props.severity)
  }, [props.message, props.severity])

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={2000}
      onClose={handleSnackbarClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={alertDetails.severity}
        variant="filled"
      >
        {alertDetails.message}
      </Alert>
    </Snackbar>
  )
}

export default ACKNOWLEDGE
