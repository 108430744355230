import { CancelOutlined } from '@mui/icons-material'
import PublishIcon from '@mui/icons-material/Publish'
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Loading from '../../../../Common/Loading'
import SimpleFileUploadModel from '../../../../Common/SimpleFileUpload'
import {
  uploadArtifactsToFinalSharePoint,
  uploadFileToSharePoint,
} from '../../../../GraphService'
import { saveComplianceArtifacts } from '../../../../services/complianceService'
import {
  getMetaDataonAccount,
  getMetaDataonProject,
} from '../../../../services/csatService'

const ArtifactsForm = (props) => {
  const { enqueueSnackbar: notify } = useSnackbar()
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [vertical, setVertical] = useState('')
  const [verticalId, setVerticalId] = useState(null)
  const [account, setAccount] = useState()
  const [accountId, setAccountId] = useState()
  const [accountList, setAccountList] = useState(props?.accounts)
  const [projectList, setProjectList] = useState(props?.projects)
  const [project, setProject] = useState('')
  const [projectId, setProjectId] = useState(null)
  const [adhEmail, setAdhEmail] = useState()
  const [docName, setDocName] = useState(null)
  const [checklists, setChecklists] = useState(props?.checklists)
  const [docDescription, setDocDescription] = useState(null)
  const [existingArtifactsForm, setExistingArtifactsForm] = useState([])
  const [existingArtifactsFormOpen, setExistingArtifactsFormOpen] =
    useState(false)
  const [documentRepositoryModelOpen, setDocumentRepositoryModelOpen] =
    React.useState(false)
  const [selectedFile, setSelectedFile] = React.useState()
  const [accountGeneric, setAccountGeneric] = React.useState(false)

  const { csatRevieweeInitialData, csatFilterData } = useSelector(
    (state) => state?.filter
  )
  const accessToken = useSelector((state) => state.user.accessToken)
  const dummyaccessToken = useSelector((state) => state.user.dummyaccessToken)
  const { userDetails } = useSelector((state) => state.user)

  const [workstream, setWorkStream] = useState(null)
  const [workstreamId, setWorkStreamId] = useState(null)
  const [existingworkstreams, setExistingWorkStreams] = useState([])
  const [uniqueId, setUniqueId] = useState(null)

  const handleDocumentModelOpen = () => {
    setDocumentRepositoryModelOpen(true)
  }
  const handleDocumentModelClose = () => {
    setDocumentRepositoryModelOpen(false)
  }
  const handleCancelFile = () => {
    setSelectedFile()
  }

  const fileUploadHandler = () => {
    handleDocumentModelClose()
  }

  const columns = useMemo(
    () => [
      {
        label: 'Account',
        name: 'account',
        options: {
          searchable: true,
          filterList: csatFilterData?.account,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Project',
        name: 'project',
        options: {
          searchable: true,
          filterList: csatFilterData?.project,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Doc Name',
        name: 'docName',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Doc Description',
        name: 'docDescription',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
    ],
    []
  )

  const options = {
    responsive: 'standard',
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: 'No nominations to display!',
      },
    },
    search: false,
    print: false,
    filter: false,
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 25, 50],
    rowsPerPage: 5,
    elevation: 2,
    download: false,
  }

  useEffect(() => {
    setAccountList(props?.accounts)
    setChecklists(props?.checklists)
  }, [props?.accounts, props?.checklists])

  const handleAccountChange = (event, val) => {
    if (val) {
      setAccount(val)
      setProject('')
      getProjectsforAccount(val)
    }
  }
  const handleCheckboxAccountGeneric = (e) => {
    if (e?.target?.checked) {
      setProject('Generic')
    } else {
      setProject()
    }
    setProjectId(null)
    setWorkStream('')
    setWorkStreamId(null)
    setAccountGeneric(e?.target?.checked)
  }

  const getProjectsforAccount = (account) => {
    setOpenBackdrop(true)
    getMetaDataonAccount(account)
      .then((res) => {
        if (res.success) {
          setVertical(res.data.result[0]?.vertical)
          setVerticalId(res.data.result[0]?.verticalId)
          setAccountId(res.data.result[0]?.accountId)
          setAdhEmail(res.data.result[0]?.adhEmail)
          const UniqueProjects = _.uniq(_.map(res.data.result, 'project'))
          setProjectList(UniqueProjects)
          setOpenBackdrop(false)
        }
      })
      .catch((error) => {
        console.error('Error fetching account data:', error)
        setOpenBackdrop(false)
      })
  }
  const handleProjectChange = (event, val) => {
    if (val) {
      setProject(val)
      setOpenBackdrop(true)
      getMetaDataonProject(val)
        .then((res) => {
          if (res.success) {
            setProjectId(res.data.result[0]?.projectId)
          }
          setAdhEmail(res.data.result[0]?.adhEmail)
          setUniqueId(res.data.result[0]?.unique_id)
          const extractedworkstreams = res.data.result
            ?.filter(({ workstream }) => !workstream == false)
            ?.map(({ workstream, workstreamId, unique_id }) => ({
              workstream,
              workstreamId,
              unique_id,
            }))
          if (extractedworkstreams.length == 1) {
            setWorkStream(extractedworkstreams[0].workstream)
            setWorkStreamId(extractedworkstreams[0].workstreamId)
            setUniqueId(extractedworkstreams[0].unique_id)
          }
          setExistingWorkStreams(extractedworkstreams)
          setOpenBackdrop(false)
        })
        .catch((error) => {
          console.error('Error fetching project data:', error)
          setOpenBackdrop(false)
        })
    }
  }

  const SaveArtifacts = () => {
    const filledData = {
      salesforce_opportunity_id: projectId,
      vertical: vertical,
      vertical_id: verticalId,
      account: account,
      account_id: accountId,
      project: project,
      unique_id: uniqueId,
      workstream: workstream,
      workstream_id: workstreamId,
      Doc_name: docName,
      Doc_description: docDescription,
      file_name: selectedFile?.name,
      status: 'Pending',
      updated_by: userDetails?.email_id,
      updated_at: new Date(),
      approved_by: adhEmail,
    }
    const isStringInArray = checklists.some(
      (item) => item.toLowerCase() === docName.toLowerCase()
    )
    if (!isStringInArray) {
      filledData.other_docs = 'Yes'
    }
    if (accountGeneric) {
      filledData.account_generic = 'Yes'
      filledData.project = 'Generic'
    }
    if (userDetails.role === 'Reviewer' /*|| userDetails.role === 'PMO'*/) {
      filledData.status = 'Auto-Approved'
      filledData.approved_by = userDetails.email_id
      filledData.approved_on = new Date()
    }
    setOpenBackdrop(true)
    saveComplianceArtifacts(filledData)
      .then((res) => {
        if (res.success) {
          notify(`Data saved successfully!`, {
            variant: 'success',
          })
          Promise.all([props?.getArtifacts()])
            .then(() => {
              setOpenBackdrop(false)
              handleClose()
            })
            .catch((error) => {
              console.error('Error fetching data:', error)
              handleClose()
              setOpenBackdrop(false)
            })
        }
      })
      .catch((error) => {
        notify(`Error while saving the artifacts, reach out to the DX team.`, {
          variant: 'error',
        })
        console.error('Error while saving Artifacts:', error)
        setOpenBackdrop(false)
      })
  }

  const handleSave = (event) => {
    event.preventDefault()

    let uploadPromise
    if (!selectedFile) {
      notify(`Please uplaod a file!`, {
        variant: 'info',
      })
    } else {
      if (!docName) {
        notify(`Please add Doc Name!`, {
          variant: 'info',
        })
      } else {
        let extension
        if (workstreamId) {
          extension = docName + '_' + workstreamId
        } else {
          extension = docName
        }
        setOpenBackdrop(true)
        if (userDetails.role === 'Reviewee' || userDetails.role === 'Admin') {
          if (accountGeneric) {
            uploadPromise = uploadFileToSharePoint(
              selectedFile,
              vertical,
              account,
              'Common Artifacts',
              docName,
              accessToken
            )
          } else {
            uploadPromise = uploadFileToSharePoint(
              selectedFile,
              vertical,
              account,
              project,
              extension,
              accessToken
            )
          }
        } else {
          //  uploadFileToFinalSharePoint doesnot have docName/checklist arg check and add accordingly.
          if (accountGeneric) {
            uploadPromise = uploadArtifactsToFinalSharePoint(
              selectedFile,
              vertical,
              account,
              'Common Artifacts',
              docName,
              dummyaccessToken
            )
          } else {
            uploadPromise = uploadArtifactsToFinalSharePoint(
              selectedFile,
              vertical,
              account,
              project,
              extension,
              dummyaccessToken
            )
          }
        }

        // Wait for all upload promises to resolve
        uploadPromise
          .then((result) => {
            SaveArtifacts()
            if (result) {
              console.log(`Upload ${selectedFile?.name} succeeded:`, result)
              notify(`${selectedFile?.name} uploaded successfully!`, {
                variant: 'success',
              })
            } else {
              // Handle failed uploads
              console.error(`Upload ${selectedFile?.name} failed`)
              notify(
                `Error in File Upload for ${selectedFile?.name}, please re-upload`,
                {
                  variant: 'error',
                }
              )
            }
            setOpenBackdrop(false)
          })
          .catch((error) => {
            // Handle any errors that occurred during uploads
            console.error('Error during file uploads:', error)
            if (error === 'Access denied') {
              notify(
                `You don't have access to upload the docs. Please reach out to DX team.`,
                {
                  variant: 'error',
                }
              )
            } else {
              notify('Error during file upload, reach out to the DX team.', {
                variant: 'error',
              })
            }
            setOpenBackdrop(false)
          })
      }
    }
  }

  const handleInputChange = (event, newInputValue) => {
    // Capture the new value entered by the user
    setDocName(newInputValue)
  }

  const handleClose = () => {
    props.onClose()
    setAccount('')
    setAccountId(null)
    setProject('')
    setProjectId(null)
    setProjectList([])
    setDocName('')
    setDocDescription(null)
    setSelectedFile()
    setWorkStream(null)
    setWorkStreamId(null)
    setExistingWorkStreams([])
    setUniqueId(null)
    setVertical('')
    setVerticalId(null)
    setAccountGeneric(false)
    setExistingArtifactsForm([])
    setAdhEmail()
  }

  const handleCloseExistingArtifactsForm = () => {
    setExistingArtifactsFormOpen(false)
  }

  return (
    <>
      <Dialog maxWidth="sm" open={props.open} onClose={handleClose}>
        <Loading open={openBackdrop} />
        <DialogTitle>Add Artifact</DialogTitle>
        <DialogContent>
          {/* <br></br> */}
          <form onSubmit={handleSave}>
            <Grid align="center" container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  control={
                    <Checkbox
                      checked={accountGeneric}
                      onChange={handleCheckboxAccountGeneric}
                      color="primary"
                    />
                  }
                  label="Account Generic"
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={accountList}
                  value={account}
                  onChange={handleAccountChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Account"
                      variant="outlined"
                      required
                      error={Boolean(!account)}
                    />
                  )}
                />
              </Grid>
              {!accountGeneric && (
                <>
                  <Grid item xs={12}>
                    <Tooltip
                      title={
                        !account && project?.length === 0
                          ? 'Please Choose Account'
                          : ''
                      }
                    >
                      <Autocomplete
                        options={projectList}
                        value={project}
                        onChange={handleProjectChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Project"
                            variant="outlined"
                            required
                            error={Boolean(!project)}
                          />
                        )}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip
                      title={
                        existingworkstreams?.length === 0
                          ? 'No workstreams'
                          : ''
                      }
                    >
                      <Autocomplete
                        disabled={
                          existingworkstreams?.length > 0 ? false : true
                        }
                        options={existingworkstreams}
                        getOptionLabel={(option) =>
                          option?.workstream ? option?.workstream : ''
                        }
                        value={
                          props?.workstreams?.find(
                            (option) => option?.workstream === workstream
                          ) || ''
                        }
                        onChange={(event, newValue) => {
                          setWorkStream(newValue?.workstream)
                          setWorkStreamId(newValue?.workstreamId)
                          setUniqueId(newValue?.unique_id)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="medium"
                            label="Workstream"
                            variant="outlined"
                          />
                        )}
                      />
                    </Tooltip>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                {/* <Tooltip> */}
                <Autocomplete
                  freeSolo={true}
                  disabled={checklists?.length > 0 ? false : true}
                  options={checklists}
                  onInputChange={handleInputChange}
                  value={docName}
                  onChange={(event, newValue) => {
                    setDocName(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      label="Doc Name"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  //   margin="normal"
                  label="Doc Description"
                  value={docDescription}
                  required
                  error={Boolean(!docDescription)}
                  onChange={(e) => setDocDescription(e.target.value)}
                  aria-label="minimum height"
                  minRows={2}
                  placeholder="Doc Description"
                  style={{
                    width: '100%',
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  fontSize: '16px',
                }}
              >
                <div style={{ marginTop: '8px' }}>Upload a file:</div>
                <Button onClick={handleDocumentModelOpen}>
                  <PublishIcon color="primary" fontSize="medium" />
                </Button>
                {selectedFile?.name ? (
                  // <>
                  <Stack
                    direction="row"
                    spacing={0}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div style={{ fontSize: '12px' }}>{selectedFile?.name}</div>
                    <Tooltip title="Erase the file">
                      <Button
                        onClick={handleCancelFile}
                        style={{
                          borderRadius: '50%', // Make the button circular
                          backgroundColor: '#yourColor', // Set the background color of the button
                          width: '20px', // Set the width of the button
                          height: '20px', // Set the height of the button
                          padding: '0', // Remove padding to make the button size match the width and height
                        }}
                      >
                        <CancelOutlined
                          style={{
                            fontSize: '15px',
                          }}
                        ></CancelOutlined>
                      </Button>
                    </Tooltip>
                  </Stack>
                ) : (
                  <></>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                spacing={5}
                display={'flex'}
                alignContent={'flex-end'}
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <DialogActions>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="primary"
                    autoFocus
                    className="primarybtntext"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <SimpleFileUploadModel
        documentRepositoryModelOpen={documentRepositoryModelOpen}
        handleDocumentModelClose={handleDocumentModelClose}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        fileUploadHandler={fileUploadHandler}
      />
    </>
  )
}

export default ArtifactsForm
