import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Loading from '../../../../Common/Loading'

import { Folder } from '@mui/icons-material'
import { updateComplianceArtifacts } from '../../../../services/complianceService'
import { deleteFilesFromChecklistFolder } from '../../../../GraphService'

const DeleteArtifactsForm = (props) => {
  const { enqueueSnackbar: notify } = useSnackbar()
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [account, setAccount] = useState(props?.editRow?.account)
  const [project, setProject] = useState(props?.editRow?.project)
  const [docName, setDocName] = useState(props?.editRow?.Doc_name)
  const [docDescription, setDocDescription] = useState(
    props?.editRow?.Doc_description
  )
  const [selectedFile, setSelectedFile] = React.useState(
    props?.editRow?.file_name
  )
  const [accountGeneric, setAccountGeneric] = React.useState(
    props?.editRow?.account_generic == 'Yes' ? true : false
  )

  const accessToken = useSelector((state) => state.user.accessToken)
  const { userDetails } = useSelector((state) => state.user)

  const [workstream, setWorkStream] = useState(props?.editRow?.workstream)
  const [workstreamId, setWorkStreamId] = useState(
    props?.editRow?.workstream_id
  )

  const [uniqueId, setUniqueId] = useState(props?.editRow?.unique_id)

  useEffect(() => {
    setAccount(props?.editRow?.account)
    setProject(props?.editRow?.project)
    setWorkStream(props?.editRow?.workstream)
    setWorkStreamId(props?.editRow?.workstream_id)
    setDocName(props?.editRow?.Doc_name)
    setDocDescription(props?.editRow?.Doc_description)
    setSelectedFile(props?.editRow?.file_name)
    setAccountGeneric(props?.editRow?.account_generic == 'Yes' ? true : false)
  }, [props?.editRow])

  // Delete API----------------->
  const DeleteArtifacts = () => {
    let deletingfilenames = []
    const fileExtension = selectedFile?.split('.').pop()
    let extension
    if (props?.editRow?.account_generic) {
      extension = docName
    } else {
      extension = docName + '_' + workstreamId
    }
    const newFileName = `${selectedFile?.replace(
      `.${fileExtension}`,
      `_${extension}.${fileExtension}`
    )}`
    deletingfilenames?.push(newFileName)
    const filledData = {
      rowId: props?.editRow?.Doc_Id,
      data: {
        status: 'Deleted',
        updated_by: userDetails?.email_id,
        updated_at: new Date(),
      },
    }
    setOpenBackdrop(true)
    updateComplianceArtifacts(filledData)
      .then((res) => {
        let projectname
        if (props?.editRow?.project === 'Generic') {
          projectname = 'Common Artifacts'
        } else {
          projectname = props?.editRow?.project
        }
        deleteFilesFromChecklistFolder(
          props?.editRow?.vertical,
          props?.editRow?.account,
          projectname,
          deletingfilenames,
          accessToken
        )
          .then((response) => {
            console.log('Files deleted successfully')
          })
          .catch((error) => {
            console.error('Error deleting files:', error)
          })
        if (res.success) {
          notify(`Data Deleted Successfully !`, {
            variant: 'success',
          })
          Promise.all([props?.getArtifacts()])
            .then(() => {
              setOpenBackdrop(false)
              handleClose()
            })
            .catch((error) => {
              console.error('Error fetching data:', error)
              handleClose()
              setOpenBackdrop(false)
            })
        }
      })
      .catch((error) => {
        notify(
          `Error while deleting the artifacts, reach out to the DX team.`,
          {
            variant: 'error',
          }
        )
        console.error('Error while deleting Artifacts:', error)
        setOpenBackdrop(false)
      })
  }

  const handleDelete = (event) => {
    event.preventDefault()
    DeleteArtifacts()
  }

  const handleClose = () => {
    props?.onClose()
    setAccount('')
    setProject('')
    setDocName('')
    setDocDescription(null)
    setSelectedFile([])
    setWorkStream(null)
    setUniqueId(null)
    setAccountGeneric(false)
  }

  return (
    <>
      <Dialog maxWidth="sm" open={props?.open} onClose={handleClose}>
        <Loading open={openBackdrop} />
        <DialogTitle>You will be Deleting the below Artifact</DialogTitle>
        <DialogContent>
          <Grid align="center" container spacing={2}>
            {/* <Grid item xs={12}>
              <FormControlLabel
                disabled
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                control={<Checkbox checked={accountGeneric} color="primary" />}
                label="Account Generic"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Account" value={account} disabled fullWidth />
            </Grid>
            {!props?.editRow?.account_generic && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label="Project"
                    value={project}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Tooltip
                    title={!props?.editRow?.workstream ? 'No workstreams' : ''}
                  >
                    <TextField
                      label="Workstream"
                      value={workstream}
                      disabled
                      fullWidth
                    />
                  </Tooltip>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField label="Doc Name" value={docName} disabled fullWidth />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Doc Description"
                value={docDescription}
                disabled
                aria-label="minimum height"
                minRows={2}
                placeholder="Doc Description"
                style={{
                  width: '100%',
                }}
              />
            </Grid> */}
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: '16px',
              }}
            >
              <div style={{ marginTop: '8px' }}>Uploaded file:</div>
              {/* <Tooltip title="Check the file"> */}
              <Button disabled>
                <Folder color="primary" fontSize="medium" />
              </Button>
              {/* </Tooltip> */}
              {props?.editRow?.file_name ? (
                <Stack
                  direction="row"
                  spacing={0}
                  justifyContent="center"
                  alignItems="center"
                >
                  <div style={{ fontSize: '12px' }}>
                    {props?.editRow?.file_name}
                  </div>
                </Stack>
              ) : (
                <></>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              spacing={5}
              display={'flex'}
              alignContent={'flex-end'}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
                <Button
                  onClick={handleClose}
                  color="primary"
                  autoFocus
                  className="primarybtntext"
                >
                  Close
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DeleteArtifactsForm
