import { Edit } from '@mui/icons-material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Popper,
  Stack,
  Switch,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import Loading from '../../../Common/Loading'
import {
  updateCsatFilterData,
  updateCsatHistoricalFilterData,
} from '../../../redux/reducer/projectHealthFilterSlice'
import {
  removeExcludedRow,
  saveCsat,
  updateCsat,
} from '../../../services/csatService'
import FilterComponent from '../../Common/multiSelectCustomFilter'
import CsatBulkUpload from './CsatBulkUpload'
import CsatOptions from './csatOptions'
import FormDialog from './Form'
import CustomToolbar from './CustomToolBar'

const ToolTipTitle = () => {
  return (
    <ul>
      To add this nomination for current cycle, update the mandatory fields
    </ul>
  )
}

const useStyles = makeStyles({
  option: {
    fontSize: '12px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  toolbarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // marginTop: '-42px'
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
  },
  searchInput: {
    fontSize: '14.5px',
    marginLeft: '8px',
    marginRight: '8px',
    width: '200px',
    borderBottom: '2px solid #F58220',
  },
  searchIcon: {
    cursor: 'pointer',
    color: '#F58220',
  },
})
export default function CsatView(props) {
  const [rows, setRows] = useState(props?.rows)
  const [excludedrows, setExcludedRows] = useState([])
  const [nonExcludedrows, setNonExcludedRows] = useState([])
  const [openForm, setOpenForm] = useState(false)
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const [editForm, setEditForm] = useState(false)
  const [editRowIndex, setEditRowIndex] = useState(null)
  const { data: adminData } = useSelector((state) => state.admin)
  const { userDetails } = useSelector((state) => state.user)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const {
    csatRevieweeInitialData,
    csatFilterData,
    csatHistoricalFilterData,
    csatHistoricalInitialData,
  } = useSelector((state) => state.filter)
  const dispatch = useDispatch()
  const [openExclude, setOpenExclude] = React.useState(false)
  const { enqueueSnackbar: notify } = useSnackbar()
  const [excludeFilter, setExcludeFilter] = useState(false)
  const [uploadOpen, setUploadOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isBetweenSmAndMd = window.matchMedia('(max-width: 600px)').matches
  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleFormOpen = () => {
    setOpenForm(true)
  }

  const handleFormClose = () => {
    setOpenForm(false)
    setEditForm(false)
    setEditRowIndex(null)
  }

  const onEdit = (index) => () => {
    setEditRowIndex(index)
    setEditForm(true)
    handleFormOpen()
  }

  const onExclude = (index) => () => {
    setEditRowIndex(index)
    handleOpenExclude()
  }

  const onInclude = (index) => () => {
    setEditRowIndex(index)
    handleOpenExclude()
  }

  const handleOpenExclude = () => {
    setOpenExclude(true)
  }
  const saveExclude = () => {
    if (editRow?.csat_id) {
      const filledData = {
        rowId: editRow?.csat_id,
        data: {
          exclude: 'yes',
          updated_by: userDetails?.employee_id,
          updatedAt: new Date(),
        },
      }
      setOpenBackdrop(true)
      updateCsat(filledData)
        .then((res) => {
          if (res.success) {
            notify(`Data Updated Successfully !`, {
              variant: 'success',
            })
            Promise.all([props?.getCsat(), props?.getCsatHistoricalData()])
              .then(() => {
                setOpenBackdrop(false)
              })
              .catch((error) => {
                console.error('Error fetching data:', error)
                setOpenBackdrop(false)
              })
          }
        })
        .catch((error) => {
          notify(`Couldn't Update CSAT!`, {
            variant: 'error',
          })
          console.error('Error while updating csat:', error)
          setOpenBackdrop(false)
        })
    } else {
      const filledData = {
        salesforce_opportunity_id: editRow?.salesforce_opportunity_id,
        vertical: editRow?.vertical,
        vertical_id: editRow?.vertical_id,
        account: editRow?.account,
        account_id: editRow?.account_id,
        project: editRow?.project,
        unique_id: editRow?.unique_id,
        workstream: editRow?.workstream,
        workstream_id: editRow?.workstream_id,
        time_period: '2024 H1',
        exclude: 'yes',
        created_by: userDetails?.employee_id,
        createdAt: new Date(),
      }
      setOpenBackdrop(true)
      saveCsat(filledData)
        .then((res) => {
          if (res.success) {
            notify(`Data Saved Successfully !`, {
              variant: 'success',
            })
            Promise.all([props?.getCsat()])
              .then(() => {
                setOpenBackdrop(false)
              })
              .catch((error) => {
                console.error('Error fetching data:', error)
                setOpenBackdrop(false)
              })
          }
        })
        .catch((error) => {
          notify(`Couldn't Save CSAT!`, {
            variant: 'error',
          })
          console.error('Error while saving csat:', error)
          setOpenBackdrop(false)
        })
    }
    handleCloseExclude()
  }

  const handleUploadOpen = () => {
    setUploadOpen(true)
  }
  const saveInclude = () => {
    if (editRow?.csat_id && editRow?.client_email) {
      const filledData = {
        rowId: editRow?.csat_id,
        data: {
          exclude: '',
          updated_by: userDetails?.employee_id,
          updatedAt: new Date(),
        },
      }
      setOpenBackdrop(true)
      updateCsat(filledData)
        .then((res) => {
          if (res.success) {
            notify(`Data Updated Successfully !`, {
              variant: 'success',
            })
            Promise.all([props?.getCsat(), props?.getCsatHistoricalData()])
              .then(() => {
                setOpenBackdrop(false)
              })
              .catch((error) => {
                console.error('Error fetching data:', error)
                setOpenBackdrop(false)
              })
          }
        })
        .catch((error) => {
          notify(`Couldn't Update CSAT!`, {
            variant: 'error',
          })
          console.error('Error while updating csat:', error)
          setOpenBackdrop(false)
        })
    } else {
      const filledData = {
        rowId: editRow?.csat_id,
      }
      setOpenBackdrop(true)
      removeExcludedRow(filledData)
        .then((res) => {
          if (res.success) {
            notify(`Data Saved Successfully !`, {
              variant: 'success',
            })
            Promise.all([props?.getCsat()])
              .then(() => {
                setOpenBackdrop(false)
              })
              .catch((error) => {
                console.error('Error fetching data:', error)
                setOpenBackdrop(false)
              })
          }
        })
        .catch((error) => {
          notify(`Couldn't Save CSAT!`, {
            variant: 'error',
          })
          console.error('Error while saving csat:', error)
          setOpenBackdrop(false)
        })
    }
    handleCloseExclude()
  }
  const handleCloseExclude = () => {
    setOpenExclude(false)
  }

  function onClose() {
    handleFormClose()
  }

  const filtersData = (data, view) => {
    let filteredData = data
    if (view.vertical && view.vertical.length > 0) {
      filteredData = filteredData.filter((item) =>
        view.vertical.includes(item.vertical)
      )
    }
    if (view.account && view.account.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          view.vertical.includes(item.vertical) &&
          view.account.includes(item.account)
      )
    }
    if (view.project && view.project.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          view.vertical.includes(item.vertical) &&
          view.account.includes(item.account) &&
          view.project.includes(item.project)
      )
    }
    if (view.vertical.length === 0 && view.time_period.length > 0) {
      filteredData = filteredData.filter((item) =>
        view.time_period.includes(item.time_period)
      )
    }
    if (
      view.vertical.length > 0 &&
      view.time_period &&
      view.time_period.length > 0
    ) {
      filteredData = filteredData.filter(
        (item) =>
          view.vertical.includes(item.vertical) &&
          view.account.includes(item.account) &&
          view.project.includes(item.project) &&
          view.time_period.includes(item.time_period)
      )
    }
    return filteredData
  }

  useEffect(() => {
    if (
      csatRevieweeInitialData.length > 0 &&
      csatFilterData.vertical &&
      csatFilterData.vertical.length > 0
    ) {
      let filteredData = filtersData(csatRevieweeInitialData, csatFilterData)
      setRows(filteredData)
    } else if (
      csatRevieweeInitialData.length > 0 &&
      csatFilterData.vertical.length === 0
    ) {
      setRows(csatRevieweeInitialData)
    }
  }, [csatFilterData, csatRevieweeInitialData])

  useEffect(() => {
    if (
      (csatHistoricalInitialData.length > 0 &&
        csatHistoricalFilterData.vertical &&
        csatHistoricalFilterData.vertical.length > 0) ||
      (csatHistoricalFilterData.time_period &&
        csatHistoricalFilterData.time_period.length > 0)
    ) {
      let filteredData = filtersData(
        csatHistoricalInitialData,
        csatHistoricalFilterData
      )
      setRows(filteredData)
    } else if (
      csatHistoricalFilterData.vertical.length === 0 ||
      (csatHistoricalFilterData.time_period &&
        csatHistoricalFilterData.time_period.length > 0)
    ) {
      setRows(csatHistoricalInitialData)
    }
  }, [csatHistoricalFilterData, csatHistoricalInitialData])

  useEffect(() => {
    setOpenBackdrop(true)
    setRows(props?.rows)
    const excludedData = props?.rows?.filter((row) => row?.exclude === 'yes')
    const restData = props?.rows?.filter((row) => row?.exclude !== 'yes')
    setExcludedRows(excludedData)
    setNonExcludedRows(restData)
    setOpenBackdrop(false)
  }, [props?.rows])

  const columns = useMemo(() => {
    const baseColumns = [
      {
        label: 'ID',
        name: 'csat_id',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Vertical',
        name: 'vertical',
        options: {
          filterList:
            props?.view == 'current'
              ? csatFilterData?.vertical
              : csatHistoricalFilterData?.vertical,
          display: 'excluded',
        },
      },
      {
        label: 'Account',
        name: 'account',
        options: {
          searchable: true,
          filterList:
            props?.view == 'current'
              ? csatFilterData?.account
              : csatHistoricalFilterData?.account,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Project',
        name: 'project',
        options: {
          searchable: true,

          filterList:
            props?.view == 'current'
              ? csatFilterData?.project
              : csatHistoricalFilterData?.project,

          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },

      {
        label: 'Work Stream',
        name: 'workstream',
        options: {
          sortDescFirst: true,
          // display: 'excluded',
          searchable: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Project Lead',
        name: 'projectLead_emailId',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'CS Email',
        name: 'cs_emailId',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client Name',
        name: 'client_name',
        options: {
          sortDescFirst: true,
          searchable: true,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Client Email',
        name: 'client_email',
        options: {
          display: 'excluded',
        },
      },
      {
        label: 'Client Designation',
        name: 'client_designation',
        options: {
          display: 'excluded',
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Client First Name',
        name: 'client_first_name',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client Last Name',
        name: 'client_last_name',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client Preferred Name',
        name: 'client_prefferred_name',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client Location',
        name: 'client_location',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Client LinkedIn',
        name: 'client_linkedin',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Tenure',
        name: 'client_tenure',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Survey Type',
        name: 'survey_type',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Alternate Client Name',
        name: 'alternate_client_name',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Alternate Client Email',
        name: 'alternate_client_emailId',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Internal Surveyee',
        name: 'internal_surveyee',
        options: {
          sortDescFirst: true,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
          customBodyRender: (value) => {
            return (
              <div>
                {value
                  ?.replace('@tredence.com', '')
                  ?.replace('.', ' ')
                  ?.toUpperCase()}
              </div>
            )
          },
        },
      },
      // {
      //   label: 'Direct Client',
      //   name: 'direct_client',
      //   options: {
      //     display: 'excluded',
      //     searchable: false,k
      //     filter: false,
      //   },
      // },
      {
        label: 'Time Period',
        name: 'time_period',
        options: {
          sortDescFirst: true,
          searchable: true,
          filterList:
            props?.view == 'current'
              ? csatFilterData?.time_period
              : csatHistoricalFilterData?.time_period,

          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },

      {
        label: 'Revenue bucket',
        name: 'client_revenue',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },

      {
        label: 'Are you directly engaged in work with this client',
        name: 'direct_client',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      ...(props.view == 'current'
        ? [
            {
              label: 'Excluded from current cycle - 2024 H1',
              name: 'exclude',
              options: {
                display: 'excluded',
                searchable: false,
                filter: false,
              },
            },
          ]
        : ''),
      {
        label: 'Actions',
        name: 'actions',
        options: {
          // display: excludeFilter ? 'excluded' : 'true',
          sort: false,
          empty: true,
          download: false,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginRight: '12px',
                  marginLeft: '7px',
                }}
              >
                {columnMeta.label}
              </div>
            )
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return props?.view == 'current' ? (
              <CsatOptions
                //  when nomination window is closed--->
                disabled={
                  // (userDetails.role == 'Reviewer' &&
                  //   userDetails?.vertical_ids?.verticalIds?.length > 0) ||
                  userDetails.role == 'Admin' ? false : true
                }
                editable={tableMeta.rowData[7] ? true : false}
                excludeFilter={excludeFilter}
                onEdit={onEdit(
                  tableMeta?.currentTableData[tableMeta?.rowIndex]?.index
                )}
                onExclude={onExclude(
                  tableMeta?.currentTableData[tableMeta?.rowIndex]?.index
                )}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <Tooltip title="Edit Nomination">
                  <IconButton
                    //  when nomination window is closed--->
                    disabled={
                      // (userDetails.role == 'Reviewer' &&
                      //   userDetails?.vertical_ids?.verticalIds?.length > 0) ||
                      userDetails.role == 'Admin' ? false : true
                    }
                    aria-label="Edit Nomination"
                    color="primary"
                    onClick={onEdit(
                      tableMeta?.currentTableData[tableMeta?.rowIndex]?.index
                    )}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                {/* window closed open for only admin */}
                {userDetails.role == 'Admin' ? (
                  <Tooltip title={<ToolTipTitle />}>
                    <IconButton className="blinking-icon">
                      <ErrorOutlineSharpIcon sx={{ color: '#F58220' }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            )
          },
        },
      },
      ...(props.view == 'current'
        ? [
            {
              label: 'Created By',
              name: 'created_by',
              options: {
                display: 'excluded',
                searchable: false,
                filter: false,
              },
            },
            {
              label: 'Created At',
              name: 'createdAt',
              options: {
                display: 'excluded',
                searchable: false,
                filter: false,
              },
            },
            {
              label: 'Updated By',
              name: 'updated_by',
              options: {
                display: 'excluded',
                searchable: false,
                filter: false,
              },
            },
            {
              label: 'Updated At',
              name: 'updatedAt',
              options: {
                display: 'excluded',
                searchable: false,
                filter: false,
              },
            },
          ]
        : ''),
    ]
    // Conditionally exclude columns based on userRole
    if (userDetails.role !== 'Admin') {
      return baseColumns.filter(
        (column) =>
          column.name !== 'alternate_client_name' &&
          column.name !== 'alternate_client_emailId'
      )
    }

    return baseColumns
  }, [
    excludeFilter,
    userDetails,
    rows,
    csatFilterData,
    csatHistoricalFilterData,
  ])

  const onSwitchChange = (e) => {
    setExcludeFilter(e.target.checked)
    props?.setExcludeFilter(e.target.checked)
  }

  const onRemove = (index, removedFilter, filterList) => {
    if (props?.view == 'current') {
      if (index === 1) {
        const removedItems = rows
          .filter((item) => item.vertical === removedFilter)
          .map((item) => item.account)
        const accounts = csatFilterData.account.filter(
          (account) => !removedItems.includes(account)
        )
        const updatedAccounts = rows
          .filter((item) => accounts.includes(item.account))
          .map((item) => item.account)
        const projects = rows
          .filter((item) => updatedAccounts.includes(item.account))
          .map((item) => item)
        const updatedProjects = projects
          .filter((item) => csatFilterData.project.includes(item.project))
          .map((item) => item.project)

        dispatch(
          updateCsatFilterData({
            vertical: filterList[1],
            account: _.uniq(updatedAccounts),
            project: _.uniq(updatedProjects),
          })
        )
      } else if (index === 2) {
        const removedItems = rows
          .filter((item) => item.account === removedFilter)
          .map((item) => item.project)

        const projects = csatFilterData.project.filter(
          (project) => !removedItems.includes(project)
        )
        const updatedProjects = rows
          .filter((item) => projects.includes(item.project))
          .map((item) => item.project)
        dispatch(
          updateCsatFilterData({
            vertical: filterList[1],
            account: filterList[2],
            project: _.uniq(updatedProjects),
          })
        )
      } else if (index === 3) {
        dispatch(
          updateCsatFilterData({
            vertical: filterList[1],
            account: filterList[2],
            project: filterList[3],
          })
        )
      }
    } else if (props?.view == 'historical') {
      if (index === 1) {
        const removedItems = rows
          .filter((item) => item.vertical === removedFilter)
          .map((item) => item.account)
        const accounts = csatHistoricalFilterData.account.filter(
          (account) => !removedItems.includes(account)
        )
        const updatedAccounts = rows
          .filter((item) => accounts.includes(item.account))
          .map((item) => item.account)
        const projects = rows
          .filter((item) => updatedAccounts.includes(item.account))
          .map((item) => item)
        const updatedProjects = projects
          .filter((item) =>
            csatHistoricalFilterData.project.includes(item.project)
          )
          .map((item) => item.project)
        const timePeriod = rows
          .filter((item) => updatedProjects.includes(item.project))
          .map((item) => item.time_period)
        const updatedTime = _.uniq(timePeriod)

        dispatch(
          updateCsatHistoricalFilterData({
            vertical: filterList[1],
            account: _.uniq(updatedAccounts),
            project: _.uniq(updatedProjects),
            time_period:
              csatHistoricalFilterData.time_period.length > 0
                ? updatedTime
                : filterList[20],
          })
        )
      } else if (index === 2) {
        const removedItems = rows
          .filter((item) => item.account === removedFilter)
          .map((item) => item.project)

        const projects = csatHistoricalFilterData.project.filter(
          (project) => !removedItems.includes(project)
        )
        const updatedProjects = rows
          .filter((item) => projects.includes(item.project))
          .map((item) => item.project)
        const timePeriod = rows
          .filter((item) => updatedProjects.includes(item.project))
          .map((item) => item.time_period)
        const updatedTime = _.uniq(timePeriod)

        dispatch(
          updateCsatHistoricalFilterData({
            vertical: filterList[1],
            account: filterList[2],
            project: _.uniq(updatedProjects),
            time_period:
              csatHistoricalFilterData.time_period.length > 0
                ? updatedTime
                : filterList[20],
          })
        )
      } else if (index === 3) {
        const removedItems = rows
          .filter((item) => item.project === removedFilter)
          .map((item) => item.project)

        const projects = csatHistoricalFilterData.project.filter(
          (project) => !removedItems.includes(project)
        )
        const updatedProjects = rows
          .filter((item) => projects.includes(item.project))
          .map((item) => item.project)
        const timePeriod = rows
          .filter((item) => updatedProjects.includes(item.project))
          .map((item) => item.time_period)

        const updatedTime = _.uniq(timePeriod)

        dispatch(
          updateCsatHistoricalFilterData({
            vertical: filterList[1],
            account: filterList[2],
            project: filterList[3],
            time_period:
              csatHistoricalFilterData.time_period.length > 0
                ? updatedTime
                : filterList[20],
          })
        )
      } else if (index === 20) {
        dispatch(
          updateCsatHistoricalFilterData({
            vertical: filterList[1],
            account: filterList[2],
            project: filterList[3],
            time_period: filterList[20],
          })
        )
      }
    }
  }

  const handleDownload = () => {
    if (props?.nominatedRecord.length > 0) {
      const sortedData = props?.nominatedRecord.slice().sort((a, b) => {
        if (a.vertical === b.vertical) {
          return a.account.localeCompare(b.account)
        }
        return a.vertical.localeCompare(b.vertical)
      })
      const filteredColumns = columns.filter((col) => col.name !== 'actions')
      const columnData = sortedData.map((row) =>
        filteredColumns.map((col) => row[col.name])
      )

      const worksheet = XLSX.utils.aoa_to_sheet([
        filteredColumns.map((col) => col.label),
        ...columnData,
      ])
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      XLSX.writeFile(workbook, 'CSAT-2024 H1 Nominations.csv')
    }
  }
  const handleSearch = (value) => {
    setSearchText(value)
  }
  const handleClear = () => {
    setSearchText('')
  }
  const options = {
    responsive: 'standard',
    selectableRows: 'none',
    textLabels: {
      toolbar: {
        downloadCsv: 'Download Historical Nominations',
      },
      body: {
        noMatch: 'No nominations to display!',
      },
    },
    search: false,
    searchText: searchText,
    onSearchChange: (searchQuery) => setSearchText(searchQuery),
    print: false,
    filter: false,
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 25, 50],
    rowsPerPage: 5,
    elevation: 2,
    download: props.view == 'historical' ? true : false,
    downloadOptions: {
      filename: props.view === 'historical' ? 'Historical Nominations.csv' : '',
    },

    onDownload: (buildHead, buildBody, columns, data) => {
      console.log('data', data, rows, columns, buildBody)
      return buildHead(columns) + buildBody(data)
    },

    customToolbar:
      props.view == 'current'
        ? () => {
            return (
              <>
                <CustomToolbar
                  handleClick={handleClick}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  userDetails={userDetails}
                  handleFormClose={handleFormClose}
                  handleFormOpen={handleFormOpen}
                  handleDownload={handleDownload}
                  handleUploadOpen={handleUploadOpen}
                  excludeFilter={excludeFilter}
                />
                <div style={{ position: 'relative', top: '-6.25rem' }}>
                  <h5
                    style={{
                      display: 'flex',
                      fontSize: '12px',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    Show Excluded Nominations :{' '}
                    <Switch
                      checked={excludeFilter}
                      onChange={(e) => onSwitchChange(e)}
                    />
                  </h5>
                </div>
              </>
            )
          }
        : () => {
            return (
              <>
                {/* <InputBase
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                  className={classes.searchInput}
                />
                {searchText ? (
                  <IconButton type="button" onClick={handleClear}>
                    <ClearIcon onClick={handleClear} />
                  </IconButton>
                ) : (
                  <SearchIcon className={classes.searchIcon} />
                )}{' '} */}
                <Tooltip title={'Click here to filter the data'} arrow>
                  <FilterListIcon
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    sx={
                      isSmallScreen || isBetweenSmAndMd
                        ? {
                            margin: '-0.4rem',
                            marginLeft: '0.5rem',
                            marginRight: '-35rem',
                            cursor: 'pointer',
                          }
                        : {
                            margin: '-0.5rem',
                            marginLeft: '7px',
                            marginRight: '7px',
                            cursor: 'pointer',
                          }
                    }
                  ></FilterListIcon>
                </Tooltip>

                <Popper id={id} open={open} anchorEl={anchorEl}>
                  <Box sx={{ p: 1.2, backgroundColor: '#f9f9f9' }}>
                    <FilterComponent
                      keyName={'historicalNominations'}
                      setAnchorEl={setAnchorEl}
                    />
                  </Box>
                </Popper>
              </>
            )
          },
    onFilterChipClose: (index, removedFilter, filterList) => {
      onRemove(index, removedFilter, filterList)
    },
  }

  const editRow =
    editRowIndex !== null
      ? excludeFilter
        ? excludedrows[editRowIndex]
        : nonExcludedrows[editRowIndex]
      : {}
  return (
    <>
      <Loading open={openBackdrop} />
      {uploadOpen && (
        <CsatBulkUpload
          openBulkUploadModel={uploadOpen}
          setOpenBulkUploadModel={setUploadOpen}
          nonExcludedrows={nonExcludedrows}
          getCsat={props?.getCsat}
        />
      )}
      <div style={{ marginBottom: '20px', position: 'relative' }}>
        <MUIDataTable
          data={excludeFilter ? excludedrows : nonExcludedrows}
          columns={columns}
          options={options}
        />
      </div>

      <Grid item xs={12}>
        {openForm && (
          <FormDialog
            open={openForm}
            onClose={onClose}
            accounts={props?.accounts}
            projectList={props?.projectList}
            projects={adminData.projects}
            workstreams={adminData.workstreams}
            employees={adminData.employeeDetails}
            editForm={editForm}
            editRow={editRow}
            view={props?.view}
            user={userDetails}
            getCsat={props?.getCsat}
            getCsatHistoricalData={props?.getCsatHistoricalData}
          />
        )}
      </Grid>

      <Dialog open={openExclude} onClose={handleCloseExclude}>
        <DialogTitle>
          {excludeFilter ? 'Include' : 'Exclude'} CSAT Nomination
        </DialogTitle>
        <DialogContent sx={{ minWidth: '500px' }}>
          <DialogContentText>
            <Stack spacing={0.5}>
              <span
                style={{
                  fontFamily: 'inherit',
                  fontStyle: 'inherit',
                  fontSize: '13px',
                  fontWeight: 'bold',
                }}
              >
                Do you wish to {excludeFilter ? 'Include' : 'Exclude'} this
                project/client {excludeFilter ? 'for' : 'from'} current
                nominations - 2024 H1?
              </span>
              <span>Account : {editRow?.account}</span>
              <span>Project : {editRow?.project}</span>
              {editRow?.workstream && (
                <span>Workstream : {editRow?.workstream}</span>
              )}
              {editRow?.client_name && (
                <span>Client : {editRow?.client_name}</span>
              )}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            sx={{ width: '90px', fontSize: '12px' }}
            onClick={excludeFilter ? saveInclude : saveExclude}
          >
            Yes
          </Button>
          <Button
            onClick={handleCloseExclude}
            variant="outlined"
            sx={{ width: '90px', fontSize: '12px' }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
