window.employeeList = []
export default function storageFactory() {
  let inMemoryStorage = {}
  let employeeList = []

  function getAdminDetails(name) {
    return JSON.parse(localStorage.getItem('adminData'))
  }

  function setAdminDetails(data) {
    localStorage.setItem('adminData', JSON.stringify(data))
  }

  function clear() {
    inMemoryStorage = {}
    employeeList = []
  }

  return {
    clear,
    getAdminDetails,
    setAdminDetails,
  }
}