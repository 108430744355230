import React from 'react'
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import Autocomplete from '@mui/lab/Autocomplete'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import API from './../../../Common/api'
import Loading from '../../../Common/Loading'
import ACKNOWLEDGE from './../../../Common/AcknowledgeAlert'
import Upload from '../../../Component/Header/Upload'
import { useMediaQuery, useTheme } from '@mui/material'
import { SearchOutlined } from '@material-ui/icons'
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  },
}))

const AdminEmployeeUpdate = (props) => {
  const classes = useStyles()
  const [selectedEmailId, setSelectedEmailId] = React.useState('')
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const [openUpdate, setOpenUpdate] = React.useState(false)
  const [messageDetails, setMessageDetails] = React.useState({
    message: '',
    severity: '',
  })
  const [employeeDetails, setEmployeeDetails] = React.useState({
    userName: '',
    employeeId: '',
    designation: '',
    client: '',
    email: '',
    role: 'Reviewee',
    clientDefault: [],
    accessDefault: [],
    projectDefault: [],
    reqComp: false,
    previousClient: '',
    client_ids_selected: '',
    accessType: '',
    projectName: '',
  })
  const [projects, setProjects] = React.useState([])

  const [removedDetails, setRemovedDetails] = React.useState({
    project: [],
    account: [],
  })
  const [addedDetails, setAddedDetails] = React.useState({
    account: [],
    project: [],
  })

  const [existingPrjs, setExistingPrjs] = React.useState([])
  const [existingClients, setExistingClients] = React.useState([])

  const [clientList, setClientList] = React.useState([])
  const [projectList, setProjectList] = React.useState([])

  // const [accessTypeList, setAccessTypeList] = React.useState([])
  const { userDetails } = useSelector((state) => state.user)

  // const [userAccess, setUserAccess] = React.useState({
  //   accessList: [],
  // })

  // let addValueToAccessType = [
  //   {
  //     client_id: '',
  //     client_name: 'None',
  //   },
  //   {
  //     client_id: 0,
  //     client_name: 'ALL',
  //   },
  // ]
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isBetweenSmAndMd = window.matchMedia('(max-width: 784px)').matches

  React.useEffect(() => {
    setOpenBackdrop(true)
    if (localStorage.getItem('project_review_employee_role') !== 'Admin') {
      setMessageDetails({
        ...messageDetails,
        message:
          ' Dont have permissions to this page, Kindly contact with Admin team !! ',
        severity: 'error',
      })
      setOpenBackdrop(false)

      setTimeout(() => {
        props.history.push({
          pathname: `/`,
        })
      }, 3000)
    } else {
      setOpenBackdrop(false)
    }
  }, [])

  const handleReset = () => {
    handleUserSearch()
  }

  const handleUserOnchange = (data) => {
    if (data) {
      setSelectedEmailId(data.target.value)
    }
  }

  const clearEmployeeSearchedStates = () => {
    setEmployeeDetails({
      ...employeeDetails,
      userName: '',
      employeeId: '',
      designation: '',
      client: '',
      email: '',
      role: '',
      clientDefault: [],
      accessDefault: [],
      projectDefault: [],
      reqComp: false,
      client_ids_selected: '',
      previousClient: '',
      accessType: '',
      projectName: '',
    })
    setAddedDetails({})
    setRemovedDetails({})
  }

  const handleUserRoleChange = (data) => {
    setEmployeeDetails({
      ...employeeDetails,
      role: data.target.value,
    })
  }
  // const getUserAccessDefaultValue = (data) => {
  //   let accessListFinal = []

  //   if (data) {
  //     if (data.indexOf(',') > -1) {
  //       let splitArray = data.split(',')
  //       const finalIntArray = splitArray.filter(function (value) {
  //         return value !== ''
  //       })

  //       finalIntArray.map((record) => {
  //         const result = clientListState.accessList.filter(
  //           (itemNew) => itemNew?.client_name === record
  //         )
  //         result[0] && accessListFinal.push(result[0])
  //       })

  //       setEmployeeDetails(prev=>({
  //         ...prev,
  //         accessDefault: accessListFinal,
  //       }))
  //       Object.assign(employeeDetails, {
  //         accessDefault: accessListFinal,
  //       })
  //     } else {
  //       const result = clientListState.accessList.filter(
  //         (itemNew) => itemNew?.client_name === data
  //       )
  //       result[0] && accessListFinal.push(result[0])
  //       setUserAccess({
  //         ...employeeDetails,
  //         accessDefault: accessListFinal,
  //       })
  //       // Object.assign(employeeDetails, {
  //       //   accessDefault: accessListFinal,
  //       // })
  //     }
  //   } else {
  //     setEmployeeDetails(prev=>({
  //       ...prev,
  //       accessDefault: [],
  //     }))
  //     // Object.assign(employeeDetails, {
  //     //   accessDefault: [],
  //     // })
  //   }
  // }
  const handleUserSearch = () => {
    clearEmployeeSearchedStates()
    setOpenUpdate(false)
    if (!selectedEmailId.includes('tredence.com')) {
      setSelectedEmailId('')
      setMessageDetails({
        ...messageDetails,
        message: ' Invalid Email.. ',
        severity: 'error',
      })
      return false
    }

    setEmployeeDetails((prev) => ({
      ...prev,
      clientDefault: [],
      projectDefault: [],
      // accessDefault: [],
      client_ids_selected: '',
      reqComp: false,
    }))
    // Object.assign(accessTypeList, {
    //   userAccess: [],
    // })

    // setAccessTypeList({
    //   ...accessTypeList,
    //   userAccess: [],
    // })
    setOpenBackdrop(true)
    API.post('/admin/getAdminOrgAssociation', {
      selectedEmailId: selectedEmailId,
    }).then(
      function (response) {
        if (!response.data.employeeDetails[0]) {
          setMessageDetails({
            ...messageDetails,
            message: 'No data found for the user ..!! ',
            severity: 'error',
          })
          setSelectedEmailId('')
          setOpenBackdrop(false)
          return false
        } else {
          setOpenUpdate(true)
          let EmpResp = response.data.employeeDetails[0]
          let clients = []
          const result = []
          const unique_ids = []
          let client_ids_existing = []
          response?.data?.projects?.map((itemNew) => {
            if (
              !unique_ids?.includes(itemNew?.unique_id) &&
              (itemNew?.projectLead_emailId?.includes(EmpResp.email_id) ||
                itemNew?.adh_emailId?.includes(EmpResp.email_id))
            ) {
              result.push(itemNew)
              unique_ids.push(itemNew?.unique_id)
            }
          })

          result.map((value) => {
            if (!client_ids_existing?.includes(value?.client_id)) {
              client_ids_existing.push(value?.client_id)
              clients.push(value)
            }
          })
          setProjects(response.data.projects)
          setClientList(response.data.clients)
          setProjectList(
            response.data.projects?.filter((a) =>
              client_ids_existing?.includes(a?.client_id)
            )
          )

          //setAccessTypeList(addValueToAccessType.concat(response.data.clients))

          setExistingClients(
            clients?.map((a) => ({
              client_id: a?.client_id,
              client_name: a?.client_name,
            }))
          )
          setExistingPrjs(result)
          setIniitalClientIdState(EmpResp.client_ids)
          setProjectInititalValue(EmpResp.project_name)
          setEmployeeDetails((prev) => ({
            ...prev,
            userName: EmpResp.emp_name,
            employeeId: EmpResp.employee_id,
            designation: EmpResp.designation,
            client: client_ids_existing,
            existingClientIds: client_ids_existing,
            email: EmpResp.email_id,
            role: EmpResp.role ? EmpResp.role : 'Reviewee',
            reqComp: true,
            projectDefault: result,
            clientDefault: clients,
          }))
          setOpenBackdrop(false)
        }
      },
      (error) => {
        setMessageDetails({
          ...messageDetails,
          message: ' Error in Fetching USER details ',
          severity: 'error',
        })
        setOpenBackdrop(false)
      }
    )
  }

  const setIniitalClientIdState = (data) => {
    setEmployeeDetails((prev) => ({
      ...prev,
      client_ids_selected: data,
    }))
  }

  // const setAccessInititalValue = (data) => {
  //   setEmployeeDetails(prev=>({
  //     ...prev,
  //     //accessType: data,
  //   }))

  //   // Object.assign(employeeDetails, {
  //   //   accessType: data,
  //   // })
  // }

  const setProjectInititalValue = (data) => {
    setEmployeeDetails((prev) => ({
      ...prev,
      projectName: data,
    }))
  }

  const handleSelectedClientChange = (data) => {
    let clientArray = []
    let clientNameArray = []
    setExistingClients(data)
    setExistingPrjs((prev) =>
      prev.filter((prj) =>
        data?.map((client) => client.client_id)?.includes(prj?.client_id)
      )
    )
    data.map((item) => {
      clientArray.push(item.client_id)
      clientNameArray.push(item?.client_name)
    })
    setEmployeeDetails((prev) => ({
      ...prev,
      client_ids_selected: clientArray.toString(),
    }))
    setProjectList(
      JSON.parse(JSON.stringify(projects)).filter((a) =>
        clientArray?.includes(a?.client_id)
      )
    )
  }

  const handleProjectNameChange = (data) => {
    let finalValueOfProjectName = ''
    if (data) {
      let checkNone = data.some((item) => item.project_name === 'None')

      if (checkNone) {
        finalValueOfProjectName = ''
      } else {
        let temp = []
        data.map((item) => {
          temp.push(
            `${item.project_name?.toUpperCase()} ${
              item.workstream ? `- ${item.workstream}` : ''
            }`
          )
        })
        finalValueOfProjectName = temp.toString()
      }
    } else {
      //Do nothing setting up the values
    }
    setEmployeeDetails((prev) => ({
      ...prev,
      projectName: finalValueOfProjectName,
    }))
  }

  const updateAPICall = (data) => {
    setOpenBackdrop(true)
    API.post('/admin/updateUserData', data).then(
      function (response) {
        setEmployeeDetails((prev) => ({
          ...prev,
          reqComp: false,
        }))
        clearEmployeeSearchedStates()
        setOpenBackdrop(false)
        if (response.status === 200) {
          setMessageDetails({
            ...messageDetails,
            message: ' User details updated successfully..',
            severity: 'success',
          })
        } else {
          setMessageDetails({
            ...messageDetails,
            message: ' Error while updating user..',
            severity: 'error',
          })
        }
      },
      (error) => {
        setMessageDetails({
          ...messageDetails,
          message: ' Error in Updating USER details ',
          severity: 'error',
        })
        setOpenBackdrop(false)
      }
    )
  }

  const updateSetUpData = () => {
    let finalJson = {}
    finalJson.client_ids = employeeDetails.client_ids_selected
    finalJson.email_id = employeeDetails.email
    finalJson.role = employeeDetails.role
    //finalJson.access = employeeDetails.accessType
    finalJson.project_name = employeeDetails.projectName
    finalJson.removedProject = removedDetails?.project || []
    finalJson.removedAccount = removedDetails?.account || []
    finalJson.addedAccount = addedDetails?.account || []
    finalJson.addedProject = addedDetails?.project || []
    finalJson.updatedBy = userDetails?.email_id

    updateAPICall(finalJson)
    setOpenUpdate(false)
  }

  return (
    <React.Fragment>
      <ACKNOWLEDGE
        message={messageDetails.message}
        severity={messageDetails.severity}
      ></ACKNOWLEDGE>
      <Loading open={openBackdrop} />

      <Grid
        container
        style={
          isSmallScreen
            ? {
                maxWidth: '90vw',
              }
            : {}
        }
      >
        <Grid
          container
          style={{
            padding: '0.95rem 2%',
          }}
        >
          <Grid
            item
            xs={isSmallScreen ? 6 : 4}
            style={{ textAlign: 'left', alignSelf: 'center' }}
          >
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="outlined-helperText"
                label="Enter Email Id"
                helperText={
                  isSmallScreen || isBetweenSmAndMd
                    ? 'Enter Email Id'
                    : 'Enter Email id to search an employee'
                }
                variant="outlined"
                style={{ width: '100%' }}
                value={selectedEmailId}
                onChange={handleUserOnchange}
              />
            </form>
          </Grid>
          {isSmallScreen ? (
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUserSearch}
                style={{
                  borderRadius: '50%',
                  minWidth: '2.2rem',
                  minHeight: '2.2rem',
                  width: '2.2rem',
                  height: '2.2rem',
                  padding: 0,
                  position: 'relative',
                  top: '1rem',
                  left: '1rem',
                }}
              >
                <SearchOutlined style={{ fontSize: '1.2rem' }} />
              </Button>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUserSearch}
                style={{
                  position: 'relative',
                  top: '1rem',
                  left: '1rem',
                }}
              >
                Find
              </Button>
            </Grid>
          )}
          <Grid item xs={4}>
            <Upload />
          </Grid>
        </Grid>

        {/* populate employee details */}
        {openUpdate == true ? (
          //    employeeDetails.reqComp == true ?
          <React.Fragment>
            <Grid container style={{ marginTop: '5%' }}>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <FormControl
                  className={classes.formControl}
                  disabled
                  style={{ width: '80%' }}
                >
                  <TextField
                    id="standard-read-only-input"
                    value={employeeDetails.employeeId}
                    label="Employee ID"
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={isSmallScreen ? 8 : 4}
                style={{ textAlign: 'center' }}
              >
                <FormControl
                  className={classes.formControl}
                  style={isSmallScreen ? { width: '100%' } : { width: '80%' }}
                  disabled
                >
                  <TextField
                    id="standard-read-only-input"
                    value={employeeDetails.userName}
                    label="Employee name"
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={4}
                style={
                  isSmallScreen
                    ? { textAlign: 'center', marginTop: '2%' }
                    : { textAlign: 'center' }
                }
              >
                <FormControl
                  className={classes.formControl}
                  style={{ width: '80%' }}
                  disabled
                >
                  <TextField
                    id="standard-read-only-input"
                    value={employeeDetails.designation || 'Not specified'}
                    label="Employee Designation"
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={isSmallScreen ? 8 : 4}
                style={{ textAlign: 'center', marginTop: '2%' }}
              >
                <FormControl
                  className={classes.formControl}
                  style={isSmallScreen ? { width: '100%' } : { width: '80%' }}
                  disabled
                >
                  <TextField
                    id="standard-read-only-input"
                    value={employeeDetails.email}
                    label="Employee Email"
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                // xs={4}
                xs={isSmallScreen ? 8 : 4}
                style={{ textAlign: 'center', marginTop: '2%' }}
              >
                <FormControl
                  variant="filled"
                  className={classes.formControl}
                  // style={{ width: '80%' }}
                  style={isSmallScreen ? { width: '90%' } : { width: '80%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Role
                  </InputLabel>
                  <Select
                    variant="outlined"
                    id="demo-simple-select-filled"
                    value={employeeDetails.role}
                    onChange={handleUserRoleChange}
                  >
                    <MenuItem value="Reviewee">Reviewee</MenuItem>
                    <MenuItem value="Reviewer" disabled>
                      Reviewer
                    </MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                // xs={4}
                xs={isSmallScreen ? 10 : 4}
                style={{ textAlign: 'center', marginTop: '2%' }}
              >
                {/* <Autocomplete
                  multiple
                  id="tags-standard"
                  options={clientListState.accessList}
                  getOptionLabel={(option) => option?.client_name}
                  defaultValue={
                    employeeDetails.reqComp ? employeeDetails.accessDefault : []
                  }
                  onChange={(event, newValue,reason,option) => {
                    // if(reason == 'removeOption'){
                    //    setRemovedDetails(prev=>({...prev,client:option}))
                    // }
                    if (newValue) {
                      setEmployeeDetails({
                        ...employeeDetails,
                        client: newValue,
                      })
                     // handleAccessTypeChange(newValue)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ width: '80%' }}
                      variant="outlined"
                      label="Access Type"
                      placeholder="Access Type"
                      helperText="Account Access_Reviewers (All - Leadership or Specific Client - Accounts Heads)"
                    />
                  )}
                /> */}
              </Grid>

              {employeeDetails?.role != 'Admin' && (
                <>
                  <Grid
                    item
                    xs={isSmallScreen ? 10 : 4}
                    style={{ textAlign: 'center', marginTop: '2%' }}
                  >
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={clientList}
                      getOptionLabel={(option) => option?.client_name}
                      isOptionEqualToValue={(option, value) => {
                        return option.client_id === value.client_id
                      }}
                      value={
                        //employeeDetails.reqComp ? selectedClients: []
                        employeeDetails.reqComp ? existingClients : []
                      }
                      onChange={(event, newValue, reason, option) => {
                        if (
                          reason == 'removeOption' &&
                          option &&
                          employeeDetails?.existingClientIds?.includes(
                            option?.option?.client_id
                          ) &&
                          !removedDetails?.account
                            ?.map((a) => a?.client_id)
                            ?.includes(option?.option?.client_id)
                        ) {
                          const removedAccounts = removedDetails?.account || []
                          removedAccounts.push(option?.option)
                          const removedExistingProjects = JSON.parse(
                            JSON.stringify(existingPrjs)
                          )?.filter(
                            (prj) =>
                              !newValue
                                ?.map((client) => client.client_id)
                                ?.includes(prj?.client_id)
                          )
                          const removedProjects = removedDetails?.project || []
                          removedExistingProjects?.map((a) =>
                            removedProjects.push(a)
                          )
                          setRemovedDetails((prev) => ({
                            ...prev,
                            account: removedAccounts,
                            project: removedProjects,
                          }))
                        }
                        if (
                          reason == 'removeOption' &&
                          option &&
                          addedDetails?.account
                            ?.map((a) => a?.client_id)
                            ?.includes(option?.option?.client_id)
                        ) {
                          const removedAccounts = addedDetails?.account || []
                          setAddedDetails((prev) => ({
                            ...prev,
                            account: removedAccounts.filter(
                              (acc) =>
                                acc?.client_id !== option?.option?.client_id
                            ),
                          }))
                        }
                        if (reason == 'clear') {
                          setAddedDetails({})
                          setRemovedDetails((prev) => ({
                            ...prev,
                            account: employeeDetails.clientDefault,
                            project: employeeDetails?.projectDefault,
                          }))
                          setExistingClients([])
                          setExistingPrjs([])
                        }
                        if (newValue) {
                          setEmployeeDetails((prev) => ({
                            ...prev,
                            client: newValue,
                          }))
                          if (
                            reason != 'removeOption' &&
                            option &&
                            removedDetails?.account
                              ?.map((a) => a?.client_id)
                              ?.includes(option?.option?.client_id)
                          ) {
                            const removedAccounts =
                              removedDetails?.account || []
                            setRemovedDetails((prev) => ({
                              ...prev,
                              account: removedAccounts.filter(
                                (acc) =>
                                  acc?.client_id !== option?.option?.client_id
                              ),
                            }))
                          }
                          if (
                            reason != 'removeOption' &&
                            option &&
                            !addedDetails?.account
                              ?.map((a) => a?.client_id)
                              ?.includes(option?.option?.client_id) &&
                            !employeeDetails?.existingClientIds?.includes(
                              option?.option?.client_id
                            )
                          ) {
                            const addedAccounts = addedDetails?.account || []
                            addedAccounts.push(option?.option)
                            setAddedDetails((prev) => ({
                              ...prev,
                              account: addedAccounts,
                            }))
                          }
                          handleSelectedClientChange(newValue)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={
                            isSmallScreen ? { width: '92%' } : { width: '80%' }
                          }
                          variant="outlined"
                          label="Client Access_Reviewee"
                          placeholder="Select Client"
                          helperText={
                            'Client : ' +
                            employeeDetails.clientDefault?.map(
                              (c) => c?.client_name
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    // xs={4}
                    xs={isSmallScreen ? 12 : 8}
                    style={{ textAlign: 'center', marginTop: '2%' }}
                  >
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={projectList}
                      getOptionLabel={(option) =>
                        `${option?.project_name?.toUpperCase()} ${
                          option?.workstream ? `- ${option?.workstream}` : ''
                        }`
                      }
                      isOptionEqualToValue={(option, value) => {
                        return option.unique_id === value.unique_id
                      }}
                      value={
                        employeeDetails.reqComp
                          ? existingPrjs //employeeDetails.projectDefault
                          : []
                      }
                      onChange={(event, newValue, reason, option) => {
                        if (
                          reason == 'removeOption' &&
                          option &&
                          employeeDetails?.projectDefault
                            ?.map((prj) => prj?.unique_id)
                            ?.includes(option.option?.unique_id) &&
                          !removedDetails?.project
                            ?.map((prj) => prj.unique_id)
                            ?.includes(option?.option?.unique_id)
                        ) {
                          const removedProjects = removedDetails?.project || []
                          removedProjects.push(option?.option)
                          setRemovedDetails((prev) => ({
                            ...prev,
                            project: removedProjects,
                          }))
                        }
                        if (
                          reason == 'removeOption' &&
                          option &&
                          addedDetails?.project
                            ?.map((a) => a?.unique_id)
                            ?.includes(option?.option?.unique_id)
                        ) {
                          const removedProjects = addedDetails?.project || []
                          setAddedDetails((prev) => ({
                            ...prev,
                            project: removedProjects.filter(
                              (prj) =>
                                prj?.unique_id !== option?.option?.unique_id
                            ),
                          }))
                        }
                        if (reason == 'clear') {
                          setAddedDetails({})
                          setRemovedDetails((prev) => ({
                            ...prev,
                            project: employeeDetails.projectDefault,
                          }))
                        }
                        if (newValue) {
                          setEmployeeDetails({
                            ...employeeDetails,
                            client: newValue,
                          })
                          setExistingPrjs(newValue)
                          if (
                            reason != 'removeOption' &&
                            option &&
                            removedDetails?.project
                              ?.map((prj) => prj?.unique_id)
                              ?.includes(option?.option?.unique_id)
                          ) {
                            const removedProjects =
                              removedDetails?.project || []
                            setRemovedDetails((prev) => ({
                              ...prev,
                              project: removedProjects.filter(
                                (prj) =>
                                  prj?.unique_id !== option?.option?.unique_id
                              ),
                            }))
                          }
                          if (
                            reason != 'removeOption' &&
                            option &&
                            !addedDetails?.project
                              ?.map((a) => a?.unique_id)
                              ?.includes(option?.option?.unique_id) &&
                            !employeeDetails?.projectDefault
                              ?.map((prj) => prj?.unique_id)
                              ?.includes(option?.option?.unique_id)
                          ) {
                            const addedProjects = addedDetails?.project || []
                            addedProjects.push(option?.option)
                            setAddedDetails((prev) => ({
                              ...prev,
                              project: addedProjects,
                            }))
                          }
                          handleProjectNameChange(newValue)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // style={{ width: '80%' }}
                          style={
                            isSmallScreen ? { width: '94%' } : { width: '90%' }
                          }
                          variant="outlined"
                          label="Project Access_Reviewee"
                          placeholder="Project Access_Reviewee"
                          helperText={`Project : ${JSON.stringify(
                            employeeDetails?.projectDefault?.map(
                              (prj) =>
                                `${prj?.project_name} ${
                                  prj?.workstream ? `- ${prj?.workstream}` : ''
                                }`
                            )
                          )
                            ?.replaceAll('[', '')
                            ?.replaceAll(']', '')
                            ?.replaceAll('"', '')}`}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container style={{ marginTop: '2%' }}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginRight: '10px' }}
                  onClick={updateSetUpData}
                >
                  Update
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
          </React.Fragment>
        ) : null}
      </Grid>
    </React.Fragment>
  )
}

export default AdminEmployeeUpdate
