import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  sendReviewerMail,
  sendRevieweeMail,
  sendRevieweeMailForCopliance,
} from '../../services/emailService'

const initialState = {
  response: '',
  error: null,
}

export const sendReviewerEmail = createAsyncThunk(
  'sendReviewerEmail',

  async (data) => {
    const response = await sendReviewerMail(data)

    if (response.success) {
      return 'sent email successfully'
    }

    return {}
  }
)

export const sendRevieweeEmail = createAsyncThunk(
  'sendRevieweeEmail',
  async (data) => {
    const response = await sendRevieweeMail(data)

    if (response.success) {
      return 'sent email successfully'
    }

    return {}
  }
)

export const sendRevieweeEmailforCompliance = createAsyncThunk(
  'sendRevieweeEmailforCompliance',
  async (data) => {
    const response = await sendRevieweeMailForCopliance(data)

    if (response.success) {
      return 'sent email successfully'
    }

    return {}
  }
)

export const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendReviewerEmail.fulfilled, (state, action) => {
      state.response = action.payload
    })
    builder.addCase(sendReviewerEmail.rejected, (state, action) => {
      state.error = action.error.message
    })
    builder.addCase(sendRevieweeEmail.fulfilled, (state, action) => {
      state.response = action.payload
    })
    builder.addCase(sendRevieweeEmail.rejected, (state, action) => {
      state.error = action.error.message
    })
    builder.addCase(
      sendRevieweeEmailforCompliance.fulfilled,
      (state, action) => {
        state.response = action.payload
      }
    )
    builder.addCase(
      sendRevieweeEmailforCompliance.rejected,
      (state, action) => {
        state.error = action.error.message
      }
    )
  },
})

export default emailSlice.reducer
