import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import {
  FormControlLabel,
  TextField,
  Button,
  MenuItem,
  Select,
  Box,
  Tooltip,
  IconButton,
  Link,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { tooltipClasses } from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import lod from 'lodash'
import PublishIcon from '@mui/icons-material/Publish'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import {
  getCheckListLogs,
  saveChecklistdataforCompliance,
} from '../../../../services/complianceService'
import RestoreIcon from '@mui/icons-material/Restore'
import { useSnackbar } from 'notistack'
import Loading from '../../../../Common/Loading'
import {
  creatFileObject,
  getDefaultFilesList,
  processFileDownload,
} from '../../../../Common/AppConstants'
import { Folder } from '@mui/icons-material'
import { linksToFilesInSharePoint } from '../../../../GraphService'
import DocumentUploadModel from '../../Admin/DocumentRepositoryModal'
import API from './../../../../Common/api'
import Stack from '@mui/material/Stack'
import Config from '../../../../Config'

function ChecklistTableAdmin(props) {
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 750,
      padding: '0px',
    },
  })
  const CustomTitle = styled(Typography)(({ theme }) => ({
    // Custom styles for the tooltip content (title)
    color: 'orange',
    background: 'white',
    fontWeight: 'bold',
    border: '1px solid black',
  }))
  function DescriptionIcon({ description }) {
    return (
      <CustomWidthTooltip
        style={{ maxWidth: 10 }}
        title={
          <CustomTitle>
            {Array.isArray(description) ? (
              <>
                {description[0].title && <div>{description[0].title}:</div>}
                <ul style={{ marginLeft: '20px' }}>
                  {description
                    .slice(description[0].title ? 1 : 0)
                    .map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                </ul>
              </>
            ) : description ? (
              description
            ) : (
              '-'
            )}
          </CustomTitle>
        }
        placement="right"
      >
        <IconButton>
          <InfoOutlinedIcon style={{ fontSize: '15px' }} />
        </IconButton>
      </CustomWidthTooltip>
    )
  }
  function HistoryLogs({ data }) {
    return (
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="md">
        <DialogTitle>History Logs</DialogTitle>
        <MUIDataTable
          data={data}
          columns={logscolumns}
          options={optionsforlogs}
        />
        <Loading open={openBackdrop} />
      </Dialog>
    )
  }

  const [rows, setRows] = useState(props.checklist)
  const [rowsforlogs, setRowsForLogs] = useState([])
  const { enqueueSnackbar: notify } = useSnackbar()
  const dispatch = useDispatch()
  const [reviewID, setReviewID] = React.useState(0)
  const [selectedRows, setSelectedRows] = useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [openFilesModel, setOpenFilesModel] = useState(false)
  const [linktodocs, setLinkToDocs] = useState([])
  const [checklistlog, setCheckListLog] = useState([])
  const { userDetails } = useSelector((state) => state.user)
  const { data: adminData } = useSelector((state) => state.admin)
  const accessToken = useSelector((state) => state.user.accessToken)

  const fetchLogData = async (req) => {
    try {
      const response = await getCheckListLogs(req)
      if (response?.data?.length !== 0) {
        const filteredData = response.data.map((item) => {
          const filteredLogs = item.checkListLog.map((checklist) => {
            const employee = adminData.employeeDetails.find(
              (emp) => checklist?.approvedByEmpId === emp.employee_id
            )

            if (employee) {
              return { ...checklist, approvedByName: employee.employee_name }
            } else {
              return checklist
            }
          })
          return { ...item, checkListLog: filteredLogs }
        })
        setCheckListLog(filteredData)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const fetchlinkstosharepoint = (checklist) => {
    setOpenBackdrop(true)
    linksToFilesInSharePoint(
      props?.currentReview?.vertical,
      props?.currentReview?.account,
      props?.currentReview?.project,
      checklist,
      accessToken
    )
      .then((result) => {
        setLinkToDocs(result)
        setOpenBackdrop(false)
      })
      .catch((error) => {
        setOpenBackdrop(false)
        if (error == 'Access denied') {
          notify(
            `You don't have access to view the docs. Please reach out to DX team.`,
            {
              variant: 'error',
            }
          )
        }
        setLinkToDocs([])
        console.log(error, 'error:')
      })
  }
  useEffect(() => {
    setRows(props?.checklist)
    const req = {
      reviewId: props?.reviewId,
      phaseId: props?.phaseId,
      checkListId: props?.checklistIds,
    }
    fetchLogData(req)
  }, [props?.checklist])

  const handleOpenFilesModel = () => {
    setOpenFilesModel(true)
  }

  const handleCloseFilesModel = () => {
    setOpenFilesModel(false)
  }

  const logscolumns = [
    {
      name: 'applicationflag',
      label: 'Applicable Flag',
    },
    {
      name: 'remark',
      label: 'Remark',
    },
    {
      name: 'editDate',
      label: 'Review Date',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                width: '100px',
              }}
            >
              {value}
            </div>
          )
        },
      },
    },
    {
      name: 'checklist_status',
      label: 'Status',
    },
    {
      name: 'rejectionReason',
      label: 'Reason for Rejection',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {value}
            </div>
          )
        },
      },
    },
    {
      name: 'approvalDate',
      label: 'Approval/Rejection Date',
    },
    {
      name: 'approvedByName',
      label: 'Approved/Rejected By',
    },
  ]

  const handleOpenDialog = () => {
    setOpen(true)
  }

  // Handle closing the dialog box
  const handleCloseDialog = () => {
    setOpen(false)
  }

  let checkListNames
  if (selectedRows.length > 0) {
    const filteredItems = rows.filter((item, index) =>
      selectedRows.includes(index)
    )
    checkListNames = filteredItems.map((item) => item.checklist).join(',')
  }

  const downloadFile = (row) => {
    API.get(
      `complianceReview/files/download?reviewId=${props.reviewId}&phaseId=${props.phaseId}&checklistId=${row.checklist_id}&fileName=${row.filename}`,
      { responseType: 'blob' }
    )

      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]))

        const link = document.createElement('a')

        link.href = url

        link.download = row.filename

        link.click()

        URL.revokeObjectURL(url)
      })

      .catch((error) => {
        notify('File has been removed!', { variant: 'error' })
      })
  }

  const columns = [
    {
      name: 'checklist',
      label: 'Checklist',
      options: {
        filter: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {columnMeta.label}
            </div>
          )
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ textAlign: 'center' }}> {value ? value : 'TBD'}</div>
          )
        },
      },
    },
    {
      name: 'evidenceOfCompletion',
      label: 'Evidence Of Completion',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const link = rows[tableMeta.rowIndex].links
          const description = rows[tableMeta.rowIndex].description
          const columnStyle = {
            width: '200px',
            justifyItems: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }
          return (
            <Stack direction="row" spacing={0} justifyItems="center">
              <div style={columnStyle}>
                {link && value ? (
                  <>
                    <Tooltip title={link} enterDelay={500}>
                      <Link
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        {value}
                      </Link>
                    </Tooltip>
                    {description && (
                      <DescriptionIcon description={description} />
                    )}
                  </>
                ) : value ? (
                  <>
                    {value}
                    {description && (
                      <DescriptionIcon description={description} />
                    )}
                  </>
                ) : (
                  'TBD'
                )}
              </div>
            </Stack>
          )
        },
      },
    },
    {
      name: 'applicationflag',
      label: 'Applicable Flag',
      options: {
        filter: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {columnMeta.label}
            </div>
          )
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <div style={{ textAlign: 'center', marginLeft: '20px' }}>
              <FormControlLabel
                label=""
                control={
                  <Select disabled={true} value={value}>
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                }
              />
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>{'-'}</div>
          )
        },
      },
    },
    {
      name: 'remark',
      label: 'Remark',
      options: {
        filter: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {columnMeta.label}
            </div>
          )
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ textAlign: 'center' }}> {value ? value : '-'}</div>
          )
        },
      },
    },
    {
      name: 'filename',
      label: 'Link to Docs',
      options: {
        filter: false,
        searchable: false,
        customHeadLabelRender: (columnMeta) => {
          // let link = `https://tasolutions.sharepoint.com/sites/ProjectReviewAppArtifacts/Docs/Forms/AllItems.aspx`
          return (
            <div
              style={{
                textAlign: 'center',
                maxWidth: '350px',
              }}
            >
              {/* <Tooltip title={link} enterDelay={500}>
                <Link
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    cursor: 'pointer',
                  }}
                > */}
              {columnMeta.label}
              {/* </Link>
              </Tooltip> */}
            </div>
          )
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {value && !rows[tableMeta.rowIndex].checklist_status ? (
                <Folder
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleOpenFilesModel()
                    rows[tableMeta.rowIndex]?.required === 'mandatory'
                      ? fetchlinkstosharepoint('casestudy')
                      : fetchlinkstosharepoint(
                          rows[tableMeta.rowIndex]?.checklist
                        )
                  }}
                  color="primary"
                  fontSize="small"
                />
              ) : value &&
                rows[tableMeta.rowIndex].checklist_status &&
                rows[tableMeta.rowIndex].uploadFileError ? (
                <Tooltip title={'Files Not Uploaded'}>
                  <Folder
                    style={{ cursor: 'pointer', color: 'grey' }}
                    fontSize="small"
                    disabled={true}
                  />
                </Tooltip>
              ) : value && rows[tableMeta.rowIndex].checklist_status ? (
                <Tooltip
                  title={
                    rows[tableMeta.rowIndex].checklist_status === 'Approved'
                      ? 'Files Approved'
                      : rows[tableMeta.rowIndex].checklist_status === 'Rejected'
                      ? 'Files Rejected'
                      : ''
                  }
                >
                  <Folder
                    style={{ cursor: 'pointer' }}
                    color="primary"
                    fontSize="small"
                    disabled={true}
                  />
                </Tooltip>
              ) : (
                <div>-</div>
              )}
            </div>
          )
        },
      },
    },
    {
      name: 'checklist_status',
      label: 'Approval Status',
      options: {
        filter: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {columnMeta.label}
            </div>
          )
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <div
              style={
                value === 'Approved'
                  ? {
                      color: 'green',
                      textAlign: 'center',
                    }
                  : { color: 'red', textAlign: 'center' }
              }
            >
              {value}
            </div>
          ) : (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              -
            </div>
          )
        },
      },
    },
    {
      name: 'rejectionReason',
      label: 'Rejection Reason',
      options: {
        filter: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {columnMeta.label}
            </div>
          )
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ textAlign: 'center' }}>{value ? value : '-'}</div>
          )
        },
      },
    },
    {
      name: 'History',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const currentObject = checklistlog?.find(
            (obj) => obj.checkListId === rows[tableMeta.rowIndex].checklist_id
          )
          return (
            <Tooltip title="Check logs">
              <RestoreIcon
                aria-label="CheckListLogs"
                color="primary"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setRowsForLogs(currentObject?.checkListLog)
                  handleOpenDialog()
                }}
              ></RestoreIcon>
            </Tooltip>
          )
        },
      },
    },
  ]

  const options = {
    responsive: 'simple',
    sort: false,
    filter: false,
    print: false,
    viewColumns: false,
    pagination: false,
    search: false,
    download: false,
    expandableRowsHeader: false,
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: 'none',
  }

  const optionsforlogs = {
    responsive: 'simple',
    selectableRows: 'none',
    sort: false,
    filter: false,
    print: false,
    viewColumns: false,
    pagination: false,
    search: false,
    download: false,
    expandableRowsHeader: false,
    customFooter: () => {
      return (
        <>
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button variant="contained" onClick={() => handleCloseDialog()}>
              Close
            </Button>
          </Box>
        </>
      )
    },
  }
  return (
    <>
      <Loading open={openBackdrop} />
      <MUIDataTable data={rows} columns={columns} options={options} />
      <HistoryLogs data={rowsforlogs} />
      <Dialog open={openFilesModel} onClose={handleCloseFilesModel}>
        <DialogTitle>List of Files</DialogTitle>
        {linktodocs.length !== 0 ? (
          <DialogContent>
            <DialogContentText>Here is a list of files:</DialogContentText>
            <Loading open={openBackdrop} />
            <ul>
              {linktodocs.map((obj) => (
                <a
                  style={{
                    maxWidth: '350px',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    textAlign: 'center',
                    display: 'block',
                  }}
                  href={obj?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {obj?.name}
                </a>
              ))}
            </ul>
          </DialogContent>
        ) : (
          <DialogContent>
            <Loading open={openBackdrop} />
            <DialogContentText>No Files Uploaded</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCloseFilesModel} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChecklistTableAdmin
