import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filesDetails: [
{
name:'Initiation & Planning',
files:[]
},
{
  name:'Discovery',
  files:[]
},
{
  name:'Execution',
  files:[]
},
{
  name:'Testing',
  files:[]
},
{
  name:'Deployment & Hypercare',
  files:[]
},
{
  name:'Closure',
  files:[]
},
  ],
};

const checklistFilesSlice = createSlice({
  name: 'allChecklistFiles',
  initialState,
  reducers: {
    allChecklistFiles: (state, action) => {
      const updatedItem = action.payload;
      const existingItem = state.filesDetails.find((item) => item.name === updatedItem.phaseName);
      if (existingItem) {
        existingItem.files = updatedItem.files;
      }
    },
  },
});



export const { allChecklistFiles } = checklistFilesSlice.actions;
export default checklistFilesSlice.reducer;
