import { Add, Block } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import * as React from 'react'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export default function CsatOptions(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleAddorEdit = () => {
    props?.onEdit()
    handleClose()
  }
  const handleExclude = () => {
    props?.onExclude()
    handleClose()
  }

  const handleInclude = () => {
    props?.onExclude()
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Tooltip title={'Click here'}>
          <Button
            //  when nomination window is closed--->
            disabled={props?.disabled}
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            style={{
              borderRadius: '50%', // This makes the button round
              minWidth: 0, // Ensures the button maintains its size regardless of content
              padding: '5px',
            }}
          >
            <KeyboardArrowDownIcon />
          </Button>
        </Tooltip>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {!props?.excludeFilter ? (
            props?.editable ? (
              <MenuItem onClick={handleAddorEdit} disableRipple>
                <EditIcon />
                Edit Nominations
              </MenuItem>
            ) : (
              <MenuItem onClick={handleAddorEdit} disableRipple>
                <Add />
                Add Nominations
              </MenuItem>
            )
          ) : (
            ''
          )}

          {props?.excludeFilter ? (
            <MenuItem onClick={handleInclude} disableRipple>
              <Add />
              Include Nominations
            </MenuItem>
          ) : (
            <MenuItem onClick={handleExclude} disableRipple>
              <Block />
              Exclude Nominations
            </MenuItem>
          )}
        </StyledMenu>
      </div>
    </>
  )
}
