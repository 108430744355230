import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  const theme = useTheme()

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`review-tabpanel-${index}`}
      aria-labelledby={`review-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            mb: 3,
            borderRadius: '0 8px 8px 8px',
            backgroundColor: theme.palette.background.default,
            boxShadow: '0px 3px 30px rgba(0, 0, 0, 0.1607843137)',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `review-tab-${index}`,
    'aria-controls': `review-tabpanel-${index}`,
  }
}

export { TabPanel, a11yProps }
