import React from 'react'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { Button } from '@mui/material'
import _ from 'lodash'

const ExcelGenerator = (props) => {
  const { data, uniqueEmployees, revenuesOptions, countries, designations } =
    props

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    let worksheet = workbook.addWorksheet('Sheet1')

    let worksheetMasterData = workbook.addWorksheet('MasterData')
    const columnNames = [
      'rowId','vertical', 'account', 'project', 'workStream','projectLeadEmail', 'csEmail','internalSurveyee', 
      'clientFirstName', 'clientLastName','clientEmail', 'clientDesignation', 'clientPreferredName', 'clientTenure','clientBaseLocationCountry', 'clientLinkedin','revenueBucket', 'surveyType',  
      'areYouDirectlyEngagedInWorkWithThisClient','alternateClientName','alternateclientEmailId',
'uniqueId' 
    ];

    worksheetMasterData.getColumn(3).values = revenuesOptions
    worksheet.addRow(columnNames);
    columnNames.forEach((columnName, index) => {
      if (columnName !== 'clientLinkedin' && columnName !== 'workStream' && columnName !== 'projectLeadEmail'&& columnName !== 'rowId'&& columnName !=='alternateClientName'&& columnName !=='alternateclientEmailId') {
        const cell = worksheet.getCell(1, index + 1); 
        const richText = [{ text: columnName }, { font: { color: { argb: 'FF0000' } }, text: '*' }];
        cell.value = { richText };
      }
    });
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const length = cell.value ? String(cell.value).length : 0;
        if (length > maxLength) {
          maxLength = length;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });
    const templateData = data.map((item, index) => ([
      index+1,
      item.vertical,
      item.account,
      item.project,
      item.workstream,
      '', '', '', '', '', '', '', '', '', '', '', '','','','','',
      item.unique_id,
    ]));

    templateData.forEach(data => {
      worksheet.addRow(data);
    });
    
    const revenuFormula = `" ${revenuesOptions.join(',')}"`
 
    for (let i = 2; i < data.length; i++) {
      worksheet.getCell(`Q${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [revenuFormula],
      }
    }
    for (let i = 2; i <3000; i++) {
      worksheet.getCell(`N${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [`"<3 months,3-6 months,6-12 months,>12 months"`],
      }
    }

    for (let i = 2; i < data.length; i++) {
      worksheet.getCell(`S${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['"yes,no"'],
      }
    }
    for (let i = 2; i < data.length; i++) {
      worksheet.getCell(`R${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['"Generic,Solution Support"'],
      }
    }

    worksheet.getColumn(22).hidden = true;

    worksheetMasterData.state = 'veryHidden'
    const buffer = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buffer]), 'Csat Nominations - 2024 H1.xlsx')
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ borderRadius: '15px' }}
        onClick={generateExcel}
      >
        Download Template
      </Button>
    </div>
  )
}

export default ExcelGenerator
